import React, {useEffect, useState} from "react";
import {Col, Table} from "rsuite";

/*Importing styles*/
import "./docs.scss";
import {useParams} from "react-router-dom";

export const Catalogs = () => {
    const params = useParams();

    const {Column, HeaderCell, Cell} = Table;
    const [nameRoute] = useState(`${params.name}`);

    const data = [
        {
            "code": "001",
            "description": "Transaction created",
            "details": "Código de respuesta esperado para las transacciones creadas exitosamente. (Exclusivo para WebCheckout y Ligas de pago).",
            "notes": [
                {
                    "id": 1,
                    "note1": "Este estatus regresa una url a la cual se debe redirigir para procesar el pago."
                },
            ]
        },
        {
            "code": "002",
            "description": "Accepted",
            "details": "Se recibe cuando las peticiones fueron procesadas exitosamente. (Exclusivo para Tokenización; Cancelación de suscripción; pago autorizado por el banco).",
            "notes": []
        },
        {
            "code": "003",
            "description": "Rejected",
            "details": "Indica que la transacción fue rechazada por validaciones o por respuesta del banco.",
            "notes": []
        },
        {
            "code": "004",
            "description": "Canceled by Customer",
            "details": "Indica que la transacción fue cancelada por el Usuario.",
            "notes": []
        },
        {
            "code": "005",
            "description": "Pending Payment",
            "details": "Aplica para pagos en efectivo, en espera del pago del Usuario.",
            "notes": []
        },
        {
            "code": "006",
            "description": "Expired",
            "details": "Sucede cuando se ha excedido el tiempo de espera para el pago de la transacción.",
            "notes": []
        },
        {
            "code": "007",
            "description": "Review",
            "details": "Indica que la transacción requiere una validación de seguridad adicional.",
            "notes": [
                {
                    "id": 2,
                    "note1": "Este estatus regresa una url a la cual se debe redirigir para procesar la validación."
                }
            ]
        },
        {
            "code": "008",
            "description": "Authorized",
            "details": "Código de respuesta esperado para las transacciones con Captura Diferida (Preautorización).",
            "notes": []
        },
        {
            "code": "009",
            "description": "Reversed",
            "details": "Código esperado para la Cancelación de Captura Diferida.",
            "notes": []
        },
        {
            "code": "010",
            "description": "Non-payment",
            "details": "Código de respuesta para transacciones abandonadas por el usuario. Flujo incompleto.",
            "notes": []
        },
        {
            "code": "011",
            "description": "Replaced",
            "details": "Se refiere a un intento fallido de pago, cuya referencia se reutiliza para un nuevo intento.",
            "notes": []
        },
        {
            "code": "012",
            "description": "Refund",
            "details": "Corresponde a un reembolso por el monto total de la transacción.",
            "notes": []
        },
        {
            "code": "013",
            "description": "Authorization Expired",
            "details": "Hace referencia a una preautorización que no se capturó.",
            "notes": []
        },
        {
            "code": "014",
            "description": "Expired cash payment",
            "details": "Sucede cuando la referencia de pago en efectivo (en Oxxo y Paynet) ha expirado, pero puede intentarse el pago por otro medio.",
            "notes": []
        },
        {
            "code": "015",
            "description": "Payment canceled",
            "details": "Ocurre cuando el cliente cancela la transacción.",
            "notes": [
                {
                    "id":3,
                    "note1": "Este estatus sólo puede ocurrir mientras la transacción está no en proceso de pago."
                }
            ]
        },
        {
            "code": "016",
            "description": "Partial refund",
            "details": "Se refiere a un reembolso parcial del monto total de la transacción.",
            "notes": []
        },
    ];

    const data2 = [
        {
            "code": "Daily",
            "description": "Diariamente",
        },
        {
            "code": "Biweekly",
            "description": "Quincenalmente (Cada 14 días)",
        },
        {
            "code": "Monthly",
            "description": "Mensualmente",
        },
        {
            "code": "Halfyearly",
            "description": "Semestral",
        },
        {
            "code": "Annual",
            "description": "Anualmente",
        },
    ];

    const [scrollTop,setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollTop(window.scrollY);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollTop]);

    return (
        <>
            {{
                'response':
                <>
                    <Col xs={24}>
                        <h4>Catálogos</h4>
                        <p>
                            Actividades y requerimientos técnicos que permiten realizar la integración del e-commerce del cliente a Red
                            Pay, en cualquiera de sus modalidades (Api y WebCheckout).
                        </p>
                    </Col>
                    <Col xs={24} id="response">
                        <h4>Códigos de respuesta</h4>
                        <Table
                            height={800}
                            data={data}
                            onScroll={(x,y) => {
                                x = Math.abs(x)
                                return x;
                            }}
                        >
                            <Column width={80} align="center">
                                <HeaderCell>Código</HeaderCell>
                                <Cell dataKey="code" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Descripción</HeaderCell>
                                <Cell dataKey="description" />
                            </Column>
                            <Column width={1200}>
                                <HeaderCell>Detalle</HeaderCell>
                                <Cell dataKey="details" />
                            </Column>
                        </Table>
                    </Col>
                </>,
                'frecuency':
                    <Col xs={24} id="frecuency">
                        <h4>Códigos de frecuencia</h4>
                        <Table
                            height={300}
                            data={data2}
                        >
                            <Column width={120} align="center">
                                <HeaderCell>Código</HeaderCell>
                                <Cell dataKey="code" />
                            </Column>
                            <Column width={250}>
                                <HeaderCell>Descripción</HeaderCell>
                                <Cell dataKey="description" />
                            </Column>
                        </Table>
                    </Col>,
            }[nameRoute]}
        </>
    );
}
