import React, {useEffect} from "react";
import {
    Button,
    CheckTree,
    Icon,
} from "rsuite";
/*Importing styles*/
import "./docs.scss";


export const GeneratorJSON = ({
        showDrawerBuilder,
        checkDataJSON,setCheckDataJSON,
        objectJsonSelected,
        setStateJsonSelected,stateJsonSelected
    }) => {

    const handleSelectedItem = (selectedItem) => {
        //debugger
        //si el item no tiene hijos y su padre tiene hijos entra aqui
        if(selectedItem.hasChildren === false && selectedItem.parentNode !== undefined && selectedItem.parentNode.parentNode) {
            const grandParentId = selectedItem.parentNode.parentNode.value;
            let data = [], itemGrandParent;
            itemGrandParent = stateJsonSelected.filter(data => data.value === grandParentId);

            // si el array padre del item seleccionado ya no existe en el json, lo recuperamoos
            if(itemGrandParent.length === 0) {
                return addItemGrandChild(selectedItem);
            }

            const itemParent = itemGrandParent.map(item => item.children?.filter(data => data.value === selectedItem.parentNode.value));
            itemParent[0].map(item => item.children.map(child => {
                if (child.value === selectedItem.value) data.push(child);
                return data;
            }))
            return (data.length === 0) ? addItemGrandChild(selectedItem) : removeItemGrandChild(selectedItem);
        }

        //si el item seleccionado es un arreglo y tiene un padre
        if (selectedItem.hasChildren === false && selectedItem.parentNode !== undefined){
            const filtered = stateJsonSelected.filter(data => data.value === selectedItem.parentNode.value);
            const idFiltered = filtered.map(item => item.value)

            if(filtered.length >= 0 && selectedItem.check === false) {
                return removeItemChild(selectedItem);
            } else {
                if(idFiltered.length === 0) {
                    return addItemChildRecovered(selectedItem)
                }
                return addItemChild(selectedItem);
            }
        }

        //si el item seleccionado es un arreglo y no tiene un padre
        if (selectedItem.hasChildren && selectedItem.parentNode === undefined){
            const data = stateJsonSelected.filter(item => item.value === selectedItem.value)
            return (data.length === 0) ? addItemParent(selectedItem) : removeItemParent(selectedItem);
        }

        //si el item seleccionado es un arreglo y tiene padre e hijos
        if(selectedItem.hasChildren && selectedItem.parentNode !== undefined) {
            const data = stateJsonSelected.filter(item => item.value === selectedItem.parentNode.value);
            const childSelected = [];

            data.map(item => item.children.map(child => {
                if (child.value === selectedItem.value) childSelected.push(child)
                return childSelected;
            }));

            if(data.length !== 0) {
                if(data[0].children.length === 0) {
                    return addItemParentRecovered(selectedItem)
                }
            }

            return (childSelected.length === 0) ? addItemChild(selectedItem) : removeItemParent(selectedItem);
        }

        // si el item seleccionado no es un array
        const foundData = stateJsonSelected.filter(data => data.value === selectedItem.value)
        return (foundData.length === 0) ? addItem(selectedItem) : removeItem(selectedItem);
    }

    const addItemParent = (selectedItem) => {
        const id = selectedItem.value;
        //si el array no pertenece a otro array
        if(selectedItem.parentNode === undefined) {
            objectJsonSelected.filter(data => {
                if(data.value === id) setStateJsonSelected([...stateJsonSelected, data])
                return data;
            });
            return;
        }

        let newItem = [];
        stateJsonSelected.map(item => {
            if(item.value === selectedItem.parentNode.value) {
                let grandChildren, newGrandChildren = [], itemChild = [], newItemChild;

                itemChild.push(item);

                grandChildren = item.children?.map(child => {
                    let grandChild;

                    const parent = {
                        "label": child.label,
                        "labelValue": child.labelValue,
                        "value": child.value,
                        "check": child.check,
                        "checkAll": child.checkAll,
                        "description": child.description,
                        "sampleType": child.sampleType,
                        "hasChildren": item.hasChildren
                    };

                    if(child.children) {
                        grandChild = child.children.map(grandChild => {
                            return {
                                "label": grandChild.label,
                                "labelValue": grandChild.labelValue,
                                "value": grandChild.value,
                                "check": grandChild.check,
                                "checked": grandChild.check,
                                "description": grandChild.description,
                                "sampleType": grandChild.sampleType
                            }
                        })
                        if(child.hasChildren) parent.hasChildren = child.hasChildren
                        parent.children = grandChild;
                    }

                    return parent;
                }) ?? null;

                grandChildren.map(item => newGrandChildren.push(item));
                //se añade el attr seleccionado
                newGrandChildren.push({
                    "label": selectedItem.label,
                    "labelValue": selectedItem.labelValue,
                    "value": selectedItem.value,
                    "check": true,
                    "checkAll": true,
                    "description": selectedItem.description,
                    "sampleType": selectedItem.sampleType,
                    "hasChildren": selectedItem.hasChildren,
                    "children": selectedItem.children
                })

                newItemChild = itemChild.map(item => {
                    return {
                        "label": item.label,
                        "labelValue": item.labelValue,
                        "value": item.value,
                        "check": item.check,
                        "checkAll": item.checkAll,
                        "description": item.description,
                        "sampleType": item.sampleType,
                        "hasChildren": item.hasChildren,
                        "children": newGrandChildren
                    }
                });
                newItem.push(newItemChild[0]);
            }
            if(item.value !== selectedItem.parentNode.value) newItem.push(item)
            return true;
        })
        setStateJsonSelected(newItem)
    }

    const addItem = (selectedItem) => {
        setStateJsonSelected([...stateJsonSelected, {
            "label": selectedItem.label,
            "labelValue": selectedItem.labelValue,
            "value": selectedItem.value,
            "check": selectedItem.check,
            "checkAll": undefined,
            "hasChildren": false,
            "description": selectedItem.description
        }]);
    }

    const addItemChild = (selectedItem) => {
        debugger
        const parentId = selectedItem.parentNode.value;
        let newChildren = [];

        const itemParent = stateJsonSelected.filter(data => data.value === parentId);

        //cuando el array de objetos abuelo esta vacio y queremos añadir un hijo
        if(itemParent.length > 0) {
            if(itemParent[0].children.length > 0) {
                itemParent.map(item => {
                    return item.children.map(child => {

                        const grandParentItem = {
                            "check": child.check,
                            "checkAll": child.checkAll,
                            "description": child.description,
                            "label": child.label,
                            "labelValue": child.labelValue,
                            "value": child.value,
                            "sampleType": child.sampleType,
                            "hasChildren": child.hasChildren,
                        }

                        if(child.parent) grandParentItem.parent = child.parent;
                        if(child.children) grandParentItem.children = child.children;

                        newChildren.push(grandParentItem);

                        const parentItem = {
                            "check": selectedItem.parentNode,
                            "checkAll": selectedItem.checkAll,
                            "description": selectedItem.description,
                            "label": selectedItem.label,
                            "labelValue": selectedItem.labelValue,
                            "value": selectedItem.value,
                            "sampleType": selectedItem.sampleType,
                            "hasChildren": selectedItem.hasChildren,
                        }

                        if(selectedItem.parent) parentItem.parent = selectedItem.parent;
                        if(selectedItem.children) parentItem.children = selectedItem.children;

                        newChildren.push(parentItem);

                        return newChildren;
                    })
                })
            }

            const currentSelectedItem = [
                {
                    "value": selectedItem.value,
                    "sampleType": selectedItem.sampleType,
                    "label": selectedItem.label,
                    "labelValue": selectedItem.labelValue,
                    "check": selectedItem.check,
                    "checked": selectedItem.checked,
                    "description": selectedItem.description,
                    "hasChildren": selectedItem.hasChildren,
                    "parent": selectedItem.parent
                },
            ];

            if(selectedItem.hasChildren) currentSelectedItem.children = selectedItem.children

            setStateJsonSelected(stateJsonSelected.map(item => {
                const currentItem = (item.value === parentId);
                const currentItemChildren = (item.children?.length === 0) ?? null;
                const parent  = {
                    "label": item.label,
                    "labelValue": item.labelValue,
                    "value": item.value,
                    "check": (currentItem) ? false : item.check,
                    "checkAll": (currentItem) ? undefined : item.checkAll,
                    "hasChildren": item.hasChildren,
                    "description": item.description,
                }

                if(item.children)  parent.children = (currentItem && currentItemChildren === false) ? newChildren
                                   : (currentItem && currentItemChildren === true) ? currentSelectedItem : item.children;

                return parent;
            }))
        } else addItemChildRecovered(selectedItem)
    }

    const addItemGrandChild = (selectedItem) => {
        const id = selectedItem.value;
        const grandParentId = selectedItem.parentNode.parentNode.value;
        const parentId = selectedItem.parentNode.value;
        let data = [];

        const itemGrandParent = stateJsonSelected.filter(data => data.value === grandParentId);

        if(itemGrandParent.length === 0) {
            addItemGrandChildRecovered(selectedItem);
            return;
        }

        //grandChildItems
        itemGrandParent.map(item => item.children?.map(child => {
            if(child.value === parentId) child.children.map(grandChild => data.push(grandChild))
            return data;
        }) ?? '');

        //cuando el nieto a añadir no tiene a su array padre en el json
        if(data.length === 0) {
            //aqui recuperamos el arreglo si ya no existe y seleccionamos un atributo de él, entonces recuperamos ese arreglo

            //obtenemos el arreglo hijo que ya no existe
            let children = [];
            const parentItem = objectJsonSelected.filter(item => item.value === grandParentId);
            parentItem.map(item => item.children.filter(data => {
                if (data.value === parentId) children.push(data)
                return children;
            }));

            //añadimos el atributo seleccionado al arreglo recuperado
            const newItemChild =  children.map(child => {
                return {
                    "label": child.label,
                    "labelValue": child.labelValue,
                    "value": child.value,
                    "check": child.check,
                    "checkAll": undefined,
                    "description": child.description,
                    "sampleType": child.sampleType,
                    "parent": child.parent,
                    "hasChildren": child.hasChildren,
                    "children": [{
                        "label": selectedItem.label,
                        "labelValue": selectedItem.labelValue,
                        "value": selectedItem.value,
                        "check": selectedItem.check,
                        "checked": selectedItem.checked,
                        "description": selectedItem.description,
                        "sampleType": selectedItem.sampleType,
                        "hasChildren": selectedItem.hasChildren,
                    }]
                }
            });

            let newData = [];
            itemGrandParent.map(item => item.children.map(child => newData.push(child)));
            newData.push(newItemChild[0]);

            setStateJsonSelected(stateJsonSelected.map(item => {
                const currentItem = (item.value === grandParentId);
                return {
                    "label": item.label,
                    "labelValue": item.labelValue,
                    "value": item.value,
                    "check": item.check,
                    "checkAll": item.checkAll,
                    "hasChildren": item.hasChildren,
                    "description": item.description,
                    "children": (currentItem) ? newData : item.children
                }
            }))
            return;
        }

        //se añade la info del atributo nieto
        data.push({
            "label": selectedItem.label,
            "labelValue": selectedItem.labelValue,
            "value": selectedItem.value,
            "check": selectedItem.value,
            "checked": selectedItem.value,
            "description": selectedItem.description,
            "sampleType": selectedItem.sampleType,
            "hasChildren": selectedItem.hasChildren,
        })

        const newChildren = itemGrandParent.map(item => item.children.map(child => {
            const currentItem = (child.value === parentId);

            const parent = {
                "label": child.label,
                "labelValue": child.labelValue,
                "value": child.value,
                "check": child.check,
                "checkAll": child.checkAll,
                "description": child.description,
                "sampleType": child.sampleType,
                "hasChildren": child.hasChildren,
                "parent": child.parent
            }

            if(child.children) parent.children = (currentItem) ? data : child.children

            return parent;
        }));

        setStateJsonSelected(stateJsonSelected.map(item => {
            const currentItem = (item.value === grandParentId);

            const parent = {
                "label": item.label,
                "labelValue": item.labelValue,
                "value": item.value,
                "check": item.check,
                "checkAll": item.checkAll,
                "hasChildren": item.hasChildren,
                "description": item.description,
            };

            if(item.children) parent.children = (currentItem) ? newChildren[0] : item.children

            return parent;
        }))
    }

    const addItemParentRecovered = (selectedItem) => {
        const parentId = selectedItem.parentNode.value;
        const newChildren = [
            {
                "value": selectedItem.value,
                "sampleType": selectedItem.sampleType,
                "label": selectedItem.label,
                "labelValue": selectedItem.labelValue,
                "check": selectedItem.check,
                "checked": selectedItem.checked,
                "description": selectedItem.description,
                "hasChildren": selectedItem.hasChildren,
                "parent": selectedItem.parent,
                "children": selectedItem.children
            },
        ]

        setStateJsonSelected(stateJsonSelected.map(item => {
            const currentItem = (item.value === parentId);
            const parent  = {
                "label": item.label,
                "labelValue": item.labelValue,
                "value": item.value,
                "check": (currentItem) ? false : item.check,
                "checkAll": (currentItem) ? undefined : item.checkAll,
                "hasChildren": item.hasChildren,
                "description": item.description,
            }

            if(item.children)  parent.children = (currentItem) ? newChildren : item.children;
            return parent;
        }))
    }

    const addItemChildRecovered = (selectedItem) => {
        const id = selectedItem.value;
        const parentId = selectedItem.parentNode.value;
        let childItem;

        //recuperamos el arreglo que ya no existe
        const parentItem = objectJsonSelected.filter(item => (item.value === parentId));
        //armamos el nuevo array
        parentItem.map(item => childItem = item.children.filter(data => data.value === id))

        const newItem = parentItem.map(item => {
            return {
                "value": item.value,
                "sampleType": item.sampleType,
                "label": item.label,
                "labelValue": item.labelValue,
                "check": item.check,
                "checkAll": item.check,
                "hasChildren": item.hasChildren,
                "description": item.description,
                "children": childItem
            }
        })

        setStateJsonSelected([...stateJsonSelected, newItem[0]])
    }

    const addItemGrandChildRecovered = (selectedItem) => {
        const grandParentId = selectedItem.parentNode.parentNode.value;
        const parentId = selectedItem.parentNode.value;

        const parentItem = objectJsonSelected.filter(data => data.value === grandParentId);
        let grandChildItem = [], childItem = [], newItemGrandParent = [];

        //parent
        parentItem.map(item => {
            if(item.value === grandParentId) newItemGrandParent.push(item)
            return newItemGrandParent;
        });

        //childItem
        parentItem.map(item => item.children.map(child => {
            if(child.value === parentId) childItem.push(child)
            return childItem;
        }));

        //grandChildItem
        parentItem.map(item => item.children.filter(data => {
            if(data.value === parentId) {
                data.children.filter(child => {
                    if(child.value === selectedItem.value) grandChildItem.push(child);
                    return grandChildItem;
                })
            }
            return grandChildItem;
        }));

        const newChildItem = childItem.map(item => {
            return {
                "label": item.label,
                "labelValue": item.labelValue,
                "value": item.value,
                "check": item.check,
                "checkAll": item.checkAll,
                "description": item.description,
                "sampleType": item.sampleType,
                "hasChildren": item.hasChildren,
                "parent": item.parent,
                "children": grandChildItem
            }
        })

        //get newData
        const newData = newItemGrandParent.map(item => {
            return {
                "label": item.label,
                "labelValue": item.labelValue,
                "value": item.value,
                "check": item.check,
                "checkAll": item.checkAll,
                "sampleType": item.sampleType,
                "description": item.description,
                "hasChildren": item.hasChildren,
                "children": newChildItem
            }
        })
        setStateJsonSelected([...stateJsonSelected, newData[0]]);
    }

    const removeItem = (selectedItem) => {
        setStateJsonSelected(stateJsonSelected.filter(data => data.value !== selectedItem.value))
    }

    const removeItemParent = (selectedItem) => {
        debugger
        //si el item tiene hijos y un padre, procedemos a eliminar a los hijos solamente
        if(selectedItem.parentNode) {
            const filtered = stateJsonSelected.filter(data => data.value === selectedItem.parentNode.value);
            const newData = filtered.map(item => item.children?.filter(data => data.value !== selectedItem.value))

            if(newData[0].length === 0) {
                setStateJsonSelected(stateJsonSelected.filter(data => data.value !== selectedItem.parentNode.value))
                return;
            }

            setStateJsonSelected(stateJsonSelected.map(data => {
                const currentItem = (data.value === selectedItem.parentNode.value);

                const grandParent = {
                    "label": data.label,
                    "labelValue": data.labelValue,
                    "value": data.value,
                    "check": data.check,
                    "checkAll": data.checkAll,
                    "sampleType": data.sampleType,
                    "description": data.description,
                    "hasChildren": data.hasChildren
                }

                if(data.children) {
                    grandParent.children = (currentItem) ? newData[0] : data.children;
                }

                return grandParent;
            }));
            return;
        }
        //si el item solo tiene hijos, los eliminamos
        setStateJsonSelected(stateJsonSelected.filter(data => data.value !== selectedItem.value))
    }

    const removeItemChild = (selectedItem) => {
        const id = selectedItem.value;
        const parentId = selectedItem.parentNode.value;
        let itemChild;
        const itemParent = stateJsonSelected.filter(data => data.value === parentId)
        itemParent.map(item => itemChild = item.children.filter(data => data.value !== id));


        if(itemChild.length === 0) {
            setStateJsonSelected(stateJsonSelected.filter(data => data.value !== parentId))
            return;
        }

        setStateJsonSelected(stateJsonSelected.map(item => {
            const grandParent = {
                "label": item.label,
                "labelValue": item.labelValue,
                "value": item.value,
                "check": item.check,
                "checkAll": item.checkAll,
                "sampleType": item.sampleType,
                "description": item.description,
                "hasChildren": item.hasChildren
            }

            if(item.children) {
                grandParent.children = (item.value === parentId) ? itemChild : item.children;
            }

            return grandParent;
        }) ?? '')
    }

    const removeItemGrandChild = (selectedItem) => {
        debugger
        const grandParentId = selectedItem.parentNode.parentNode.value;
        const parentId = selectedItem.parentNode.value;
        const id = selectedItem.value;
        let itemParent, itemChild, newItemChild = [];

        stateJsonSelected.map(item => {
            if (item.value === grandParentId) {
                itemParent = item.children.filter(child => child.value === parentId);
                itemChild = item.children.filter(child => child.value !== parentId);
            }
            return item;
        });

        itemChild.map(item => newItemChild.push(item))

        if(itemChild.length === 0) {
            setStateJsonSelected(stateJsonSelected.filter(data => data.value !== grandParentId))
            return
        }

        //obtengo el arreglo a actualizar
        const grandChildren = itemParent.map(item => item.children.filter(data => data.value !== id))
        itemParent.map(child => {
            newItemChild.push({
                "label": child.label,
                "labelValue": child.labelValue,
                "value": child.value,
                "check": child.check,
                "checkAll": child.checkAll,
                "description": child.description,
                "sampleType": child.sampleType,
                "hasChildren": child.hasChildren,
                "parent": child.parent,
                "children": grandChildren[0]
            });
            return newItemChild;
        })

        setStateJsonSelected(stateJsonSelected.map(item => {
            const grandParent = {
                "label": item.label,
                "labelValue": item.labelValue,
                "value": item.value,
                "check": item.check,
                "checkAll": item.checkAll,
                "sampleType": item.sampleType,
                "description": item.description,
                "hasChildren": item.hasChildren
            }

            if(item.children) {
                grandParent.children = (item.value === grandParentId) ? newItemChild : item.children;
            }
            return grandParent;
        }))

    }

    const selectEachItem = () => {
        setCheckDataJSON([]);
        let aSingleValues = [];

        const singleValues = stateJsonSelected.filter(item => item.check === true && item.hasChildren === false);
        singleValues.map(item => aSingleValues.push(item.value))
        setCheckDataJSON(aSingleValues);

        const objGrandParents = stateJsonSelected.filter(item => item.children);
        if(objGrandParents.length > 0) {
            setCheckDataJSON([]);
            objGrandParents.map(item => item.children?.map(child => {
                if(!child.children) aSingleValues.push(child.value)
                return objGrandParents;
            }) ?? '')
            setCheckDataJSON(aSingleValues);
        }

        let objParents = [];
        stateJsonSelected.map(item => {
            if(item.children) item.children.map(child => objParents.push(child) ?? '')
            return objParents;
        })

        if(objParents.length > 0) {
            setCheckDataJSON([]);
            objParents.map(item => item.children?.map(child => aSingleValues.push(child.value)) ?? '');
            setCheckDataJSON(aSingleValues);
        }

    }

    useEffect(() => {
        selectEachItem()
    }, [stateJsonSelected])

    return (
        <>
            <CheckTree
                value={checkDataJSON}
                data={objectJsonSelected}
                defaultExpandAll={true}
                cascade={true}
                onSelect={handleSelectedItem}
                renderTreeNode={nodeData => {
                    return (
                        <>
                            <span>{nodeData.label}</span>
                            <Button onClick={() => showDrawerBuilder(nodeData)}>
                                <Icon icon="question2" />
                            </Button>
                        </>
                    );
                }}
            />
        </>
    );
}
