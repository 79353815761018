import React, { useState, useEffect } from 'react'
import { TitleSection } from '../../components/titleSection'
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    Button,
    Icon,
    Schema,
    Notification,
    Input,
    Tag, TagGroup
} from 'rsuite'
import { settingService } from '../../services/setting.service'

import './integration.scss'

export const BoxGeneral = ({ urlLogo, notificationText, notificationEmail }) => {
    const [formValue, setFormValue] = useState({ urlLogo: '', notificationText: '', notificationEmail: '' })

    useEffect(() => { setFormValue({ urlLogo, notificationText, notificationEmail }) }, [urlLogo, notificationText, notificationEmail]);

    const { StringType } = Schema.Types;
    const modelLogo = Schema.Model({ urlLogo: StringType().isRequired('Este campo es requerido.') });
    const modelText = Schema.Model({ notificationText: StringType().isRequired('Este campo es requerido.').maxLength(600, 'El campo no puede tener más de 600 caracteres') });
    const modelNotificationEmail = Schema.Model({ notificationEmail: StringType().maxLength(600, 'El campo no puede tener más de 600 caracteres') });
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [tagList, setTagList] = useState([]);

    const handleSubmit = (formStatus, property, value) => {
        if (formStatus) {
            if (property === "NotificationEmail") {
                if (emailInvalid) { return false; }
                value = tagList.join(";");
            }
            settingService.update({
                Property: property,
                Value: value
            }).then(result => {
                if (result.success) {
                    Notification.success({ title: 'Se actualizo exitosamente.', duration: 5000 });
                } else {
                    console.log(result.message);
                    Notification.error({ title: 'Ocurrió un error al actualizar .', duration: 5000 });
                }
            });
        }
    }

    const showEmails = (value) => {
        setTagList([]);
        let hayError = false;
        if (value !== undefined && value !== null && value !== "") {

            let items = [];
            let correos = value.replaceAll(',', ';');

            if (correos.includes(";")) { items = correos.split(";").filter(Boolean); }
            else { items.push(correos); }

            items.forEach((item) => {
                if (validateEmail(item))
                    setTagList(prevArray => [...prevArray, item]);
                else
                    hayError = item === "" ? false : true;
            });
        }
        setEmailInvalid(hayError);
    }

    function validateEmail(email) {
        let regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return regex.test(email);
    }

    return (
        <section className="wrapper_box_logos">
            <TitleSection title="General" />
            <Form fluid model={modelLogo} formValue={formValue} onChange={formValue => setFormValue(formValue)} onSubmit={(formStatus) => handleSubmit(formStatus, "Logo", formValue.urlLogo)}>
                <FlexboxGrid align="bottom">
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={17}>
                        <FormGroup className="mb-0">
                            <ControlLabel>URL de logo oficial</ControlLabel>
                            <FormControl size="lg" placeholder="Pega aquí la URL de tu logo oficial" name="urlLogo" />
                        </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={7}>
                        <Button className="btn secundary" block size="lg" appearance="primary" type="submit">Guardar</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
            <div className="box_imgs">
                <p className="prev">VISTA PREVIA</p>
                {formValue.urlLogo ? <img src={formValue.urlLogo} alt="Logo oficial" /> : <Icon icon="image"> </Icon>}
            </div>
            <Form fluid model={modelText} formValue={formValue} onChange={formValue => setFormValue(formValue)} onSubmit={(formStatus) => handleSubmit(formStatus, "NotificationText", formValue.notificationText)}>
                <FlexboxGrid align="bottom">
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={17}>
                        <FormGroup className="mb-0">
                            <ControlLabel>Texto de notificaciones por email</ControlLabel>
                            <FormControl componentClass="textarea" rows={3} placeholder="Agrega el texto que aparecerá en los email enviados a clientes" name="notificationText" />
                        </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={7}>
                        <Button className="btn secundary" block size="lg" appearance="primary" type="submit">Guardar</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
            <Form fluid model={modelNotificationEmail} formValue={formValue} onChange={formValue => setFormValue(formValue)} onSubmit={(formStatus) => handleSubmit(formStatus, "NotificationEmail", formValue.notificationEmail)} autocomplete="off">
                <FlexboxGrid align="bottom">
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={17}>
                        <FormGroup className="mb-0">
                            <ControlLabel>Email que recibirá notificaciones</ControlLabel>
                            <FormControl placeholder="Ingresa él o los correos electrónicos" name="notificationEmail" onChange={value => showEmails(value)} />
                        </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={7}>
                        <Button className="btn secundary" block size="lg" appearance="primary" type="submit">Guardar</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid align="bottom">
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={17}>
                        <FormGroup className="mb-0">
                            <TagGroup>
                                {tagList && tagList.map((tagitem) => (
                                    <Tag>{tagitem}</Tag>
                                ))}
                            </TagGroup>
                        </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} colspan={24} xs={24} sm={17}>
                        <FormGroup className="mb-0">
                            <TagGroup>
                                {emailInvalid ? (<Tag color="red">El correo ingresa es invalido.</Tag>) : ""}
                            </TagGroup>
                        </FormGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </section>
    )
}