import React from 'react'
import Moment from 'react-moment'
import 'moment/locale/es';
import getSymbolFromCurrency from "currency-symbol-map"
import { Tag, IconButton, Icon, Whisper, Tooltip } from 'rsuite';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { handleSimulate } from '../../hooks/transactionHook';

export const getColumns = (userSetting, reloadTable) => {

    return [
        {
            name: "Transacción",
            selector: "idTransaction",
        },
        {
            name: "Referencia",
            selector: "referenceNumber",
            wrap: true,
            width: "210px",
        },
        {
            name: "Fecha",
            selector: "date",
            format: (row) => (
                <Moment locale="es" format="DD/MM/YYYY hh:mm:ss A">
                    {row.date}
                </Moment>
            ),
            wrap: true,
            width: "115px",
        },
        {
            name: "Fecha de expiración",
            selector: "expirationDate",
            format: (row) => (
                <Moment locale="es" format="DD/MM/YYYY hh:mm:ss A">
                    {row.expirationDate}
                </Moment>
            ),
            wrap: true,
            width: "115px",
        },
        {
            name: "Correo electrónico",
            selector: "email",
            wrap: true,
            width: "175px",
        },
        {
            name: "Monto",
            selector: "amount",
            format: (row) =>
                getSymbolFromCurrency(row.currency) +
                row.amount.toLocaleString(userSetting.cultureCode, {
                    minimumFractionDigits: 2,
                }),
        },
        {
            name: "Estatus",
            selector: "status",
            wrap: true,
            center: true,
            cell: (row) => (
                <span>
                    <span>
                        {{
                            "Accepted": <Tag color="green">PAGADA</Tag>,
                            "Transaction created": <Tag color="orange">CREADA</Tag>,
                            "Pending payment": <Tag color="orange">PENDIENTE DE PAGO</Tag>,
                            "Review": <Tag color="orange">EN REVISIÓN</Tag>,
                            "Authorized": <Tag color="orange">AUTORIZADA</Tag>,
                            "Canceled by customer": <Tag color="red">CANCELADA POR CLIENTE</Tag>,
                            "Expired": <Tag color="red">EXPIRADA</Tag>,
                            "Rejected": <Tag color="red">RECHAZADA</Tag>,
                            "Reversed": <Tag color="red">REVOCADA</Tag>,
                            "Non-payment": <Tag color="red">NO PAGADA</Tag>,
                            "Replaced": <Tag color="red">REMPLAZADA</Tag>,
                            "Authorization expired": <Tag color="red">AUTORIZACIÓN EXPIRADA</Tag>,
                            "Expired cash payment": <Tag color="red">REFERENCIA EXPIRADA</Tag>
                        }[row.status]}
                    </span >
                </span>
            ),
        },
        {
            name: "Acciones",
            selector: "url",
            center: true,
            cell: (row) => (
                <>
                    <span className="box_ico_table">
                        <CopyToClipboard text={row.url}>
                            <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={<Tooltip>Copiar al portapapeles</Tooltip>}
                            >
                                <IconButton
                                    className="mr-2"
                                    icon={<Icon icon="copy" />}
                                    circle
                                    size="md"
                                />
                            </Whisper>
                        </CopyToClipboard>
                        {/*<Whisper
            placement="bottom"
            trigger="hover"
            speaker={<Tooltip>Cancelar liga</Tooltip>}
          >
            <IconButton
              className="mr-2"
              icon={<Icon icon="ban" />}
              circle
              size="md"
              // onClick={() => handleAction(2, row.idTransaction)}
            />
          </Whisper>*/}
                    </span>
                    {(row.status === "Pending payment" && userSetting.environment === "MODO PRUEBAS") &&
                        <span className="box_ico_table" onClick={() => handleSimulate(row.idTransaction, reloadTable)} >
                            <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={<Tooltip>Simular Pago</Tooltip>}
                            >
                                <IconButton
                                    className="mr-2"
                                    icon={<Icon icon="shopping-cart" />}
                                    circle
                                    size="md"
                                />
                            </Whisper>
                        </span>
                    }
                </>
            ),
        },
    ];
}