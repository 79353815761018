import { callApi, callApiFile } from './service'
import 'moment/locale/es';

const GetBlackList = async (page, rowsOfPage) => {
    return callApi(`/BlackList/GetList?companyId=${sessionStorage["rp_company"]}&page=${page}&rows=${rowsOfPage}`, 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return [];
            }
        });
};

const Save = async (form) => {
    form.companyId = sessionStorage["rp_company"];
    return callApi(`/BlackList/Save`, 'post', form)
        .then((result) => {
            return result
        });
}

const Edit = async (form) => {
    form.companyId = sessionStorage["rp_company"];
    return callApi(`/BlackList/Edit`, 'Put', form)
        .then((result) => {
            return result
        });
}

const GetFieldRules = async () => {
    return callApi(`/BlackList/FieldRules`, 'get')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return [];
            }
        });
}

const GetNumberOfRows = async () => {
    return callApi(`/BlackList/GetTotalRows?companyId=${sessionStorage["rp_company"]}`, 'get')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return [];
            }
        });
}

export const BlackListService = {
    GetBlackList,
    Save,
    GetFieldRules,
    Edit,
    GetNumberOfRows
};
