import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TitleSection } from "../../components/titleSection";
import {
    Row,
    Col,
    Button,
    Icon,
    Tag,
    Modal,
    Container,
    Input,
    Notification,
    Paragraph,
} from "rsuite";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import {ModalConfirm} from "../../components/modals/modalConfirm";
import status from "../../data/status.json";
import getSymbolFromCurrency from "currency-symbol-map";
import { transactionService } from "../../services/transaction.service";
import "./transactions.scss";
import { accountService } from "../../services/account.service";
import { StatusEnum } from "../../enum/statusEnum";
import { PaymentMethodEnum } from "../../enum/paymentMethodEnum";

export const TransactionDetail = (props) => {
    debugger
    const [detail, setDetail] = useState(props.location.state);
    const userSetting = useSelector((state) => state.userSetting);
    const [show, setShow] = useState(false);
    const [amountRefund, setAmountRefund] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const statusReembolso = [StatusEnum.Accepted, StatusEnum.Refund, StatusEnum.PartialRefund];

    const initialFilters = {
        idStatus: 0,
        initDate: "",
        endDate: "",
        referenceNumber: '',
        email: "",
        statusChargeBack: [],
        idTransaction: detail.idTransaction
    };
    const setInitialFilters = () => {
        let filtersTmp = initialFilters;
        if (sessionStorage["rp_filters"] !== undefined && props.location.toReturn) {
            filtersTmp = JSON.parse(sessionStorage["rp_filters"]);
            filtersTmp.initDate = new Date(filtersTmp.initDate);
            filtersTmp.endDate = new Date(filtersTmp.endDate);
            filtersTmp.statusChargeBack = [];
        }

        return filtersTmp;
    };
    const [filters, setFilters] = useState(setInitialFilters());

    const refundTransaction = () => {
        if (
            amountRefund == undefined ||
            parseFloat(amountRefund) <= 0 ||
            parseFloat(amountRefund) > detail.amount - detail.refundedAmount
        ) {
            Notification.error({
                title: "El monto ingresado es invalido",
                duration: 5000,
            });
            setTimeout(function () {
                handleShow();
            }, 5500);

            return false;
        } else {
            handleClose();
            let item = {
                transactionCode: detail.transactionCode,
                amount: amountRefund,
            };

            ModalConfirm.show({
                size: "xs",
                title: "Confirmación de reembolso",
                text: `¿Estas seguro que deseas reembolsar la cantidad de ${getSymbolFromCurrency(detail.currency) +
                    amountRefund.toLocaleString(userSetting.cultureCode, {
                        minimumFractionDigits: 2,
                    })
                    }?`,
                onOk: () => refund(item),
            });
        }
    };

    const refund = (item) => {
        transactionService.refundTransaction(item).then((result) => {
            setAmountRefund();
            if ((result.status !== "Refund" && result.status !== "PartialRefund") || validMessage(result.message)) {
                Notification.error({
                    title: "Error",
                    duration: 10000,
                    description: (
                        <p width={320} rows={3}>
                            {result.message}
                        </p>
                    )
                });
            } else {
                Notification.success({
                    title: "Reembolso exitoso",
                    duration: 10000,
                    description: (
                        <p width={320} rows={3}>
                            {result.message}
                        </p>
                    )
                });
            }
            getSameTransaction();
        }).catch((error) => {
            console.error("Error en refundTransaction: ", error);
        });
    };

    const validMessage = (message) => {
        const errors = [
            "There is already a transaction in process, try again later.",
            "1003",
            "Unexpected character encountered",
            "INVALID_DATA"
        ];

        return errors.some(error => message.startsWith(error));
    }


    const getSameTransaction = () => {
        transactionService
            .get({ ...filters, pageNumber: 1, rowsOfPage: 1 })
            .then((itemsReport) => {
                setDetail(itemsReport[0]);
            });
    }

    const [role, setRole] = useState("");

    useEffect(() => {
        if (detail.isTotal || detail.idPaymentMethod == PaymentMethodEnum.Aplazo) setAmountRefund(detail.amount - detail.refundedAmount);
        accountService.role(true).then((result) => {
            setRole(result);
        });
        getSameTransaction();
        if (props.location.state.filters.transaction != null) { sessionStorage.setItem("rp_filters", props.location.state.filters.transaction); }
        if (props.location.state.filters.preauth != null) { sessionStorage.setItem("rp_filters_PreAuth", props.location.state.filters.preauth); }
        if (props.location.state.pages.transaction != null) { sessionStorage.setItem("rp_ActivePage", props.location.state.pages.transaction); }
        if (props.location.state.pages.preauth != null) { sessionStorage.setItem("rp_ActivePage_PreAuth", props.location.state.pages.preauth); }
    }, [props]);

    const handleChange = (value) => {
        setAmountRefund(value);
    };

    const openModal = () => {
        if (detail.isTotal && detail.isRequiredTime) {
            Notification.info({
                title: "Información",
                duration: 10000,
                description: (
                    <p width={320} rows={3}>
                        Se debe esperar un mínimo de 5 minutos para realizar el reembolso
                    </p>
                ),
            });
        } else {
            handleShow();
        }
    };

    return (
        <section className="transactions_details">
            <div className="mb-4">
                <Link to={props.location.state.origin ? props.location.state.origin : "transactions"}>
                    <Button className="btn c_primary p0" appearance="link" type="button">
                        <Icon icon="chevron-left" />
                        <span className="fw-400"> Regresar </span>
                    </Button>
                </Link>
            </div>
            <TitleSection title="Detalle de la transacción" />

            <Row className="mt-3">
                <Col sm={24} md={8}>
                    <div className="cards firstdata">
                        <p className="paragraph mb-4">
                            {detail && (
                                <span className="fw-30d0">
                                    <Tag color={status[detail.statusId].color}>
                                        <div className="labels">Estatus</div>
                                        <div>{status[detail.statusId].text}</div>
                                    </Tag>
                                </span>
                            )}
                        </p>
                        <p className="paragraph mb-3">
                            <div className="fw-700">Referencia: </div>
                            <div className="fw-300">{detail && detail.referenceNumber}</div>
                        </p>
                        <p className="paragraph mb-3">
                            <div className="fw-700 mb-2">Monto de transacción: </div>
                            <h2 className="fw-300">
                                {detail &&
                                    getSymbolFromCurrency(detail.currency) +
                                    detail.amount.toLocaleString(userSetting.cultureCode, {
                                        minimumFractionDigits: 2,
                                    })}
                            </h2>
                        </p>
                        {detail && detail.statusId === StatusEnum.Accepted && (
                            <>
                                <p className="paragraph mb-3">
                                    <div className="fw-700 mb-2">Comisión: </div>
                                    <h2 className="fw-300">
                                        {getSymbolFromCurrency(detail.currency) +
                                            detail.commission.toLocaleString(
                                                userSetting.cultureCode,
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                    </h2>
                                </p>
                                {detail.companyCommission > 0 && <p className="paragraph mb-3">
                                    <div className="fw-700 mb-2">Cargo por servicio: </div>
                                    <h2 className="fw-300">
                                        {getSymbolFromCurrency(detail.currency) +
                                            detail.companyCommission.toLocaleString(
                                                userSetting.cultureCode,
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                    </h2>
                                </p>}

                                <p className="paragraph mb-3">
                                    {detail && detail.idConciliation ? (
                                        <div className="fw-700 mb2 rs-tag-red">
                                            Total depositado:
                                        </div>
                                    ) : (
                                        <div className="fw-700 mb-2">Total a depositar:</div>
                                    )}
                                    <h1 className="fw-400">
                                        {getSymbolFromCurrency(detail.currency) +
                                            detail.total.toLocaleString(userSetting.cultureCode, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                    </h1>
                                </p>
                            </>
                        )}
                        {detail && detail.authCode && detail.paymentDate && (
                            <>
                                <hr />
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Fecha de pago: </span>
                                    <span className="fw-300">
                                        <Moment locale="es" format="DD/MM/YYYY">
                                            {detail.paymentDate}
                                        </Moment>
                                    </span>
                                </p>
                            </>
                        )}
                        {detail && !detail.authCode && detail.authResult && (
                            <p className="paragraph mb-3">
                                <div className="fw-700 mb-1">Razón de declinación: </div>
                                <div className="fw-300">{detail.authResult}</div>
                            </p>
                        )}
                        {detail && detail.idStatusChargeBack && (
                            <p className="paragraph mb-3">
                                <span className="fw-700">Status de contracargo: </span>
                                <span className="fw-300">{detail.statusChargeBack}</span>
                            </p>
                        )}
                        {detail && detail.idStatusChargeBack && (
                            <p className="paragraph mb-3">
                                <span className="fw-700 mb-1">Fecha de contracargo: </span>
                                <span className="fw-300">{detail.chargeBackDate}</span>
                            </p>
                        )}
                    </div>
                </Col>
                <Col sm={24} md={16}>
                    <div className="cards ">
                        <Row>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Id: </span>
                                    <span className="fw-300">
                                        {detail && detail.idTransaction}
                                    </span>
                                </p>
                                {detail && detail.paymentMethod && (
                                    <p className="paragraph mb-3">
                                        <span className="fw-700">Método: </span>
                                        <span className="fw-300">{detail.paymentMethod}</span>
                                    </p>
                                )}

                                {detail && detail.account && (
                                    <p className="paragraph mb-3">
                                        <span className="fw-700">Cuenta: </span>
                                        <span className="fw-300">{detail.account}</span>
                                    </p>
                                )}

                                {detail && detail.authCode && (
                                    <p className="paragraph mb-3">
                                        <span className="fw-700">Código de autorización: </span>
                                        <span className="fw-300">{detail.authCode}</span>
                                    </p>
                                )}
                            </Col>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Fecha de creación:</span>
                                    <span className="fw-300">
                                        <Moment locale="es" format="DD/MM/YYYY">
                                            {detail && detail.date}
                                        </Moment>
                                    </span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Correo electrónico: </span>
                                    <span className="fw-300">
                                        {detail && detail.custormerEmail}
                                    </span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">{detail && detail.secure3D && `Transacción por ${detail.secure3D}`}</span>
                                </p>
                                {detail && detail.referenceCash &&
                                    <p className="paragraph mb-3">
                                        <span className="fw-700">Referencia Ticket: </span>
                                        <span className="fw-300">
                                            {detail.referenceCash}
                                        </span>
                                    </p>}
                            </Col>
                        </Row>
                        {(detail.idPaymentMethod == PaymentMethodEnum.CyberSource || detail.idPaymentMethod == PaymentMethodEnum.Aplazo || detail.idPaymentMethod == PaymentMethodEnum.CardGeneric) &&
                            statusReembolso.findIndex((x) => x == detail.statusId) != -1 ? (
                            <>
                                <hr />
                                <Row>
                                    {detail.statusId != StatusEnum.Refund && role == "Admin" ? (
                                        <>
                                            <Col sm={24} md={12}>
                                                <Button
                                                    className="btn primary"
                                                    appearance="primary"
                                                    onClick={openModal}
                                                >
                                                    Devolución
                                                </Button>
                                            </Col>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {detail.statusId != StatusEnum.Accepted ? (
                                        <Col>
                                            <p className="paragraph mb-3">
                                                <span className="fw-700">Monto reembolsado:</span>
                                                <span className="fw-300">
                                                    {getSymbolFromCurrency(detail.currency) +
                                                        detail.refundedAmount.toLocaleString(
                                                            userSetting.cultureCode,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                </span>
                                            </p>
                                            <p className="paragraph mb-3">
                                                <span className="fw-700">Restante: </span>
                                                <span className="fw-300">
                                                    {getSymbolFromCurrency(detail.currency) +
                                                        (
                                                            detail.amount - detail.refundedAmount
                                                        ).toLocaleString(userSetting.cultureCode, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                </span>
                                            </p>
                                        </Col>
                                    ) : (
                                        ""
                                    )}
                                </Row>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Devolución</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Col sm={24} md={12}>
                            <span className="fw-700">Ingresa monto a reembolsar</span>
                            {detail.isTotal || detail.idPaymentMethod == PaymentMethodEnum.Aplazo ? (
                                <>
                                    <Input
                                        type="text"
                                        disabled
                                        value={
                                            getSymbolFromCurrency(detail.currency) +
                                            (amountRefund ? amountRefund : 0).toLocaleString(
                                                userSetting.cultureCode,
                                                {
                                                    minimumFractionDigits: 2,
                                                }
                                            )
                                        }
                                    />
                                    <span className="fw-300">
                                        {detail.idPaymentMethod != PaymentMethodEnum.CardGeneric ? "Solo es posible reembolsar al 100%"
                                            : "Debe transcurrir un período de 24 horas antes de que se puedan realizar reembolsos parciales."}
                                    </span>
                                </>
                            ) : (
                                <Input
                                    type="number"
                                    value={amountRefund}
                                    max={detail.amount - detail.refundedAmount}
                                    min={1}
                                    autoFocus
                                    onChange={handleChange}
                                    placeholder={`Monto Maximo ${getSymbolFromCurrency(detail.currency) +
                                        (detail.amount - detail.refundedAmount).toLocaleString(
                                            userSetting.cultureCode,
                                            {
                                                minimumFractionDigits: 2,
                                            }
                                        )
                                        }`}
                                />
                            )}
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn primary"
                        appearance="primary"
                        onClick={refundTransaction}
                    >
                        Reembolsar
                    </Button>
                    <Button
                        className="btn secundary"
                        appearance="primary"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};