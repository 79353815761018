import React, {useState} from "react";
import {Button, Col, Modal} from "rsuite";

/*Importing styles*/
import "./docs.scss";
import diagram1 from "../../assets/imgs/diagram_06.svg";
import {FaTimes} from "react-icons/fa";

export const AntifraudValidations = () => {

    const [stateImg, setStateImg] = useState('');
    const [modal, setModal] = useState(false);

    const showModal = (img) => {
        setStateImg(img)
        setModal(true);
    }
    const hideModal = () => setModal(false);

    return (
        <>
            <Col xs={24}>
                <h4>Validaciones antifraude</h4>
                <p>El proceso de revisión se lleva a cabo cuando una transacción da como resultado un Código de Respuesta 007.</p>
                <div className="figure">
                    <img src={diagram1} alt="" onClick={() => showModal(diagram1)} />
                    <figcaption>Diagrama de 3D Secure</figcaption>
                </div>
                <p>
                    3-D Secure es una capa de seguridad adicional para transacciones de tarjetas de crédito y débito en línea que ayuda a evitar
                    fraudes en las transacciones de tarjeta no presente, no autorizadas.<br /> <br />
                    Una transacción que utilice Verified-by-Visa o SecureCode iniciará una redirección al sitio web del banco emisor de la tarjeta
                    para autorizar la transacción. Cada emisor podría usar cualquier tipo de método de autenticación, pero por lo general, se
                    ingresa una contraseña vinculada a la tarjeta al realizar compras en línea. De esta manera, los sistemas del banco pueden ser
                    responsables de la mayoría de las violaciones de seguridad. Hoy es fácil enviar una contraseña de uso único como parte de
                    un mensaje de texto (SMS) a los teléfonos móviles y correos electrónicos de los usuarios para la autenticación.
                </p>
            </Col>
            <Modal size="lg" show={modal} onHide={hideModal}>
                <Modal.Header style={{display:'flex', justifyContent: 'flex-end'}}>
                    <Button color={'red'} onClick={hideModal}>
                        <span style={{marginTop: '2px'}}><FaTimes /></span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <img src={stateImg} alt="" style={{width: '100%', height: 'auto'}} />
                </Modal.Body>
            </Modal>
        </>
    );
}
