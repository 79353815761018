import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/es";
import getSymbolFromCurrency from "currency-symbol-map";
import { Tag, IconButton, Icon, Whisper, Tooltip } from "rsuite";

export const getColumns = (event) => {
    return [
        {
            name: 'Tipo de regla',
            selector: row => row.fieldRulesValue,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Valor',
            selector: row => row.value,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Status',
            selector: row => row.activeText,
            sortable: true,
            wrap: true,
            width: "120px"
        },
        {
            cell: (propsItem) => <IconButton color='red' onClick={()=> event(propsItem)}
                icon={<Icon icon={'edit'} />}
            />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            wrap: true,
        },
    ];
}