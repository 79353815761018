import React from "react";
export const TablePost = ({typePost = 'POST'}) => {

    return (
        <div className="table-docs">
            <div className="title">
                <p>Request Type</p>
            </div>
            <div className="content">
                <p>{typePost}</p>
            </div>
        </div>
    );
}
