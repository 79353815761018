import React, { useState, useEffect } from "react";
import { TitleSection } from "../../components/titleSection";
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    Button,
    Icon,
    Tooltip,
    Whisper,
    Schema,
    Notification,
} from "rsuite";
import { settingService } from "../../services/setting.service";

import "./integration.scss";

export const BoxWebhooks = (props) => {
    const [formValue, setFormValue] = useState({
        urlResponseSuccess: "",
        urlResponseError: "",
        urlResponseDefault: "",
    });

    useEffect(() => {
        setFormValue({ ...props });
    }, [props]);

    const { StringType } = Schema.Types;
    const modelSuccess = Schema.Model({
        urlResponseSuccess: StringType().isRequired("Este campo es requerido."),
    });
    const modelError = Schema.Model({
        urlResponseError: StringType().isRequired("Este campo es requerido."),
    });
    const modelDefault = Schema.Model({
        urlResponseDefault: StringType().isRequired("Este campo es requerido."),
    });

    const handleSubmit = (formStatus, url, property) => {
        if (formStatus) {
            settingService
                .update({
                    Property: property,
                    Value: url
                })
                .then((result) => {
                    if (result.success) {
                        Notification.success({
                            title: "La url se actualizo exitosamente.",
                            duration: 5000,
                        });
                    } else {
                        console.log(result.message);
                        Notification.error({
                            title: "Ocurrió un error al actualizar la url.",
                            duration: 5000,
                        });
                    }
                });
        }
    };

    const tooltipExito = (
        <Tooltip>
            Descripción liga <i>éxito</i> .
        </Tooltip>
    );
    const tooltipError = (
        <Tooltip>
            Descripción liga <i>error</i> .
        </Tooltip>
    );
    const tooltipDefecto = (
        <Tooltip>
            Descripción liga <i>defecto</i> .
        </Tooltip>
    );
    return (
        <section className="wrapper_box_webhook">
            <TitleSection title="Webhooks" />
            <div className="mt-5">
                <Form
                    fluid
                    model={modelSuccess}
                    formValue={formValue}
                    onChange={(form) => setFormValue(form)}
                    onSubmit={(formStatus) =>
                        handleSubmit(formStatus, formValue.urlResponseSuccess, "Success")
                    }
                >
                    <FlexboxGrid align="bottom" className="mb-5">
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            colspan={24}
                            xs={24}
                            sm={16}
                            md={19}
                        >
                            <FormGroup>
                                <ControlLabel>
                                    URL de éxito{" "}
                                    <Whisper
                                        placement="bottom"
                                        trigger="hover"
                                        speaker={tooltipExito}
                                    >
                                        <Icon
                                            className="box_ico_tool"
                                            icon="question-circle2"
                                        ></Icon>
                                    </Whisper>
                                </ControlLabel>
                                <FormControl size="lg" name="urlResponseSuccess" />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            colspan={24}
                            xs={24}
                            sm={8}
                            md={5}
                        >
                            <Button
                                className="btn secundary"
                                block
                                size="lg"
                                appearance="primary"
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
                <Form
                    fluid
                    model={modelError}
                    formValue={formValue}
                    onChange={(form) => setFormValue(form)}
                    onSubmit={(formStatus) =>
                        handleSubmit(formStatus, formValue.urlResponseError, "Error")
                    }
                >
                    <FlexboxGrid align="bottom" className="mb-5">
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            colspan={24}
                            xs={24}
                            sm={16}
                            md={19}
                        >
                            <FormGroup>
                                <ControlLabel>
                                    URL de error{" "}
                                    <Whisper
                                        placement="bottom"
                                        trigger="hover"
                                        speaker={tooltipError}
                                    >
                                        <Icon
                                            className="box_ico_tool"
                                            icon="question-circle2"
                                        ></Icon>
                                    </Whisper>
                                </ControlLabel>
                                <FormControl size="lg" name="urlResponseError" />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            colspan={24}
                            xs={24}
                            sm={8}
                            md={5}
                        >
                            <Button
                                className="btn secundary"
                                block
                                size="lg"
                                appearance="primary"
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
                <Form
                    fluid
                    model={modelDefault}
                    formValue={formValue}
                    onChange={(form) => setFormValue(form)}
                    onSubmit={(formStatus) =>
                        handleSubmit(formStatus, formValue.urlResponseDefault, "Default")
                    }
                >
                    <FlexboxGrid align="bottom" className="mb-5">
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            colspan={24}
                            xs={24}
                            sm={16}
                            md={19}
                        >
                            <FormGroup>
                                <ControlLabel>
                                    URL por defecto{" "}
                                    <Whisper
                                        placement="bottom"
                                        trigger="hover"
                                        speaker={tooltipDefecto}
                                    >
                                        <Icon
                                            className="box_ico_tool"
                                            icon="question-circle2"
                                        ></Icon>
                                    </Whisper>
                                </ControlLabel>
                                <FormControl size="lg" name="urlResponseDefault" />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            colspan={24}
                            xs={24}
                            sm={8}
                            md={5}
                        >
                            <Button
                                className="btn secundary"
                                block
                                size="lg"
                                appearance="primary"
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </div>
        </section>
    );
};
