import React, { useState } from "react";
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    SelectPicker,
    Schema,
    Notification,
    Icon,
} from "rsuite";
import bg_login from "../../assets/imgs/bglogin.png";
import { accountService } from "../../services/account.service";
import environments from "../../data/environments.json";

import "./login.scss";

export function Login(props) {
    const { StringType } = Schema.Types;

    const model = Schema.Model({
        UserName: StringType().isRequired("Este campo es requerido."),
        Password: StringType().isRequired("Este campo es requerido."),
        Environment: StringType().isRequired("Este campo es requerido."),
    });

    const [formValue, setFormValue] = useState({});

    const handleSubmit = (formStatus) => {
        if (formStatus) {
            accountService.login(formValue).then((result) => {
                if (result) {
                    props.history.push("/business");
                } else {
                    Notification.error({
                        title: "Inicio de sesión no valido.",
                        duration: 5000,
                    });
                }
            });
        }
    };


    const [showPass, setShowPass] = useState("password");
    const [activePass, setActivePass] = useState('eye-slash')


    const iconPass = () => {
        setShowPass(showPass === "password" ? "text" : "password");
        setActivePass(activePass === "eye-slash" ? "eye" : "eye-slash");
    };

    return (
        <div className="wrapper_login ">
            <div className="am_container">
                <Form
                    onChange={(formValue) => setFormValue(formValue)}
                    model={model}
                    onSubmit={handleSubmit}
                    fluid
                    className="box_form"
                >
                    <div className="text-center mb-5">
                        <h3 className="mb-1 c_primary fw-700">Bienvenido</h3>
                        <h5 className="fw-300">Estamos listos para ayudarte</h5>
                    </div>
                    <FormGroup>
                        <ControlLabel>Nombre de usuario</ControlLabel>
                        <FormControl name="UserName" type="text" size="lg" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Contraseña</ControlLabel>
                        <span className="inputIcon">
                            <FormControl name="Password" type={showPass} size="lg" />
                            <span className="icoPass" onClick={iconPass}>
                                <Icon icon={activePass} size="lg" />
                            </span>
                        </span>
                    </FormGroup>
                    <FormGroup className="mb-1">
                        <ControlLabel>Modo de sesión</ControlLabel>
                        <FormControl
                            accepter={SelectPicker}
                            block
                            size="lg"
                            name="Environment"
                            data={environments}
                            searchable={false}
                            placeholder="Seleccione"
                        />
                    </FormGroup>
                    <FormGroup className="mt-5">
                        <ButtonToolbar>
                            <Button
                                className="btn primary"
                                block
                                appearance="primary"
                                size="lg"
                                type="submit"
                            >
                                Iniciar sesión
                            </Button>
                        </ButtonToolbar>
                    </FormGroup>
                </Form>
                <img
                    className="bg_img_login"
                    src={bg_login}
                    alt="Red Pay MX, Pagos online, visa, mastercard, oxxo pay"
                />
            </div>
        </div>
    );
}
