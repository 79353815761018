import { callApi, callApiFile } from './service'
import 'moment/locale/es';

//summary
const summary = async (period) => {
    return callApi(
        `/transaction/summary/${sessionStorage["rp_company"]}/${period}`,
        "GET"
    ).then((result) => {
        if (result.success) {
            return result.data;
        } else {
            console.log(result.message);
            return {};
        }
    });
}

//status
const status = async () => {
    return callApi("/transaction/Status", "GET").then((result) => {
        if (result.success) {
            return result.data;
        } else {
            console.log(result.message);
            return [];
        }
    });
}

const statusChargeBack = async () => {
    return callApi("/Transaction/StatusChargeBack", "GET").then((result) => {
        if (result.success) {
            return result.data;
        } else {
            console.log(result.message);
            return [];
        }
    })
}

// get
const get = async ({
    idStatus,
    initDate,
    endDate,
    referenceNumber,
    idTransaction,
    email,
    statusChargeBack,
    referenceCash,
    pageNumber,
    rowsOfPage }) => {
    idStatus = idStatus ? idStatus : 0;
    initDate = initDate ? initDate.toLocaleDateString("ja-JP") : "";
    endDate = endDate ? endDate.toLocaleDateString("ja-JP") : "";
    email = email ? email : "";
    idTransaction = idTransaction ? idTransaction : 0;
    statusChargeBack = statusChargeBack ? statusChargeBack.map(String) : [""];
    referenceCash = referenceCash ? referenceCash : "";
    return callApi(
        `/transaction?idCompany=${sessionStorage["rp_company"]}&idStatus=${idStatus}&initDate=${initDate}&endDate=${endDate}&idTransaction=${idTransaction}&referenceNumber=${referenceNumber}&email=${email}&statusChargeBack=${statusChargeBack}&referenceCash=${referenceCash}&pageNumber=${pageNumber}&rowsOfPage=${rowsOfPage}`,
        "GET"
    ).then((result) => {
        if (result.success) {
            return result.data;
        } else {
            // console.log(result.message);
            return [];
        }
    });
}

// generate report
const generateReport = async ({
    idStatus,
    initDate,
    endDate,
    referenceNumber,
    email,
    statusChargeBack,
    referenceCash,
    rows }
) => {
    idStatus = idStatus ? idStatus : 0;
    initDate = initDate && initDate != "Invalid Date" ? initDate.toLocaleDateString("ja-JP") : "";
    endDate = endDate && endDate != "Invalid Date" ? endDate.toLocaleDateString("ja-JP") : "";
    statusChargeBack = statusChargeBack ? statusChargeBack.map(String) : '';
    email = email ? email : '';
    referenceCash = referenceCash ? referenceCash : "";
    return callApi(
        `/transaction/generatereport?idCompany=${sessionStorage["rp_company"]}&idStatus=${idStatus}&initDate=${initDate}&endDate=${endDate}&referenceNumber=${referenceNumber}&email=${email}&statusChargeBack=${statusChargeBack}&referenceCash=${referenceCash}&rows=${rows}`,
        "GET"
    )
        .then((blob) => {
            if (blob.size !== 0) {
                var FileSaver = require("file-saver");
                FileSaver.saveAs(
                    blob,
                    `Red_Pay_${sessionStorage["rp_company"]
                    }_${new Date().toLocaleDateString("ja-JP")}.csv`
                );
            }
        })
        .catch((error) => true);
}

//capture
const capture = (model) => {
    model.IdCompany = parseInt(sessionStorage["rp_company"]);
    model.Amount = parseFloat(model.Amount);
    return callApi("/transaction/capture", "POST", model).then(
        (result) => result
    );
}

//cancel
const cancel = async (model) => {
    model.IdCompany = parseInt(sessionStorage["rp_company"]);
    return callApi("/transaction/cancel", "POST", model).then((result) => result);
}

// get capture lines
const getCaptureLines = async (
    initDate,
    endDate,
    referenceNumber,
    pageNumber,
    rowsOfPage
) => {
    return callApi(
        `/transaction/capturelines?idCompany=${sessionStorage["rp_company"]}&initDate=${initDate}&endDate=${endDate}&referenceNumber=${referenceNumber}&pageNumber=${pageNumber}&rowsOfPage=${rowsOfPage}`,
        "GET"
    ).then((result) => {
        if (result.success) {
            return result.data;
        } else {
            console.log(result.message);
            return [];
        }
    });
}

// get capture line dates
const getCaptureLineDates = async () => {
    return callApi(`/transaction/capturelinedates?idCompany=${sessionStorage["rp_company"]}`, 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return {};
            }
        });
}

// create capture line
const createCaptureLine = async (model) => {
    model.idCompany = parseInt(sessionStorage["rp_company"]);
    model.amount = parseFloat(model.amount);
    return callApi('/transaction/captureline', 'POST', model)
        .then((result) => result);
}

// create capture line batch
const createCaptureLineBatch = async (files) => {
    var data = new FormData();

    files.forEach(e => data.append('files', e.blobFile, e.name));

    data.append("idCompany", sessionStorage["rp_company"]);

    return callApiFile('/transaction/capturelinebatch', 'POST', data)
        .then((result) => result);
}

// simulate cash payment in sandbox
const simulateCashPayment = async (id) => {
    return callApi(`/transaction/simulatecashpayment/${id}`, 'POST')
        .then((result) => result);
}

const refundTransaction = async (item) => {
    item.idCompany = sessionStorage["rp_company"];
    return callApi('/transaction/Refund', 'POST', item)
        .then((result) => result);
}

export const transactionService = {
    summary,
    status,
    statusChargeBack,
    get,
    generateReport,
    capture,
    cancel,
    getCaptureLines,
    getCaptureLineDates,
    createCaptureLine,
    createCaptureLineBatch,
    simulateCashPayment,
    refundTransaction
};

