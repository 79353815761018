import React, { useState, useEffect } from "react";
import { Grid, Row, Icon, InputGroup, Input, Col } from "rsuite";
import { TitleSection } from "../../components/titleSection";
import { ItemBusiness } from "./itemBusiness/itemBusiness";
import { accountService } from "../../services/account.service";

import "./business.scss";

export const Business = () => {
  const [business, setBusiness] = useState([]);
  const [businessSearch, setBusinessSearch] = useState([]);

  useEffect(() => {
    accountService.companies(false).then((result) => {
      setBusinessSearch(result);
      setBusiness(result);
    });
  }, []);

  const handelFilter = (filterName) => {
    filterName = filterName.toUpperCase();
    const search = business.filter((b) =>
      b.name.toUpperCase().includes(filterName)
    );
    setBusinessSearch(search);
  };

  return (
    <section className="wrapper_business">
      <Grid fluid className="am_container z1">
        <div className="box_ttl_business">
          <div className="pt-i">
            <span className="ico_ttl">
              <Icon icon="building-o"></Icon>
            </span>
            <TitleSection title="Lista de negocios" color="#fefefe" />
          </div>

          <InputGroup>
            <Input placeholder="Buscar negocio" onChange={handelFilter} />
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
          </InputGroup>
        </div>
        <Row>
          <Col xs={24} sm={12} md={8}></Col>
        </Row>
        <div className="content_items_oficies">
          {businessSearch.map((item) => (
            <ItemBusiness key={item.id} {...item} />
          ))}
        </div>
      </Grid>
      <div className="pleca_primary">
        <span className="item_ico_sec">
          <Icon icon="building-o"></Icon>
        </span>
      </div>
    </section>
  );
};
