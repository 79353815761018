import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import { Button } from 'rsuite'
import menu from '../../data/menu.json?v=2'

import './asideMenu.scss'

export const AsideMenu = ({ activeMenu, closeAll, role }) => {
    const router = useLocation();
    const [currentYear, setCurrentYear] = useState(0);
    const [activeLink, setActiveLink] = useState('');

    const menuUrls = [
        {
            id: 1,
            parentUrl: '/transactions',
        },
        {
            id: 2,
            parentUrl: '/subscriptions',
            children: '/subscriptions-details',
        },
        {
            id: 3,
            parentUrl: '/preauthorizations',
        },
        {
            id: 4,
            parentUrl: '/paid-leagues',
            children: '/leagues-single'
        },
        {
            id: 5,
            parentUrl: '/fraud-management',
        },
        {
            id: 6,
            parentUrl: '/chargebacks',
            children: '/chargebacks-detail',
        },
        {
            id: 7,
            parentUrl: '/integration',
        },
        {
            id: 8,
            parentUrl: '/paid-leagues',
            children: '/leagues-bulk'
        },
        {
            id: 9,
            parentUrl: '/home',
        },
    ];

    const General = () => {
        const data = menu.filter(a => a.section == "General" && a.roles.includes(role));
        if (data.length > 0)
            return (
                <div>
                    <p className="ttl_sec_menu"><span>GENERAL</span></p>
                    {
                        data.map(item =>
                            <Button key={item.name} appearance="subtle" className="item_nav">
                                <Link
                                    onClick={closeAll}
                                    to={item.url}
                                    className={item.url === activeLink ? ('active') : ''}
                                >{item.name}</Link>
                            </Button>
                        )
                    }
                </div>
            )
        else return null;
    }

    const Development = () => {
        const data = menu.filter(a => a.section == "Development" && a.roles.includes(role));
        if (data.length > 0)
            return (
                <div>
                    <hr />
                    <p className="ttl_sec_menu"><span>DESARROLLO</span></p>
                    {
                        data.map(item =>
                            <Button key={item.name} appearance="subtle" className="item_nav">
                                {
                                    item.anotherTab
                                        ? <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={item.url === activeLink ? ('active') : ''}
                                        >{item.name}</a>
                                        : <Link
                                            onClick={closeAll}
                                            to={item.url}
                                            className={item.url === activeLink ? ('active') : ''}
                                        >{item.name}</Link>
                                }
                            </Button>
                        )
                    }
                </div>
            )
        else return null;
    }

    useEffect(() => {
        const year = new Date();
        setCurrentYear(year.getFullYear());
    }, []);

    useEffect(() => {
        const path = '/'+router.pathname.split('/')[1];
        const data = menuUrls.filter(item => item.children === path || item.parentUrl === path);
        if(data.length > 0) data.map(item => setActiveLink(item.parentUrl))
        else setActiveLink('')

    }, [router.pathname]);

    return (
        <aside className={`wrapper_aside_menu trans ${activeMenu}`}>
            <div className="content_aside_menu">
                <nav className="content_nav">
                    <General />
                    <Development />
                </nav>
                <footer className="box_footer_item">
                    <Link
                        onClick={closeAll}
                        to="/help-center"
                        className={`item_nav_foot ${('/help-center' === router.pathname) ? 'active' : ''}`}

                    >Centro de ayuda</Link>
                    <div className="foot">
                        {currentYear} Red Pay. <br />
            Todos los derechos reservados
          </div>
                </footer>
            </div>
        </aside>
    )
}
