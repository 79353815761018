import React, { useState, useEffect } from "react";
import { TitleSection } from "../../components/titleSection";
import { Table } from "../../components/table";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  Icon,
  Button,
  Pagination,
  Schema,
  Modal,
  HelpBlock,
  Checkbox,
  Input,
  Notification,
} from "rsuite";
import { BlackListService } from "../../services/blackList.Service";
import { getColumns } from "../../hooks/templateBlackListHooks";
const { StringType, NumberType, BooleanType } = Schema.Types;
const { Column, HeaderCell, Cell } = Table;

export const BoxBlackList = (props) => {
  const [dataBlackList, setDataBlackList] = useState([]);
  const [dataFieldRules, setDataFieldRules] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [formError, setFormError] = useState({});
  const [form, setForm] = useState();
  const [formValue, setFormValue] = useState({
    fieldRulesId: "",
    value: "",
    active: true,
  });
  const initialTableData = { rows: 0, pages: 0, activePage: 1 };

  const [model, setModel] = useState(
    Schema.Model({
      fieldRulesId: NumberType().isRequired("Este campo es requerido"),
      value: StringType().isRequired("Este campo es requerido"),
      active: BooleanType(),
    })
  );

  useEffect(() => {
    BlackListService.GetFieldRules().then((result) => {
      setDataFieldRules(result);
      GetNumberOfRows();
    });
  }, []);

  const GetNumberOfRows = () => {
    BlackListService.GetNumberOfRows().then((result) => {
      let tableDataTmp = tableData;
      tableDataTmp.rows = result;
      tableDataTmp.pages = Math.ceil(result / 10);
      setTableData(tableDataTmp);
      Get_Black_List(tableDataTmp.activePage);
    });
  }

  const Get_Black_List = (pageNumber) => {
    let rowsOfPage = 10;
    BlackListService.GetBlackList(pageNumber, rowsOfPage).then((result) => {
      setDataBlackList(result);
    });
  };

  const closeModal = () => {
    setModalShow(false);
  };
  const openModal = () => {
    setModalShow(true);
  };
  const newRule = () => {
    let formitem = { idBlackList: undefined, active: true };
    setFormValue(formitem);
    openModal();
  };
  const ClickTable = (item) => {
    let forItem = {
      idBlackList: item.idBlackList,
      fieldRulesId: item.fieldRulesId,
      value: item.value,
      active: item.active,
    };

    setFormValue(forItem);
    fieldRuleChange(forItem.fieldRulesId);
    openModal();
  };

  const fieldRuleChange = (ruleId) => {
    if (ruleId != null) {
      let item = dataFieldRules.find((x) => x.value == ruleId);
      let rule = StringType().isRequired("Este campo es requerido");

      if (item.label.indexOf("Email") != -1) {
        rule = StringType()
          .isRequired("Este campo es requerido")
          .isEmail("Ingresa una dirección de correo valida");
      } else if (item.label.indexOf("IP") != -1) {
        rule = StringType()
          .isRequired("Este campo es requerido")
          .addRule((value, data) => {
            return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              value
            );
          }, "Ingresa una Dirección Ip Valida");
      } else if (item.label.indexOf("Account") != -1) {
        rule = StringType()
          .isRequired("Este campo es requerido")
          .pattern(
            /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
            "Tarjeta invalida."
          );
      }

      let modelnew = Schema.Model({
        fieldRulesId: NumberType().isRequired("Este campo es requerido"),
        value: rule,
        active: BooleanType(),
      });

      setModel(modelnew);
    }
  };

  const save = () => {
    if (form.check()) {
      if (formValue.idBlackList != undefined) {
        BlackListService.Edit(formValue).then((result) => {
          afterSave(result);
        });
      } else {
        BlackListService.Save(formValue).then((result) => {
          afterSave(result);
        });
      }
    }
  };

  const afterSave = (result) => {
    closeModal();
    if (result.success) {
      Notification.success({ title: result.data, duration: 5000 });
      GetNumberOfRows();
    } else {
      Notification.error({ title: result.data, duration: 5000 });
    }
  }

  const setInitialTableData = () => {
    let tableDataTmp = initialTableData;
    tableDataTmp.activePage = 1;
    return tableDataTmp;
  };
  const [tableData, setTableData] = useState(setInitialTableData());

  const columns = getColumns(ClickTable);
  const handleSelectPage = (page) => {
    let tableDataTmp = tableData;
    tableDataTmp.activePage = page;
    setTableData(tableDataTmp);
    GetNumberOfRows();
  };

  const [open, setOpen] = React.useState(false);

  return (
    <section className="wrapper_box_velocity">
      <TitleSection title="Administración de Lista Negra" />
      <div className="text-right">
        <Button
          appearance="primary"
          type="button"
          className="btn primary"
          onClick={newRule}
        >
          <Icon icon="plus" /> Nueva regla
        </Button>
      </div>

      <div className="mb-5">
        <Table myData={dataBlackList} myColumns={columns} />
        <Pagination
          prev
          last
          next
          first
          size="lg"
          ellipsis
          maxButtons={10}
          boundaryLinks
          pages={tableData.pages}
          activePage={tableData.activePage}
          onSelect={handleSelectPage}
        />
      </div>
      <Modal backdrop={"static"} show={modalShow} onHide={closeModal} className="drawermodal_fraude">
        <Modal.Header className="header_modal_fraude">
          <Modal.Title>Nueva Regla</Modal.Title>
        </Modal.Header>
        <Form
          fluid
          model={model}
          ref={(ref) => setForm(ref)}
          formValue={formValue}
          onChange={(formNewVal) => {
            setFormValue(formNewVal);
          }}
        >
          <Modal.Body className="mb-0 pb-0">
            <FormGroup>
              <ControlLabel>Activo</ControlLabel>
              <FormControl
                name="active"
                checked={formValue.active}
                value={!formValue.active}
                accepter={Checkbox}
                defaultChecked
              />
            </FormGroup>

            <div className="row_flex">
              <FormGroup className={`pt-i ${formError.fieldRulesId ? "has-error" : ""}`}>
                <ControlLabel>Tipo de regla</ControlLabel>
                <FormControl
                  name="fieldRulesId"
                  accepter={SelectPicker}
                  searchable={false}
                  data={dataFieldRules}
                  errorMessage={formError.fieldRulesId}
                  defaultValue={formValue.fieldRulesId}
                  onChange={(rule) => fieldRuleChange(rule)}
                />
                <HelpBlock>{formError.fieldRulesId}</HelpBlock>
              </FormGroup>
              <FormGroup className={`pt-ii ${formError.value ? "has-error" : ""}`}>
                <ControlLabel>Valor</ControlLabel>
                <FormControl
                  name="value"
                  accepter={Input}
                  autocomplete="off"
                  errorMessage={formError.value}
                />
                <HelpBlock>{formError.value}</HelpBlock>
              </FormGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal} appearance="default">
              Cancelar
            </Button>
            <Button appearance="primary" onClick={save} className="btn primary">
              Guardar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </section>
  );
};
