import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Loader } from 'rsuite';
import { TitleSection } from '../titleSection'

import './blockScreen.scss';

export const BlockScreen = () => {
    const blocking = useSelector(state => state.blocking);

    return (
        <Modal show={blocking} className="wrapper_block">
            
            <div className="box_content_block">
                <Loader size="lg"  center content={  <TitleSection color="#EEEEEE" title="Red Pay" />  } vertical speed="slow" />
            </div>
        </Modal >
    )
}