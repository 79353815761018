import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/es";
import getSymbolFromCurrency from "currency-symbol-map";
import { Tag} from "rsuite";
import status from "../../data/status.json";

export const getColumns = (userSetting) => {
    return [
        {
            name: "Id",
            selector: "idTransaction",
            cell: (row) => (
                    row.idTransaction
            ),
        },
        {
            name: "Referencia",
            selector: "referenceNumber",
            wrap: true,
            cell: (row) => (
                    row.referenceNumber
            ),
        },
        {
            name: "Fecha de intento",
            selector: "transactionDate",
            format: (row) => (
                <Moment locale="es" format="DD/MM/YYYY hh:mm:ss A">
                    {row.transactionDate}
                </Moment>
            ),
            wrap: true,
        },
        {
            name: "Cobro correspondiente a",
            selector: "paymentDate",
            format: (row) => (
                row.paymentDate && (row.idStatus !== 8 && row.idStatus !== 9) ?
                    <Moment locale="es" format="DD/MM/YYYY">
                        {row.paymentDate}
                    </Moment > : ""
            ),
            wrap: true,
        },
        {
            name: "Monto",
            width: "100px",
            selector: "amount",
            format: (row) =>
                getSymbolFromCurrency(row.currency) +
                row.amount.toLocaleString(userSetting.cultureCode, {
                    minimumFractionDigits: 2,
                }),
        },
        {
            name: "Número de pago",
            selector: "numberOfPayment",
            wrap: true,
        },
        {
            name: "Estatus",
            selector: "statusId",
            wrap: true,
            cell: (row) => (
                <span>
                    <Tag color={status[row.idStatus].color}>
                        {status[row.idStatus].text}
                    </Tag>
                </span>
            ),
        },
    ];
};
