import {
    Notification
} from 'rsuite';
import { transactionService } from '../services/transaction.service'
import {ModalConfirm} from '../components/modals/modalConfirm'

export const handleSimulate = (id, reloadTable) => {
    ModalConfirm.show({ size: "xs", title: "Simular pago", text: "¿Estas seguro que deseas simular este pago?", onOk: () => simulateCashPayment(id, reloadTable) });
}

export const simulateCashPayment = (id, reloadTable) => {
    transactionService.simulateCashPayment(id)
        .then(result => {
            if (result.success) {
                Notification.success({ title: result.message, duration: 5000 });
                reloadTable();
            }
            else
                Notification.error({ title: result.message, duration: 5000 });
        });
}