import { callApi, callApiFile } from './service'
import 'moment/locale/es';

// get subscription
const getSubscription = async (
    status,
    referenceNumber,
    pageNumber,
    rowsOfPage
) => {
    return callApi(
        `/subscription/subscription?idCompany=${sessionStorage["rp_company"]}&status=${status}&referenceNumber=${referenceNumber}&pageNumber=${pageNumber}&rowsOfPage=${rowsOfPage}`,
        "GET"
    ).then((result) => {
        if (result.success) {
            return result.data;
        } else {
            console.log(result.message);
            return [];
        }
    });
}

// get subscription
const getSubscriptionDetail = async (status, id) => {
    return callApi(
        `/subscription/SubscriptionDetail?idCompany=${sessionStorage["rp_company"]}&status=${status}&id=${id}`,
        "GET"
    ).then((result) => {
        if (result.success) {
            return result.data;
        } else {
            console.log(result.message);
            return {};
        }
    });
}

// get subscription Transaction
const getSubscriptionTransaction = async ({
    idSubscription,
    pageNumber,
    rowsOfPage }) => {
    return callApi(
        `/subscription/transaction?idSubscription=${idSubscription}&pageNumber=${pageNumber}&rowsOfPage=${rowsOfPage}`,
        "GET"
    ).then((result) => {
        if (result.success) {
            return result.data;
        } else {
            // console.log(result.message);
            return [];
        }
    });
}

//cancel subscription
const cancelSubscription = async (model) => {
    model.IdCompany = parseInt(sessionStorage["rp_company"]);
    return callApi("/subscription/CancelSubscription", "POST", model).then(
        (result) => result
    );
}

//cancel subscription
const reactiveSubscription = async (model) => {
    model.IdCompany = parseInt(sessionStorage["rp_company"]);
    return callApi("/subscription/ReactiveSubscription", "POST", model).then(
        (result) => result
    );
}

// generate report
const generateReportSubscription = async (status, referenceNumber, rows) => {
    return callApi(
        `/subscription/generatereportsubscription?idCompany=${sessionStorage["rp_company"]}&status=${status}&referenceNumber=${referenceNumber}&rows=${rows}`,
        "GET"
    )
        .then((blob) => {
            if (blob.size !== 0) {
                var FileSaver = require("file-saver");
                FileSaver.saveAs(
                    blob,
                    `Red_Pay_Subscription_${sessionStorage["rp_company"]
                    }_${new Date().toLocaleDateString("ja-JP")}.csv`
                );
            }
        })
        .catch((error) => true);
}

const getSettings = async () => {
    return callApi(
        `/subscription/Settings?idcompany=${sessionStorage["rp_company"]}`, "GET").then((result) => {
            if (result.success) {
                return result.data;
            } else {
                // console.log(result.message);
                return [];
            }
        });
}

const reporcessSubscriptions = async () => {
    return callApi(
        `/subscription/Reprocess/${sessionStorage["rp_company"]}`, "post").then(
            (result) => result
        );
}

const replaceCardToken = async (idSubscription) => {
    return callApi(`/subscription/CreateLinkToReplaceCard/${idSubscription}`, "post").then((result) => result);
}

export const subscriptionService = {
    getSubscription,
    getSubscriptionDetail,
    getSubscriptionTransaction,
    cancelSubscription,
    reactiveSubscription,
    generateReportSubscription,
    getSettings,
    reporcessSubscriptions,
    replaceCardToken,
};

