import React, {useEffect, useState} from "react";
import {Button, Col, FlexboxGrid} from "rsuite";
import {TestBuilder} from "./testBuilder";
import {TableEndpoint} from "./tableEndpoint";
import {TablePost} from "./tablePost";
import {TableHeaders} from "./tableHeaders";

/*Importing styles*/
import "./docs.scss";
import {useParams} from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {ThemeEditor} from "./themeEditor";
import {BsFillClipboardCheckFill} from "react-icons/bs";
import {FaCopy} from "react-icons/fa";
import {PayWithSession} from "./payWithSession";

export const IntegrationMethods = () => {
    const params = useParams();
    const [scrollTop,setScrollTop] = useState(0);
    const [nameRoute,setNameRoute] = useState('');

    useEffect(() => {
        const handleScroll = () => setScrollTop(window.scrollY);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollTop]);

    const [result, setResult] = useState(false);
    const [resultResponse, setResultResponse] = useState(false);

    async function copyCode2(value,typeResult = null) {
        if(typeResult !== null) {
            try {
                await navigator.clipboard.writeText(value);
                setResult(true);
            } catch (e) {
                setResult(false);
                throw e;
            } finally {
                setTimeout(() => setResult(null), 2000);
            }
        } else {
            try {
                await navigator.clipboard.writeText(value);
                setResultResponse(true);
            } catch (e) {
                setResultResponse(false);
                throw e;
            } finally {
                setTimeout(() => setResultResponse(null), 2000);
            }
        }
    }

    useEffect(() => {
        setNameRoute(`${params.name}`)
    }, [params])

    return (
        <>
            {{
                'integrationWeb':
                    <>
                    <Col xs={24}>
                            <h4>Métodos de integración</h4>
                            <p>Existen disponibles dos métodos, mediante los cuales, se podrá integrar Red Pay.</p>
                            </Col>
                        <Col xs={24} id="web">
                            <h4>Web Checkout</h4>
                            <p>Esta opción de integración proporciona una interfaz gráfica que permite al Usuario la captura de sus datos bancarios y el procesamiento del pago.</p>
                        </Col>
                        <Col xs={24} id="integrationWeb">
                            <h4>Instrucciones de integración.</h4>
                            <p>Las peticiones varían conforme al <a href="/docs/payment-types/capture1" className="underline">Tipo de Pago</a> a realizar. Es necesario enviar la siguiente información en formato JSON con codificación UTF-8.</p>
                        </Col>
                    </>,
                'wci':
                    <>
                        <Col xs={24} id="wci">
                            <h4>Captura inmediata</h4>
                            <p>Permite la propagación de identidad y privilegios. Es necesario hacer una petición con las siguientes características para obtener el JWT</p>
                            <TableEndpoint urlProduction="productionCreateTransaction" urlSandbox="createTransaction" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={1} loadSampleType={1} />
                        </Col>
                    </>,
                'wcda':
                    <>
                        <Col xs={24} id="wcda">
                            <h4>Captura diferida</h4>
                            <h5>Autorización</h5>
                            <p style={{marginTop: '.5rem'}}>Permite la propagación de identidad y privilegios. Es necesario hacer una petición con las siguientes características para obtener el JWT</p>
                            <TableEndpoint urlProduction="productionCreateTransaction" urlSandbox="createTransaction" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={1} loadSampleType={2} />
                        </Col>
                    </>,
                'wcdc':
                    <>
                        <Col xs={24} id="wcdc">
                            <h4>Captura</h4>
                            <TableEndpoint urlProduction="productionCapture" urlSandbox="capture" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={1} loadSampleType={3} />
                        </Col>
                    </>,
                'wcdx':
                    <>
                        <Col xs={24} id="wcdx">
                            <h4>Cancelación de captura</h4>
                            <TableEndpoint urlProduction="productionCancel" urlSandbox="cancel" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={1} loadSampleType={4} />
                        </Col>
                    </>,
                'wsc':
                    <>
                        <Col xs={24} id="wsc">
                            <h4>Creación de suscripción</h4>
                            <TableEndpoint urlProduction="productionCreateTransaction" urlSandbox="createTransaction" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={1} loadSampleType={5} />
                        </Col>
                    </>,
                'wsx':
                    <>

                        <Col xs={24} id="wsx">
                            <h4>Cancelación de suscripción</h4>
                            <TableEndpoint urlProduction="productionCancelSubscription" urlSandbox="CancelSubscription" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={1} loadSampleType={6} />
                            <strong>Nota:</strong>
                            <ul>
                                <li>Una vez concluido el pago, se redireccionará al usuario final de regreso al portal del cliente</li>
                            </ul>
                        </Col>
                    </>,
                'api':
                    <>

                        <Col xs={24} id="api">
                            <h4>API</h4>
                            <p>
                                La integración por este método hace referencia al consumo de los servicios de Red Pay vía API. Al utilizar este método de
                                integración el e-commerce se encarga de recaudar la información del usuario (Datos personales y bancarios), enviársela a
                                Red Pay y procesar el pago.
                            </p>
                        </Col>
                        <Col xs={24} id="integrationAPI">
                            <h4>Instrucciones de integración</h4>
                            <p>
                                Las peticiones varían conforme al Tipo de Pago a realizar. (Véase sección de <a href="/docs/payment-types/capture1" className="underline">Tipos de pago<span></span></a>). Es necesario enviar la siguiente
                                información en Formato JSON con codificación UTF-8.
                            </p>
                        </Col>
                    </>,
                'deferment':
                    <>
                        <Col xs={24} id="deferment">
                            <h4>Aplazo</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={7} />
                        </Col>
                    </>,
                'cash-payments':
                    <>
                        <Col xs={24} id="ape">
                            <h4>Generación de referencia para pagos en efectivo</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={8} />
                        </Col>
                    </>,
                'inmediate-capture-card':
                    <>
                        <Col xs={24} id="inmediate-capture-card">
                            <h4>Captura inmediata con tarjeta</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={9} />
                        </Col>
                    </>,
                'inmediate-capture-session':
                    <>
                        <Col xs={24} id="inmediate-capture-session">
                            <h4>Captura inmediata con sesión</h4>
                            <p>La sesión es el identificador único que contiene la información encriptada de los datos bancarios del usuario. Esta sesión se genera mediante la integración vía formulario de pago</p>
                            <PayWithSession
                                copyCode={copyCode2}
                                result={result}
                            />
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={10} />
                        </Col>
                    </>,
                'inmediate-capture-token-creation':
                    <>
                        <Col xs={24} id="inmediate-capture-token-creation">
                            <h4>Captura Inmediata con creación de token</h4>
                            <p>La sesión es el identificador único que contiene la información encriptada de los datos bancarios del usuario. Esta sesión se genera mediante la integración vía formulario de pago</p>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={12} />
                        </Col>
                    </>,
                'inmediate-capture-token':
                    <>
                        <Col xs={24} id="inmediate-capture-token">
                            <h4>Captura Inmediata con token</h4>
                            <p>La sesión es el identificador único que contiene la información encriptada de los datos bancarios del usuario. Esta sesión se genera mediante la integración vía formulario de pago</p>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={13} />
                        </Col>
                    </>,
                'delayed-capture-auth':
                    <>
                        <Col xs={24} id="delayed-capture-auth">
                            <h4>Captura diferida (Autorización)</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={14} />
                        </Col>
                    </>,
                'delayed-capture-token':
                    <>
                        <Col xs={24} id="delayed-capture-token">
                            <h4>Captura diferida (Autorización con creación de token)</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={15} />
                        </Col>
                    </>,
                'delayed-capture-auth-token':
                    <>
                        <Col xs={24} id="delayed-capture-auth-token">
                            <h4>Captura diferida (Autorización con token)</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={16} />
                        </Col>
                    </>,
                'delayed-capture-msi':
                    <>
                        <Col xs={24} id="delayed-capture-auth-token">
                            <h4>Captura diferida a meses sin intereses</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={17} />
                        </Col>
                    </>,
                'delayed-capture-cancel':
                    <>
                        <Col xs={24} id="delayed-capture-cancel">
                            <h4>Cancelación de captura diferida</h4>
                            <TableEndpoint urlProduction="productionCancel" urlSandbox="cancel" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={18} />
                        </Col>
                    </>,
                'capture':
                    <>
                        <Col xs={24} id="capture">
                            <h4>Captura</h4>
                            <TableEndpoint urlProduction="productionCapture" urlSandbox="capture" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={19} />
                        </Col>
                    </>,
                'suscription-token':
                    <>
                        <Col xs={24} id="suscriptions">
                            <h4>Suscripción con token</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={20} />
                        </Col>
                    </>,
                'suscription-token-initial-date':
                    <>
                        <Col xs={24} id="suscriptions">
                            <h4>Suscripción con token y fecha inicial</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={21} />
                        </Col>
                    </>,
                'suscription-card':
                    <>
                        <Col xs={24} id="ast">
                            <h4>Suscripción con tarjeta</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={22} />
                        </Col>
                    </>,
                'suscription-card-initial-date':
                    <>
                        <Col xs={24} id="suscriptions">
                            <h4>Suscripción con tarjeta y fecha inicial</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={23} />
                        </Col>
                    </>,
                'suscription-cancelation':
                    <>
                        <Col xs={24} id="asct">
                            <h4>Cancelación de suscripción</h4>
                            <TableEndpoint urlProduction="productionCreate" urlSandbox="create" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={24} />
                        </Col>
                    </>,
                'refund':
                    <>
                        <Col xs={24} id="refund">
                            <h4>Reembolso</h4>
                            <p>Existen dos tipos de reembolso: total y parcial. El reembolso parcial únicamente puede realizarse después de un día de ocurrida la transacción</p>
                            <TableEndpoint urlProduction="productionRefund" urlSandbox="refund" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={2} loadSampleType={25} />
                        </Col>
                    </>,
                'transaction-status':
                    <>
                        <Col xs={24} id="transaction-status">
                            <h4>Obtención de estatus de transacción por número</h4>
                            <TableEndpoint urlProduction="productionTransaction" urlSandbox="transaction" />
                            <TablePost typePost={'GET'} />
                            <div className="table-docs">
                                <div className="title">
                                    <p>URL Parameters</p>
                                </div>
                                <div className="content">
                                    <p>{`{transactionNumber}`}</p>
                                    <strong>Nota:</strong>
                                    <ul>
                                        <li><strong>transactionNumber:</strong> Es el folio o número de orden con el que el cliente identifica la compra en su comercio electrónico.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </>,
                'by-reference':
                    <>
                        <Col xs={24} id="by-reference">
                            <h4>Obtención de estatus de transacción por referencia</h4>
                            <TableEndpoint urlProduction="productionReference" urlSandbox="reference" />
                            <TablePost typePost={'GET'} />
                            <div className="table-docs">
                                <div className="title">
                                    <p>URL Parameters</p>
                                </div>
                                <div className="content">
                                    <p>{`{transactionDate}`}</p>
                                    <strong>Nota:</strong>
                                    <ul>
                                        <li><strong>referenceNumber:</strong> Es el folio o número de orden con el que el cliente identifica la compra en su comercio electrónico.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </>,
                'report':
                    <>
                        <Col xs={24} id="report">
                            <h4>Reporte de transacciones</h4>
                            <TableEndpoint urlProduction="productionTransactionDate" urlSandbox="transactionDate" />
                            <TablePost typePost={'GET'} />
                            <div className="table-docs">
                                <div className="title">
                                    <p>URL Parameters</p>
                                </div>
                                <div className="content">
                                    <p>{`{transactionDate}`}</p>
                                    <strong>Nota:</strong>
                                    <ul>
                                        <li><strong>transactionDate:</strong>Fecha en que se generaron las transacciones</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </>,
                'device-fingerprint':
                    <>
                        <Col xs={24} id="device-fingerprint">
                            <h4>Device FingerPrint</h4>
                            <p>
                                Identificador único del dispositivo del pagador. Es importante para la prevención de fraudes.
                                Dentro del html del checkout del cliente: <br /> <br />
                            </p>
                            <ul>
                                <li><p>Incluir el siguiente script dentro de la etiqueta <strong>{`<head>`}</strong></p></li>
                                <li>
                                    <FlexboxGrid justify="end" align="middle" className="mb-3">
                                        <FlexboxGrid.Item>
                                            <Button color="red" onClick={() => copyCode2('<script type="text/javascript" src="https://h.online-metrix.net/fp/tags.js?org_id={OrgId}&session_id={Mid}{ReferenceNumber}"></script>',true)}>
                                                {
                                                    (result) ?
                                                        <>
                                                            <span><BsFillClipboardCheckFill /></span>
                                                        </>
                                                        :
                                                        <span><FaCopy /></span>
                                                }
                                            </Button>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <CodeMirror
                                        value={`<script type="text/javascript" src="https://h.online-metrix.net/fp/tags.js?org_id={OrgId}&session_id={Mid}{ReferenceNumber}"></script>`}
                                        extensions={javascript({jsx: true})}
                                        readOnly={true}
                                        theme={ThemeEditor}
                                        height="auto"
                                    />
                                </li>
                                <li className="mt-4"><p>Incluir la siguiente etiqueta antes del cierre de la etiqueta <strong>{`<body>`}</strong></p></li>
                                <li>
                                    <FlexboxGrid justify="end" align="middle" className="mb-3">
                                        <FlexboxGrid.Item>
                                            <Button color="red" onClick={() => copyCode2('<noscript><iframe style="width: 100px; height: 100px; border:0; position:absolute; top: -5000px;" src="https://h.onlinemetrix.net/fp/tags?org_id={OrgId}&session_id={Mid}{ReferenceNumber}"></iframe></noscript>', true)}>
                                                {
                                                    (resultResponse) ?
                                                        <>
                                                            <span><BsFillClipboardCheckFill /></span>
                                                        </>
                                                        :
                                                        <span><FaCopy /></span>
                                                }
                                            </Button>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <CodeMirror
                                        value={`<noscript><iframe style="width: 100px; height: 100px; border:0; position:absolute; top: -5000px;" src="https://h.onlinemetrix.net/fp/tags?org_id={OrgId}&session_id={Mid}{ReferenceNumber}"></iframe></noscript>`}
                                        extensions={javascript({jsx: true})}
                                        theme={ThemeEditor}
                                        readOnly={true}
                                        height="auto"
                                    />
                                </li>
                            </ul>
                        </Col>
                        <Col xs={24} id="replace-values">
                            <h4>Valores a reemplazar</h4>
                            <ul className="list-docs">
                                <li><strong>{`{Mid}:`}</strong> MerchantId, proporcionado por su ejecutivo de cuenta</li>
                                <li><strong>{`{ReferenceNumber}:`}</strong> Identificador único de la transacción. Es indispensable que coincida con el id que se envía en la petición del pago</li>
                                <li><strong>{`{OrgId}:`}</strong>
                                    <ul>
                                        <li>
                                            <strong>o Productivo:</strong> 9ozphlq
                                        </li>
                                        <li>
                                            <strong>Sandbox:</strong> 45ssiuz3
                                        </li>
                                    </ul>
                                </li>
                                <li><strong>{`{sessionId}:`}</strong></li>
                                <li><strong>{`{guid}:`}</strong> Número de referencia generado por Red pay con cada transacción</li>
                            </ul>
                        </Col>
                    </>,
            }[nameRoute]}
        </>
    );
}
