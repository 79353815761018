import React, { useState, useEffect, useDebugValue } from 'react'
import { TitleSection } from '../../components/titleSection'
import { Table } from '../../components/table'
import { CalendarRange } from '../../components/myCalendar'
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Icon,
    IconButton,
    Button,
    Drawer,
    Pagination,
    InputNumber,
    Schema,
    Notification,
    Whisper,
    Tooltip
} from 'rsuite';
import { transactionService } from '../../services/transaction.service'
import { useSelector } from 'react-redux'
import { getColumns } from "../../hooks/templateColumnsHook";

import './preAuthorizations.scss'

export const PreAuthorizations = (props) => {

    const userSetting = useSelector(state => state.userSetting);
    const [action, setAction] = useState({ type: 0, transactionNumber: 0, label: '', textButton: '' });

    //==== FUNC TO ASIDE-DRAWER ===
    const [activeAside, setActiveAside] = useState(false);
    const toggleAside = () => setActiveAside(prevState => !prevState)
    //=======

    const handleAction = (type, transactionNumber) => {
        toggleAside()
        setAction({ type, transactionNumber });
    };

    const toolCharge = (
        <Tooltip>
            Cobrar monto
        </Tooltip>
    );
    const toolCancel = (
        <Tooltip>
            Cancelar
        </Tooltip>
    );

    let columns = [{
        name: 'Acciones',
        wrap: true,
        width: "120px",
        cell: (row) => <span className="box_ico_table">
            <Whisper placement="bottom" trigger="hover" speaker={toolCharge}>
                <IconButton
                    className="mr-2"
                    icon={<Icon icon="check" />}
                    circle
                    size="md"
                    onClick={() => handleAction(1, row.idTransaction)}
                />
            </Whisper>
            <Whisper placement="bottom" trigger="hover" speaker={toolCancel}>
                <IconButton
                    className="mr-2"
                    icon={<Icon icon="ban" />}
                    circle
                    size="md"
                    onClick={() => handleAction(2, row.idTransaction)}
                />
            </Whisper>
        </span>
    }];
    columns = [...columns, ...getColumns(userSetting, undefined, "PreAuthorizations")];
    columns = columns.filter((item) => item.name !== "Estatus");

    const [data, setData] = useState([]);
    const currentDate = new Date();
    const initialFilters = {
        initDate: currentDate,
        endDate: currentDate,
        referenceNumber: "",
        statusChargeBack: []
    };
    const [filters, setFilters] = useState(() => {
        if (sessionStorage["rp_filters_PreAuth"] !== undefined) {
            let filtersTmp = JSON.parse(sessionStorage["rp_filters_PreAuth"]);
            filtersTmp.initDate = filtersTmp.initDate ? new Date(filtersTmp.initDate) : "";
            filtersTmp.endDate = filtersTmp.endDate ? new Date(filtersTmp.endDate) : "";
            return filtersTmp;
        }
        return initialFilters;
    });
    const [tableData, setTableData] = useState(() => {
        let activePage = 1;
        let sessionPage = sessionStorage['rp_ActivePage_PreAuth'];
        activePage = sessionPage ? parseInt(sessionPage) : activePage;
        return { rows: 0, pages: 0, activePage: activePage }
    });

    useEffect(() => {
        getTransactions(tableData.activePage);
        return () => {
            sessionStorage.removeItem('rp_filters_PreAuth');
            sessionStorage.removeItem('rp_ActivePage_PreAuth');
        }
    }, []);

    const getTransactions = (pageNumber) => {
        let filtersSubmit = setfiltersLocal();
        transactionService.get({ ...filtersSubmit, idStatus: 8, pageNumber, rowsOfPage: 10 })
            .then((result) => {
                sessionStorage.setItem("rp_filters_PreAuth", JSON.stringify(filtersSubmit));
                setData(result);
                if (result.length > 0) setTableData({ rows: result[0].maxRows, pages: result[0].maxPages, activePage: pageNumber });
            });
    }

    const handleFilters = () => {
        getTransactions(tableData.activePage);
    }

    const generateReport = () => {
        if (tableData.rows > 0) {
            transactionService.generateReport({ ...filters, idStatus: 8, rows: tableData.rows });
        }
    }

    const handleSelectPage = (page) => {
        sessionStorage.setItem("rp_ActivePage_PreAuth", page);
        setTableData({ ...tableData, activePage: page });
        getTransactions(page);
    }


    const { NumberType, StringType } = Schema.Types;
    const modelCapture = Schema.Model({
        Amount: NumberType().isRequired('Este campo es requerido.').min(1, 'Este campo debe ser mayor a 0.')
    });

    const modelCancel = Schema.Model({ Reason: StringType().isRequired('Este campo es requerido.') });

    const handleSubmit = (formStatus) => {
        if (formStatus) {
            if (action.type == 1) {
                transactionService.capture(action)
                    .then(result => {
                        if (result.success) {
                            Notification.success({ title: 'Transacción capturada exitosamente', duration: 5000 });
                            handleAction(0, 0);
                            getTransactions(0);
                        } else Notification.error({ title: result.message, duration: 5000 });
                    });
            } else {
                transactionService.cancel(action)
                    .then(result => {
                        if (result.success) {
                            Notification.success({ title: 'Transacción cancelada exitosamente', duration: 5000 });
                            handleAction(0, 0);
                            getTransactions(0);
                        } else Notification.error({ title: result.message, duration: 5000 });
                    });
            }
        }
    }

    const setfiltersLocal = () => {
        let localfilter = filters;
        localfilter.initDate = filters.initDate ? filters.initDate : "";
        localfilter.endDate = filters.endDate ? filters.endDate : "";
        localfilter.statusChargeBack = [];

        return localfilter;
    }

    const initCalendar = (filtersx) => {
        let fechaFinal = undefined;
        let fechaInicial = undefined;
        if (filtersx.endDate != "") { fechaFinal = filtersx.endDate } else { fechaFinal = currentDate; }
        if (filtersx.initDate == "") { fechaInicial = filtersx.initDate } else { fechaInicial = currentDate; }
        return (<CalendarRange
            defaultValue={fechaInicial && fechaFinal ? [fechaInicial, fechaFinal] : []}
            onChange={(value) => {
                var filterSession = JSON.parse(sessionStorage["rp_filters_PreAuth"]);
                filterSession.initDate = value[0];
                filterSession.endDate = value[1];
                sessionStorage.setItem("rp_filters_PreAuth", JSON.stringify(filterSession));
                return setFilters({
                    ...filters,
                    initDate: value[0],
                    endDate: value[1],
                })
            }
            }
        />);
    }

    return (
        <section className="wrapper_transactions">
            <TitleSection title="Preautorizaciones" />
            <div className="text-right">
                <Button className="btn c_primary" appearance="subtle" type="submit" onClick={generateReport}>
                    <Icon icon="file" />
                    <span className="fw-400"> Generar reporte</span>
                </Button>
            </div>
            <div className="box_filter_options">
                <Form onSubmit={handleFilters} >
                    <FlexboxGrid align="bottom">
                        <FlexboxGrid.Item className="mb-3" componentClass={Col} md={8} sm={8} xs={24}>
                            <FormGroup>
                                <ControlLabel>Rango de fechas</ControlLabel>
                                {initCalendar(filters)}
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="mb-3" componentClass={Col} md={12} sm={10} xs={24}>
                            <FormGroup>
                                <ControlLabel>Buscar por referencia</ControlLabel>
                                <InputGroup inside>
                                    <FormControl
                                        size='lg'
                                        name="input-1"
                                        placeholder="Referencia"
                                        // errorMessage={errorMessage}
                                        // errorPlacement={errorPlacement}
                                        onChange={(value) => setFilters({ ...filters, referenceNumber: value })}
                                    />
                                    <InputGroup.Addon>
                                        <Icon icon="search" />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="mb-3" componentClass={Col} md={4} sm={6} xs={24}>
                            <Button className="btn secundary" block size="lg" appearance="primary" type="submit">Buscar</Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </div>

            <div className="mt-3 mb-5">
                <Table myData={data} myColumns={columns}></Table>
                <Pagination prev last next first size="lg" pages={tableData.pages} activePage={tableData.activePage} onSelect={handleSelectPage} />
            </div>

            <Drawer
                size='xs'
                show={activeAside}
                placement='right'
                className="is_drawer"
            >
                <Drawer.Header>
                    <Drawer.Title>Preautorizaciones</Drawer.Title>
                    <Icon
                        className="close_ico"
                        onClick={toggleAside}
                        icon="close"
                    />
                </Drawer.Header>

                <Drawer.Body >
                    {
                        {
                            1: <Form fluid className="mt-5" model={modelCapture} onSubmit={handleSubmit} onChange={formValue => setAction({ ...action, ...formValue })}>
                                <FormGroup>
                                    <ControlLabel>Ingresa monto a cobrar</ControlLabel>
                                    <FormControl name="Amount" size="lg" accepter={InputNumber} />
                                </FormGroup>
                                <Button className="btn primary" size="lg" block appearance="primary" type="submit">Cobrar</Button>
                            </Form>,
                            2: <Form fluid className="mt-5" model={modelCancel} onSubmit={handleSubmit} onChange={formValue => setAction({ ...action, ...formValue })}>
                                <FormGroup>
                                    <ControlLabel>Comentarios de cancelación</ControlLabel>
                                    <FormControl name="Reason" rows={4} componentClass="textarea" />
                                </FormGroup>
                                <Button className="btn primary" size="lg" block appearance="primary" type="submit">Si, cancelar</Button>
                            </Form>
                        }[action.type]}
                    <Icon
                        className="close_ico"
                        onClick={() => handleAction(0, 0)}
                        icon="close" />
                </Drawer.Body>
            </Drawer>

        </section >
    )
}
