export const setUserSetting = (userSetting) => {
    return {
        type: "Set_User_Setting",
        userSetting
    }
}

export const toggleBlocking = () => {
    return {
        type: "Toggle_Blocking"
    }
}