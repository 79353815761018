import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Table,
} from "rsuite";

/*Importing styles*/
import "./docs.scss";
import {FaCopy} from "react-icons/fa";
import FlexboxGridItem from "rsuite/es/FlexboxGrid/FlexboxGridItem";
import {BsFillClipboardCheckFill} from "react-icons/bs";
import {useParams} from "react-router-dom";

export const IntegrationTests = () => {
    const params = useParams();
    const {Column, HeaderCell, Cell} = Table;

    const cardsTable = [
        {
            "id": 1,
            "card": "4242424242424242",
            "brand": "Visa",
            "result": "002"
        },
        {
            "id": 2,
            "card": "5105105105105100",
            "brand": "MasterCard",
            "result": "003"
        },
    ];

    const americanExpressTable = [
        {
            "id": 1,
            "dateExpired": "01/26",
            "responseAMEX": "APPROVED",
            "result": "002"
        },
        {
            "id": 2,
            "dateExpired": "04/37",
            "responseAMEX": "DECLINED",
            "result": "003"
        },
    ];

    const [formValue, setFormValue] = useState({
        americanExpress:
            "345678901234564",
    });

    const [result, setResult] = useState(false);
    async function copyNumberCard(string) {
        try {
            await navigator.clipboard.writeText(formValue.americanExpress);
            setResult(true);
        } catch (e) {
            setResult(false);
            throw e;
        } finally {
            setTimeout(() => {
                setResult(null);
            }, 2000);
        }
    }

    const [scrollTop,setScrollTop] = useState(0);
    const [nameRoute,setNameRoute] = useState(`${params.name}`);

    useEffect(() => {
        const handleScroll = () => setScrollTop(window.scrollY);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [scrollTop]);

    return (
        <>
            <Col xs={24}>
                <h4>Pruebas de integración</h4>
                <p>En el ambiente de pruebas es posible llevar a cabo todas las transacciones necesarias para simular la respuesta de aprobación o rechazo desde Red Pay, hasta tener validada la integración con el comercio electrónico del cliente.</p>
            </Col>
            <Col xs={24} id="simulation">
                <h4>Simulación de resultados</h4>
                <p>Para la simulación de resultados se usarán datos de tarjetas fijas proporcionadas por el banco para obtener siempre el mismo resultado.</p>
            </Col>
            {{
                'visa':
                <>
                    <Col xs={24}>
                        <h4>Tarjetas Visa®/Mastercard®</h4>
                        <p>Para simular resultados de tarjetas Visa® o Mastercard® la información de prueba es la siguiente:</p>
                    </Col>
                    <Col xs={24} id="visa">
                        <figure>
                            <figcaption>Tarjetas para simulación de resultado</figcaption>
                        </figure>
                        <Table
                            data={cardsTable}
                            resizable={true}
                        >
                            <Column width={250} resizable>
                                <HeaderCell>Tarjeta</HeaderCell>
                                <Cell dataKey="card" />
                            </Column>

                            <Column width={150} resizable>
                                <HeaderCell>Marca</HeaderCell>
                                <Cell dataKey="brand" />
                            </Column>

                            <Column width={100} resizable>
                                <HeaderCell>Resultado Red Pay</HeaderCell>
                                <Cell dataKey="result" />
                            </Column>
                        </Table>
                    </Col>
                </>,
                'americanExpress':
                    <Col xs={24} id="american">
                        <h4>Tarjetas American Express®</h4>
                        <p>
                            La simulación de resultados con American Express® requiere de utilizar una tarjeta específica y modificar las fechas de vigencia.<br /> <br/>
                            Siempre debe usarse el siguiente número de tarjeta
                        </p>
                        <div style={{padding: '0 1rem 0 1rem'}}>
                            <FlexboxGrid justify="center">
                                <FlexboxGridItem>
                                    <small className="pb-0 mr-2">American express:</small>
                                </FlexboxGridItem>
                                <FlexboxGridItem>
                                    <Form formValue={formValue} className="form-docs">
                                        <FormGroup style={{maxWidth: '280px'}}>
                                            <FormControl name="americanExpress" readOnly={true} style={{maxWidth: '250px'}} />
                                            <Button color="red" onClick={copyNumberCard}>
                                                {
                                                    (result) ?
                                                        <>
                                                            <span><BsFillClipboardCheckFill /></span>
                                                        </>
                                                        :
                                                        <span><FaCopy /></span>
                                                }

                                            </Button>
                                        </FormGroup>
                                    </Form>
                                </FlexboxGridItem>
                            </FlexboxGrid>
                        </div>
                        <p className="mt-4">La tarjeta mencionada en combinación con la fecha de vencimiento emite diferentes resultados. Las combinaciones pueden ser las siguientes:</p>
                        <figure>
                            <figcaption>Fechas de vencimiento y estado para AMEX</figcaption>
                        </figure>
                        <Table
                            data={americanExpressTable}
                            resizable={true}
                        >
                            <Column width={200} resizable>
                                <HeaderCell>Fecha de Vencimiento</HeaderCell>
                                <Cell dataKey="dateExpired" />
                            </Column>

                            <Column width={250} resizable>
                                <HeaderCell>Respuesta de motor de pagos AMEX</HeaderCell>
                                <Cell dataKey="responseAMEX" />
                            </Column>

                            <Column width={200} resizable>
                                <HeaderCell>Resultado Red Pay</HeaderCell>
                                <Cell dataKey="result" />
                            </Column>
                        </Table>
                    </Col>
            }[nameRoute]}
        </>
    );
}
