import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TitleSection } from '../../components/titleSection'
import {
    FlexboxGrid,
    Col,
    TagGroup,
    Tag,
    Icon
} from 'rsuite'
import { accountService } from '../../services/account.service'

import './myAccount.scss'

export const MyAccount = () => {
    const [account, setAccount] = useState({});

    useEffect(() => {
        accountService.information().then(result => setAccount(result));
    }, []);

    return (
        <section className="wrapper_account">
            <TitleSection title="Mi cuenta" />
            <FlexboxGrid className="mt-5 account">
                <FlexboxGrid.Item componentClass={Col} colspan={24} sm={14} md={15} lg={16}>
                    <div className="cards mb-4">
                        <h6 className="fw-300 paragraph mb-5">Información de usuario</h6>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                <div className="box_info_user main">
                                    <div className="ico_user_info main"><Icon icon="user"></Icon></div>
                                    <div className="w-100">
                                        <div className="fw-700">{account && account.userName}</div>
                                        <div>{account && account.email}</div>
                                        <div><i>{account && account.role}</i></div>
                                    </div>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                    <div className="cards mb-4">
                        <h6 className="fw-300 paragraph mb-5">Información de negocio</h6>
                        <FlexboxGrid>
                            <FlexboxGrid.Item className="mb-4" componentClass={Col} xs={24} sm={12}>
                                <h6 className="mb-2">Nombre del negocio</h6>
                                <p className="fw-300">{account && account.companyName}</p>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="mb-4" componentClass={Col} xs={24} sm={12}>
                                <h6 className="mb-2">Tipo de industria</h6>
                                <p className="fw-300">{account && account.businessActivity}</p>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="mb-4" componentClass={Col} xs={24} sm={12}>
                                <h6 className="mb-2">Tipos de pago</h6>
                                <div className="fw-300">
                                    <TagGroup>
                                        {account.paymentMethods && account.paymentMethods.map(item =>
                                            <Tag>{item}</Tag>
                                        )}
                                    </TagGroup>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} colspan={24} sm={10} md={9} lg={8}>
                    <div className="cards mb-4 info_account">
                        <h6 className="fw-300 paragraph mb-5">Tus negocios afiliados</h6>
                        {account.companies && account.companies.map(item =>
                            <Link to="/home" className="box_info_user is_link" onClick={() => sessionStorage.setItem('rp_company', item.value)}>
                                <div className="ico_user_info ">
                                    <Icon icon="building-o"></Icon>
                                </div>
                                <div>
                                    <h5 className="fw-700">{item.text}</h5>
                                </div>
                            </Link>
                        )}
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </section>
    )
}