import React, { useState, useEffect } from "react";
import { TitleSection } from "../../components/titleSection";
import { BoxGeneral } from "./boxGeneral";
import { BoxKeys } from "./boxKeys";
import { BoxWebhooks } from "./boxWebhooks";
import { FlexboxGrid, Col, Icon } from "rsuite";
import { useSelector } from "react-redux";
import { settingService } from "../../services/setting.service";

import "./integration.scss";

export const Integration = () => {
    const userSetting = useSelector((state) => state.userSetting);
    const [activeTab, setActiveTab] = useState(1);
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [settings, setSettings] = useState({});

    useEffect(() => {
        settingService.get().then((result) => setSettings(result));
    }, []);

    return (
        <section className="wrapper_integration">
            <TitleSection title="Integración" />
            <FlexboxGrid className="conten_integration">
                <FlexboxGrid.Item componentClass={Col} xs={24} sm={7} md={6}>
                    <div className="cards box_controls_config">
                        <p className="fw-700">
                            <small>CONFIGURACIÓN</small>
                        </p>
                        <h4 className="name_business">
                            {" "}
                            <Icon icon="building-o"></Icon> {userSetting.companyName}
                        </h4>
                        <hr />
                        <nav className="nav_list_integration">
                            <ul>
                                <li>
                                    <span
                                        onClick={() => {
                                            toggle(1);
                                        }}
                                        className={"span_link " + (activeTab === 1 ? "active" : "")}
                                    >
                                        General
                                    </span>
                                </li>
                                <li>
                                    <span
                                        onClick={() => {
                                            toggle(2);
                                        }}
                                        className={"span_link " + (activeTab === 2 ? "active" : "")}
                                    >
                                        Keys
                                    </span>
                                </li>
                                <li>
                                    <span
                                        onClick={() => {
                                            toggle(3);
                                        }}
                                        className={"span_link " + (activeTab === 3 ? "active" : "")}
                                    >
                                        Webhooks
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={24} sm={17} md={18}>
                    <div className="section_box_config">
                        <div className={"box_config " + (activeTab === 1 ? "is-show" : "")}>
                            <BoxGeneral urlLogo={settings.urlLogo} notificationText={settings.notificationText} notificationEmail={settings.notificationEmail} />
                        </div>
                        <div className={"box_config " + (activeTab === 2 ? "is-show" : "")}>
                            <BoxKeys
                                apiKey={settings.apiKey}
                                environment={userSetting.environment}
                                publicKey={settings.publicKey}
                            />
                        </div>
                        <div className={"box_config " + (activeTab === 3 ? "is-show" : "")}>
                            <BoxWebhooks {...settings} />
                        </div>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </section>
    );
};
