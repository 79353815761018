import React, { useEffect, useRef, useState } from "react";
import './inputFile.scss';
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import { Notification } from "rsuite";
import { IoMdEye } from "react-icons/io";

export const InputFile = ({
    onFilesSelected,
    width = 0,
    height = 0,
    edit = false,
    accept,
    valueFile = [],
    oneFile = true,
    maxFiles = 1,
    nameFile,
    deleteFile,
    showUrlPreview = false
}) => {
    const [files, setFiles] = useState(valueFile);
    const [empty, setEmpty] = useState([]);
    const [fileType, setFileType] = useState('');
    const inputFileRef = useRef();
    const maxSize = (10 * 1024);
    const [format, setFormat] = useState([]);
    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        let newFiles = Array.from(droppedFiles)

        newFiles.forEach(currentFile => {
            const sizeCurrentFile = Math.round(currentFile.size / 1024);
            const fileType = currentFile.name?.split(".")[1] ?? '';
            setFileType(fileType);

            if (sizeCurrentFile > maxSize) {
                open('Los archivos debe ser menores a ' + 10 + ' MB.');
                clearFiles();
                newFiles = [];
                cleanInput();
                return;
            }

            if (accept === 'image') {
                if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'svg' || fileType === 'webp') {
                    const currentImg = URL.createObjectURL(currentFile)
                    let img = document.createElement("img"), dimensions = {};
                    img.src = currentImg;
                    img.onload = function () {
                        dimensions = {
                            width: img.width,
                            height: img.height
                        };
                        if (dimensions.width > width || dimensions.height > height) {
                            open('La imagen excede las dimensiones permitidas');
                            clearFiles();
                            newFiles = [];
                            cleanInput();
                            return;
                        } else selectedFilesUpload(newFiles)
                    };
                }
            } else if (format.includes(fileType)) selectedFilesUpload(newFiles)
            else {
                open('El archivo no es un formato permitido')
                clearFiles();
                newFiles = [];
                cleanInput();
                return;
            }
        })

    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        deleteFile((prevFiles) => prevFiles.filter((_, i) => i !== index))
        cleanInput();
    }

    const cleanInput = () => inputFileRef.current.value = null;

    const handleFileChange = (e) => {
        let newFiles = Array.from(e.target.files)

        newFiles.forEach(currentFile => {
            const sizeCurrentFile = Math.round(currentFile.size / 1024);
            const fileType = currentFile.name?.split(".")[1] ?? '';
            setFileType(fileType);

            if (sizeCurrentFile > maxSize) {
                open('Los archivos debe ser menores a ' + 10 + ' MB.');
                clearFiles();
                newFiles = [];
                cleanInput();
                return;
            }


            if (accept === 'image') {
                if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'svg' || fileType === 'webp') {
                    const currentImg = URL.createObjectURL(currentFile)
                    let img = document.createElement("img"), dimensions = {};
                    img.src = currentImg;
                    img.onload = function () {
                        dimensions = {
                            width: img.width,
                            height: img.height
                        };
                        if (dimensions.width > width || dimensions.height > height) {
                            open('La imagen excede las dimensiones permitidas');
                            clearFiles();
                            newFiles = [];
                            cleanInput();
                            return;
                        } else selectedFilesUpload(newFiles)
                    };
                }
            } else if (format.includes(fileType)) {
                selectedFilesUpload(newFiles)
                return
            } else {
                open('El archivo no es un formato permitido')
                clearFiles();
                newFiles = [];
                cleanInput();
                return;
            }
        })
    }

    const clearFiles = () => {
        setEmpty(true);
        setFileType('');
    }

    const selectedFilesUpload = (files) => {
        const newFiles = Array.from(files);
        if (oneFile) {
            setFiles(newFiles);
            onFilesSelected(newFiles)
        } else {
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            onFilesSelected(newFiles)
        }
    }

    const open = (name) => {
        return Notification.error({
            title: "Error",
            duration: 5000,
            description: (<p>{name}</p>)
        });
    }

    useEffect(() => {
        if (edit) setFiles(valueFile);
    }, [valueFile]);

    useEffect(() => {
        if (empty) setFiles([])
    }, [empty]);

    useEffect(() => {
        const format = [], acceptedFiles = [];

        for (let i = 0; i < accept.length; i++) {
            if (accept[i] === 'image') {
                format.push('png', 'jpg', 'webp', 'svg', 'jpeg')
                acceptedFiles.push('image/png', 'image/svg', 'image/webp', 'image/jpeg', 'image/jpg')
            }
            if (accept[i] === 'pdf') {
                format.push('pdf')
                acceptedFiles.push('application/pdf')
            }
            if (accept[i] === 'audio') {
                format.push('mp3', 'wav')
                acceptedFiles.push('audio/*')
            }
            if (accept[i] === 'video') {
                format.push('mp4', 'mov', 'mkv,')
                acceptedFiles.push('video/mov', 'video/mp4', 'video/mkv')
            }
            if (accept[i] === 'cvs') {
                format.push('cvs', 'xls', 'xlsx')
                acceptedFiles.push('application/csv', 'application/xlsx', 'application/xls')
            }
            if (accept[i] === 'document') {
                format.push('doc', 'docx', 'txt')
                acceptedFiles.push('application/doc', 'application/docx', 'application/txt')
            }
        }
        setFormat(format);
        setAcceptedFiles(acceptedFiles)
    }, [accept])

    return (
        <section className="drag-drop-container">
            <div
                className={`document-uploader upload-box ${files.length > 0 ? "active" : ""}`}
                onDrop={handleDrop}
            >
                {
                    ((oneFile && files.length === 0) || (!(oneFile) && files.length < maxFiles)) ?
                        <>
                            <div className="upload-info">
                                <AiOutlineCloudUpload />
                                <div>
                                    <p>Arrastra y suelta tus archivos aquí</p>
                                    <p>Límite de 10MB por archivo.
                                        <br />Archivos permitidos: {format.join(', ')}
                                    </p>
                                    {accept.includes('image') ? <p>Dimensiones de imagen: {width}x{height}</p> : null}
                                </div>
                            </div>
                            <label htmlFor={`${nameFile}`} className="browse-btn">Buscar archivos</label>
                        </>
                        : null
                }
                <input
                    ref={inputFileRef}
                    type="file"
                    hidden
                    id={`${nameFile}`}
                    onChange={handleFileChange}
                    accept={acceptedFiles}
                />

                {files.length > 0 && (
                    !(showUrlPreview) ?
                        <div className="file-list">
                            <div className="file-list__container">
                                {files.map((file, index) => (
                                    <div className="file-item" key={index}>
                                        <div className="file-info">
                                            <p>{(file.name) ? file.name : file.fileName}</p>
                                            {/* <p>{file.type}</p> */}
                                        </div>
                                        <div className="file-actions">
                                            <MdClear onClick={() => { handleRemoveFile(index); }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div className="file-list">
                            <div className="file-list__container">
                                {files.map((file, index) => {
                                    let fileName = file.split('/').pop();
                                    return (
                                        <div className="file-item" key={index}>
                                            <div className="file-info">
                                                <a href={file} target={"_blank"}>{fileName}</a>
                                            </div>
                                            <div className="file-actions">
                                                <a href={file} target={"_blank"}><IoMdEye /></a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                )}

                {files.length > 0 && (
                    <div className="success-file">
                        <AiOutlineCheckCircle
                            style={{ color: "#6DC24B", marginRight: 1 }}
                        />
                        {files.length > 1 ? <p> Archivos seleccionados</p> : <p> Archivo seleccionado</p>}
                    </div>
                )}
            </div>
        </section>
    );
}
