import React, {useState} from "react";
import {Button, FlexboxGrid, Modal} from "rsuite";
import {BsFillClipboardCheckFill} from "react-icons/bs";
import {FaCopy, FaTimes} from "react-icons/fa";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {ThemeEditor} from "./themeEditor";
import {html} from "@codemirror/lang-html";
import img1 from '../../assets/imgs/capture_session_01.png';
import img2 from '../../assets/imgs/capture_session_02.png';
import img3 from '../../assets/imgs/capture_session_03.png';
import img4 from '../../assets/imgs/capture_session_04.png';

export const PayWithSession = ({copyCode, result}) => {
    const [modal, setModal] = useState(false);
    const [stateImg, setStateImg] = useState('');

    const showModal = (img) => {
        setStateImg(img)
        setModal(true);
    }
    const hideModal = () => setModal(false);

    return (
        <>
            <h4>Integración vía formulario de pago</h4>
            <ul>
                <li className="mt-4">
                    <p><strong>1.</strong> Obtén la <strong>public key</strong> iniciando sesión en el <a href={'/login'}>Dashboard</a> de RedPay.</p>
                </li>
                <li>
                    <p>
                        <strong>2.</strong>
                        Selecciona la opción de tu negocio.
                        <strong style={{cursor:"pointer", textDecoration: 'underline'}} onClick={() => showModal(img1)}> Ver imagen</strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>3.</strong>
                        Selecciona la opción integración en el menú de la izquierda.
                        <strong style={{cursor:"pointer", textDecoration: 'underline'}} onClick={() => showModal(img2)}> Ver imagen</strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>4.</strong>
                        Da clic en Keys.
                        <strong style={{cursor:"pointer", textDecoration: 'underline'}} onClick={() => showModal(img3)}> Ver imagen</strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>5.</strong>
                        Da clic en el botón <strong>Crear</strong> de la sección Public key.
                        <strong style={{cursor:"pointer", textDecoration: 'underline'}} onClick={() => showModal(img4)}> Ver imagen</strong>
                    </p>
                </li>
            </ul>
            <h4>Integración</h4>
            <h5>Importar librería</h5>
            <ul>
                <li><p>Incluir el siguiente script dentro de la etiqueta <strong>{`<head>`}</strong></p></li>
                <li>
                    <FlexboxGrid justify="end" align="middle" className="mb-3">
                        <FlexboxGrid.Item>
                            <Button color="red" onClick={() => copyCode('<script src=\'https://redpay.mx/script/main.js\'></script>',true)}>
                                {
                                    (result) ?
                                        <>
                                            <span><BsFillClipboardCheckFill /></span>
                                        </>
                                        :
                                        <span><FaCopy /></span>
                                }
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <CodeMirror
                        value={`<script src='https://redpay.mx/script/main.js'></script>`}
                        extensions={javascript({jsx: true})}
                        readOnly={true}
                        theme={ThemeEditor}
                        height="auto"
                    />
                </li>
            </ul><br />
            <h5>Código JS</h5>
            <CodeMirror
                value={`
var sucess_callbak = function (sessionId) {
 console.log("SessionId:", sessionId);
};

var error_callbak = function (errorMessage) {
 console.log("Error:", errorMessage);
};

RedPay.configure({
 apiKey: "xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx", // sustituir por ApiKey
 publicKey: "xxxxxxxxxxxxxx", // sustituir por PublicKey generada desde el dashboard
 isProduction: false, // modificar a true para transaccionar en producción
 fields: {
     card: {
        fistName: "fist_name", // sustituir por id de input de correspondiente
         lastName: "last_name", // sustituir por id de input de correspondiente
         number: "card_number", // sustituir por id de input de correspondiente
         expiryMonth: "expiration_month", // sustituir por id de input de correspondiente
         expiryYear: "expiration_year", // sustituir por id de input de correspondiente
         securityCode: "security_code", // sustituir por id de input de correspondiente
     },
 },
     callbacks: {
     success: (response) => sucess_callbak,
     error: (error) => error_callbak,
 },
});

const onSubmit = () => {
 event.preventDefault();
 RedPay.createFormSession();
};
                                `}
                extensions={javascript({jsx: true})}
                readOnly={true}
                theme={ThemeEditor}
                height="auto"
            />
            <br />
            <h5>HTML</h5>
            <CodeMirror
                value={`
<form onsubmit="onSubmit(event)">
     <label>Nombre</label>
     <input type="text" placeholder="Como aparece en la tarjeta" autocomplete="off" id="fist_name" />
     
     <label>Apellido</label>
     <input type="text" placeholder="Como aparece en la tarjeta" autocomplete="off" id="last_name" />
     
     <label>Número de tarjeta</label>
     <input type="text" autocomplete="off" id="card_number" />
     
     <label>Fecha de expiración</label> </div>
     <input type="text" placeholder="Mes" id="expiration_month"/>
     <input type="text" placeholder="Año" id="expiration_year"/>
     
     <label>Código de seguridad</label>
     <input type="text" placeholder="3 dígitos" autocomplete="off" id="security_code" />
     
    <button type="submit">Pagar</button>
</form>
                                `}
                extensions={html()}
                readOnly={true}
                theme={ThemeEditor}
                height="auto"
            />
            <h4>Framework JS</h4>
            <Button color={'red'} href={'https://www.npmjs.com/package/redpay'}>Descargar librería</Button>
            <h4>Generación de transacción en Red Pay</h4>
            <Modal size="lg" show={modal} onHide={hideModal} overflow={false}>
                <Modal.Header style={{display:'flex', justifyContent: 'flex-end'}}>
                    <Button color={'red'} onClick={hideModal}>
                        <span style={{marginTop: '2px'}}><FaTimes /></span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <img src={stateImg} alt="" style={{width: '100%', height: 'auto'}} />
                </Modal.Body>
            </Modal>
        </>
    );
}
