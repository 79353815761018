import React, {useEffect, useRef, useState} from "react";
import {Button, Col} from "rsuite";
import {TestBuilder} from "./testBuilder";
import {TableEndpoint} from "./tableEndpoint";
import {TablePost} from "./tablePost";
import {TableHeaders} from "./tableHeaders";

/*Importing styles*/
import "./docs.scss";
import {useParams} from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {BsFillClipboardCheckFill} from "react-icons/bs";
import {FaCopy} from "react-icons/fa";
import {ThemeEditor} from "./themeEditor";

export const Tokenization = () => {
    const params = useParams();

    const create= useRef();
    const deletes  = useRef();
    const list = useRef();

    const [scrollTop,setScrollTop] = useState(0);
    const [nameRoute,setNameRoute] = useState(`${params.name}`);
    const [dataResponse, setDataResponse] = useState(  {
            "responseCode": "###",
            "status": '<<Status>>',
            "message": '<<Message>>'
    })

    useEffect(() => {
        const handleScroll = () => setScrollTop(window.scrollY);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollTop]);

    const [resultToken, setResulToken] = useState(false);

    async function copyCodeToken(value) {
        try {
            await navigator.clipboard.writeText(value);
            setResulToken(true);
        } catch (e) {
            setResulToken(false);
            throw e;
        } finally {
            setTimeout(() => setResulToken(null), 2000);
        }
    }

    return (
        <>
            {{
                'create':
                    <>
                        <Col xs={24}>
                            <h4>Tokenización</h4>
                            <p>
                                La tokenización es la forma de sustituir los datos sensibles en datos no sensibles denominados “tokens”. Estos tokens pasan
                                de forma segura mediante la red sin exponer la información bancaria.
                            </p>
                        </Col>
                        <Col xs={24} id="create">
                            <h4 ref={create}>Creación de token (CT)</h4>
                            <TableEndpoint urlProduction="productionCustomercardidentifiers" urlSandbox="customercardidentifiers" />
                            <TablePost />
                            <TableHeaders />
                            <TestBuilder loadSampleRequest={3} loadSampleType={29} />
                        </Col>
                    </>,
                'delete':
                    <Col xs={24} id="delete">
                        <h4 ref={deletes}>Eliminación de token</h4>
                        <TableEndpoint urlProduction="productionCustomercardidentifiersDelete" urlSandbox="customercardidentifiersDelete" />
                        <TablePost typePost={'DELETE'} />
                        <div className="table-docs">
                            <div className="title">
                                <p>Headers</p>
                            </div>
                            <div className="content">
                                <p>Authorization: <a href="/docs/authentication-types/auth" className="underline">Tipos de Autenticación <span></span></a></p>
                            </div>
                        </div>
                        <div className="table-docs">
                            <div className="title">
                                <p>URL Parameters</p>
                            </div>
                            <div className="content">
                                <p>{`{id}`}</p>
                                <strong>Nota</strong>
                                <ul>
                                    <li>id: Identificador único de la tarjeta (token)</li>
                                </ul>
                            </div>
                        </div>
                    </Col>,
                'list':
                    <Col xs={24} id="list">
                        <h4 ref={list}>Listado de tokens creados</h4>
                        <TableEndpoint urlProduction="productionCustomercardidentifiersCreate" urlSandbox="customercardidentifiersCreate" />
                        <TablePost typePost={'GET'} />
                        <div className="table-docs">
                            <div className="title">
                                <p>Headers</p>
                            </div>
                            <div className="content">
                                <p>Authorization: <a href="/docs/authentication-types/auth" className="underline">Tipos de Autenticación <span></span></a></p>
                            </div>
                        </div>
                        <div className="table-docs">
                            <div className="title">
                                <p>URL Parameters</p>
                            </div>
                            <div className="content">
                                <p>{`{id}`}</p>
                                <strong>Nota</strong>
                                <ul>
                                    <li>emailCustomer: Correo electrónico del usuario final (Cadena)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="table-docs code">
                            <div className="title">
                                <p>Response</p>
                            </div>
                            <div className="code">
                                <Button color="red" onClick={() => copyCodeToken(JSON.stringify(dataResponse,null,2))}>
                                    {
                                        (resultToken) ?
                                            <>
                                                <span><BsFillClipboardCheckFill /></span>
                                            </>
                                            :
                                            <span><FaCopy /></span>
                                    }
                                </Button>
                                <CodeMirror
                                    value={JSON.stringify(dataResponse,null,2)}
                                    extensions={javascript({jsx: true})}
                                    readOnly={true}
                                    height="auto"
                                    theme={ThemeEditor}
                                />
                            </div>
                        </div>
                    </Col>
            }[nameRoute]}
        </>
    );
}
