import {createTheme} from "@uiw/codemirror-themes";
import {tags as t} from "@lezer/highlight";

export const ThemeEditor = createTheme({
    theme: 'light',
    settings: {
        background: '#eff2f5',
        foreground: '#d91931',
        caret: '#5d00ff',
        selection: '#fff',
        selectionMatch: '#fff',
        lineHighlight: 'rgba(0,0,0,.2)',
        gutterBackground: '#d91931',
        gutterForeground: '#fff',
    },
    styles: [
        { tag: t.comment, color: '#444444' },
        { tag: t.variableName, color: '#000' },
        { tag: [t.string, t.special(t.brace)], color: '#444444' },
        { tag: t.number, color: '#be182d' },
        { tag: t.bool, color: '#be182d' },
        { tag: t.null, color: '#be182d' },
        { tag: t.keyword, color: '#444444' },
        { tag: t.operator, color: '#b10a1f' },
        { tag: t.className, color: '#444444' },
        { tag: t.definition(t.typeName), color: '#444444' },
        { tag: t.typeName, color: '#444444' },
        { tag: t.angleBracket, color: '#444444' },
        { tag: t.tagName, color: '#444444' },
        { tag: t.attributeName, color: '#444444' },
    ],
});
