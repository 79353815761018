import React, { useState, useEffect } from "react";
import Logo from "../assets/imgs/RedPay.png";
import { Link } from "react-router-dom";
import {
  FlexboxGrid,
  Icon,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  SelectPicker,
  Toggle,
  Sidenav,
  Nav,
  Dropdown,
  Affix,
} from "rsuite";
import { Header } from "./header/header";
import { AsideMenu } from "./asideMenu/asideMenu";
import { BlockScreen } from "./blockScreen/blockScreen";
import { accountService } from "../services/account.service";
import menu from "../data/menu.json";
import logo from "../assets/imgs/RedPay.png";
import shield from "../assets/imgs/shield.png";
import secure from "../assets/imgs/3ds.png";
import { FaBars, FaFacebook, FaTimes } from "react-icons/fa";
import { AsideDocs } from "../pages/docs/asideDocs";
// import { PlecaInfo } from "./plecaInfo/plecaInfo";

export const LayoutFull = (props) => {
  const [activeBtn, setStateBtn] = useState("");
  const BtnSet = () => {
    setStateBtn(activeBtn === "" ? " visible_menu" : "");
  };

  const [activeMenu, setStateMenu] = useState("");
  const MenuPurchaseBox = () => {
    setStateMenu(activeMenu === "" ? "is-show" : "");
  };

  const MyFunMenu = () => {
    BtnSet();
    MenuPurchaseBox();
  };

  const [role, setRole] = useState("");
  useEffect(() => {
    accountService.role(true).then((result) => {
      setRole(result);
    });
  }, []);

  return (
    <section className="wrapper_main">
      <BlockScreen />
      <Header MyFunMenu={MyFunMenu} activeBtn={activeBtn} role={role} />
      <div className="section_main trans">
        <AsideMenu activeMenu={activeMenu} closeAll={MyFunMenu} role={role} />
        {props.children}
      </div>
    </section>
  );
};

export const LayoutLess = (props) => {
  const [userMenu, setUserMenu] = useState({ url: "", name: "" });

  useEffect(() => {
    if (
      props.children.props.location.pathname === "/business" ||
      props.children.props.location.pathname === "/users"
    )
      accountService.role(false).then((result) => {
        setUserMenu(
          menu.find((a) => a.section === "Header" && a.roles.includes(result))
        );
      });
  }, [props.children.props.location.pathname]);

  return (
    <>
      <BlockScreen />
      <header className="header_simple">
        <div className="am_container">
          <FlexboxGrid justify="space-between" align="middle">
            <FlexboxGrid.Item className="box_logo">
              <img
                src={Logo}
                alt="Red Pay MX, Pagos online, visa, mastercard, oxxo pay"
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <FlexboxGrid align="middle">
                {props.children.props.location.pathname === "/business" ||
                props.children.props.location.pathname === "/users" ? (
                  <FlexboxGrid.Item className="text-center box_actions">
                    <Link to="/business">
                      <Button appearance="subtle" className="logout mr-1">
                        <Icon icon="building-o" />
                        <span className="name_btns">Mis negocios</span>
                      </Button>
                    </Link>
                    {userMenu && (
                      <Link to={userMenu.url}>
                        <Button appearance="subtle" className="logout mr-1">
                          <Icon icon="user" />
                          <span className="name_btns">{userMenu.name}</span>
                        </Button>
                      </Link>
                    )}
                    <Button
                      appearance="subtle"
                      className="logout"
                      onClick={() => accountService.logout()}
                    >
                      <Icon icon="sign-out" />
                      <span className="name_btns">Cerrar sesión</span>
                    </Button>
                  </FlexboxGrid.Item>
                ) : (
                  <FlexboxGrid.Item>
                    <a
                      href="https://www.redpay.mx/marketplace"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-center"
                    >
                      <p className="fw-200">Conoce</p>
                      <h6>Red Pay Marketplace</h6>
                    </a>
                  </FlexboxGrid.Item>
                )}
              </FlexboxGrid>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
        {/* <PlecaInfo></PlecaInfo> */}
      </header>
      {props.children}
    </>
  );
};

export const LayoutDocs = (props) => {
  const versions = ["1.17"];
  const dataSelectVersion = versions.map((item) => ({
    label: item,
    value: item,
  }));
  const getValueVersion = (val) => console.log(val, "getValueVersion");
  const getValueLang = (val) => console.log(val, "getValueLang");

  const [expanded, setExpanded] = React.useState(false);

  const getWidthDimensions = () => {
    const { innerWidth: width } = window;
    return width;
  };

  const [windowDimensions, setWindowDimensions] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleOverlay = (width) => {
      if (width <= 992) setIsMobile(true);
      if (width >= 992) {
        setIsMobile(false);
        setExpanded(false);
      }
    };
    const handleResize = () => setWindowDimensions(getWidthDimensions());
    handleOverlay(getWidthDimensions());
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const showSidenav = () =>
    windowDimensions > 992 ? "" : setExpanded(!expanded);

  return (
    <>
      <BlockScreen />
      <nav
        className={`header_simple docs ${isMobile ? "mobile" : ""} ${
          expanded ? "visible" : ""
        }`}
      >
        <div className="am_container">
          <FlexboxGrid justify="space-between" align="middle">
            <FlexboxGrid.Item className="box_logo">
              <a href="https://redpay.mx/">
                <img
                  src={Logo}
                  alt="Red Pay MX, Pagos online, visa, mastercard, oxxo pay"
                />
              </a>
              {!isMobile ? (
                <a href="https://www.redpay.mx" className="link">
                  Home
                </a>
              ) : (
                ""
              )}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <FlexboxGrid align="middle">
                <FlexboxGrid.Item>
                  {!isMobile ? (
                    <Link to="/" className="rs-btn-red">
                      Iniciar Sesión
                    </Link>
                  ) : (
                    <Button
                      className={`btn-bars ${expanded ? "active" : ""}`}
                      onClick={showSidenav}
                    >
                      <div className="line1"></div>
                      <div className="line2"></div>
                      <div className="line3"></div>
                    </Button>
                  )}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
        {isMobile ? (
          <AsideDocs
            showSidenav={showSidenav}
            expanded={expanded}
            isMobile={isMobile}
          />
        ) : null}
      </nav>
      <section className="wrapper-docs">
        <header>
          <div className="am_container">
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item>
                <Form>
                  <FormGroup>
                    <ControlLabel className="sm">Versión</ControlLabel>
                    <SelectPicker
                      data={dataSelectVersion}
                      style={{ width: 224 }}
                      defaultValue="1.17"
                      onChange={(value) => {
                        getValueVersion(value);
                      }}
                      placeholder="Seleccionar"
                      searchable={false}
                    />
                  </FormGroup>
                </Form>
              </FlexboxGrid.Item>
              {/*
                                * <FlexboxGrid.Item>
                                <Form>
                                    <FormGroup>
                                        <ControlLabel className="sm text-right">Idioma</ControlLabel>
                                        <Toggle size="lg" defaultChecked checkedChildren="ES" unCheckedChildren="ENG" disabled={true}
                                                onChange={(value) => {
                                                    getValueLang(value);
                                                }}
                                        />
                                    </FormGroup>
                                </Form>
                            </FlexboxGrid.Item>
                                * */}
            </FlexboxGrid>
          </div>
        </header>
        <article className="docs-container wrapper-docs-content">
          <div className="docs-container-side">
            <div
              className={`wrapper-docs-sidenav-container ${
                expanded ? "visible" : ""
              }`}
            >
              <h3>Documentación</h3>
              {!isMobile ? (
                <AsideDocs expanded={expanded} isMobile={isMobile} />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="docs-container-content">
            <Row>{props.children}</Row>
            <footer className="wrapper-footer">
              <Row>
                <Col md={8} sm={12} xs={24}>
                  <div className="box_foot">
                    <div>
                      <a href="https://redpay.mx/">
                        <img className="lazyload" src={logo} alt="Red Pay MX" />
                      </a>
                    </div>
                    <div className="box_social">
                      <div className="social">
                        <div>
                          Síguenos en
                          <a
                            target="_blank"
                            href="https://www.facebook.com/Redpay.mx/"
                          >
                            <FaFacebook />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <div className="box_content_foot">
                    <p>
                      Texas 39, Col. Nápoles, Del. Benito Juárez, C.P. 03810,
                      CDMX.
                    </p>
                    <p>
                      <a
                        className="c_primary"
                        href="mailto:contacto@redpay.mx"
                        target="_blank"
                      >
                        contacto@redpay.mx
                      </a>
                    </p>
                  </div>
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <div className=" box_security">
                    <p className="mb-1">Paga seguro</p>
                    <img className="cursor" src={shield} alt="Security" />
                    <img src={secure} alt="Security" />
                  </div>
                </Col>
              </Row>
              <Row className="foot">
                <Col md={12} sm={24} xs={24}>
                  <p className="foot_small">
                    <span id="anio_actual"></span> Red Pay Mx. Todos los
                    derechos reservados
                  </p>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <div className="foot_small box_legal">
                    <a href="terms-conditions.html" target="_blank">
                      Términos y Condiciones
                    </a>
                    <a href="notice-privacy.html" target="_blank">
                      Aviso de privacidad
                    </a>
                    <a href="return-policies.html" target="_blank">
                      Política de devolución
                    </a>
                  </div>
                </Col>
              </Row>
            </footer>
          </div>
        </article>
      </section>
    </>
  );
};
