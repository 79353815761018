import React from "react";
export const TableHeaders = () => {

    return (
        <div className="table-docs">
            <div className="title">
                <p>Headers</p>
            </div>
            <div className="content">
                <p>Content-Type, application/json</p>
                <p>Authorization: <a href="/docs/authentication-types/auth" className="underline">Tipos de Autenticación <span></span></a></p>
            </div>
        </div>
    );
}
