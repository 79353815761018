import React, { useState, useEffect } from 'react'
import { TitleSection } from '../../components/titleSection'
import { Table } from '../../components/table'
import { CalendarRange } from '../../components/myCalendar'
import { NewLeagues } from './newLeagues'
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Icon,
    Button,
    Pagination
} from 'rsuite';
import { transactionService } from '../../services/transaction.service'
import { useSelector } from 'react-redux'
import { getColumns } from "./templateColumns"

import './paidLeagues.scss'

export const PaidLeagues = () => {
    const userSetting = useSelector(state => state.userSetting);
    const [data, setData] = useState([]);
    const currentDate = new Date();
    const [filters, setFilters] = useState({ initDate: currentDate, endDate: currentDate, referenceNumber: "" });
    const [tableData, setTableData] = useState({ rows: 0, pages: 0, activePage: 0 });

    useEffect(() => {
        getCaptureLines(currentDate.toLocaleDateString("ja-JP"), currentDate.toLocaleDateString("ja-JP"), "", 1);
    }, []);

    const getCaptureLines = (initDate, endDate, referenceNumber, pageNumber) => {
        transactionService.getCaptureLines(initDate, endDate, referenceNumber, pageNumber, 10)
            .then((result) => {
                setData(result);
                if (result.length > 0) setTableData({ rows: result[0].maxRows, pages: result[0].maxPages, activePage: pageNumber });
            });
    }

    const handleFilters = () => {
        getCaptureLines(filters.initDate ? filters.initDate.toLocaleDateString("ja-JP") : "", filters.endDate ? filters.endDate.toLocaleDateString("ja-JP") : "", filters.referenceNumber, 1);
    }

    const columns = getColumns(userSetting, handleFilters);

    const generateReport = () => {
        if (tableData.rows > 0) {
            transactionService.generateReport(filters.idStatus ? filters.idStatus : 0, filters.initDate ? filters.initDate.toLocaleDateString("ja-JP") : "", filters.endDate ? filters.endDate.toLocaleDateString("ja-JP") : "", filters.referenceNumber, tableData.rows);
        }
    }

    const handleSelectPage = (page) => {
        setTableData({ ...tableData, activePage: page });
        getCaptureLines(filters.initDate ? filters.initDate.toLocaleDateString("ja-JP") : "", filters.endDate ? filters.endDate.toLocaleDateString("ja-JP") : "", filters.referenceNumber, page);
    }

    return (
        <section className="wrapper_paid_leagues">
            <TitleSection title="Ligas de pago" />
            <div className="text-right mb-2">
                <NewLeagues />
            </div>
            <Form className="sec_box_filters" onSubmit={handleFilters} >
                <FlexboxGrid align="bottom">
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} md={8} sm={8} xs={24}>
                        <FormGroup>
                            <ControlLabel>Rango de fechas</ControlLabel>
                            <CalendarRange size="lg" onChange={(value) => setFilters({ ...filters, initDate: value[0], endDate: value[1] })} />
                        </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} md={12} sm={10} xs={24}>
                        <FormGroup>
                            <ControlLabel>Buscar por referencia</ControlLabel>
                            <InputGroup inside>
                                <FormControl
                                    size='lg'
                                    name="input-1"
                                    placeholder="Referencia"
                                    onChange={(value) => setFilters({ ...filters, referenceNumber: value })}
                                />
                                <InputGroup.Addon>
                                    <Icon icon="search" />
                                </InputGroup.Addon>
                            </InputGroup>
                        </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="mb-3" componentClass={Col} md={4} sm={6} xs={24}>
                        <Button className="btn secundary" block size="lg" appearance="primary" type="submit">Buscar</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
            <div className="wrp_list_leagues">
                <Table
                    myData={data}
                    myColumns={columns}
                />
                <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={tableData.pages} activePage={tableData.activePage} onSelect={handleSelectPage} />
            </div>
        </section>
    )
}
