import 'rsuite/dist/styles/rsuite-default.css'
import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom'

import {
    LayoutLess,
    LayoutFull, LayoutDocs
} from './components/layouts'

import * as Pages from './pages'


export function App() {
    return (
        <Router>
            <Switch>

                {/* <Route exact path='/pruebas' render={x => (<LayoutLess children={<Pages.Pruebas {...x} />} />)} /> */}

                <Route exact path='/login' render={x => (<LayoutLess children={<Pages.Login {...x} />} />)} />
                <Route exact path='/business' render={x => (<LayoutLess children={<Pages.Business {...x} />} />)} />
                <Route exact path='/users' render={x => (<LayoutLess children={<Pages.Users {...x} />} />)} />

                {/*DOCS*/}
                <Route exact path='/docs' render={x => (<LayoutDocs children={<Pages.Homepage {...x} />} />)} />
                <Route exact path='/docs/integration-credentials' render={x => (<LayoutDocs children={<Pages.IntegrationCredentials {...x} />} />)} />
                <Route exact path='/docs/payment-types/:name' render={x => (<LayoutDocs children={<Pages.PaymentTypes {...x} />} />)} />
                <Route exact path='/docs/antifraud-validations' render={x => (<LayoutDocs children={<Pages.AntifraudValidations {...x} />} />)} />
                <Route exact path='/docs/authentication-types/:name' render={x => (<LayoutDocs children={<Pages.AuthenticationTypes {...x} />} />)} />
                <Route exact path='/docs/catalogs/:name' render={x => (<LayoutDocs children={<Pages.Catalogs {...x} />} />)} />
                <Route exact path='/docs/tokenization/:name' render={x => (<LayoutDocs children={<Pages.Tokenization {...x} />} />)} />
                <Route exact path='/docs/integration-methods/:name'
                       render={x => (<LayoutDocs children={<Pages.IntegrationMethods {...x} />} />)}
                />
                <Route exact path='/docs/integration-tests/:name' render={x => (<LayoutDocs children={<Pages.IntegrationTests {...x} />} />)} />
                <Route exact path='/docs/production-validation-session' render={x => (<LayoutDocs children={<Pages.ProductionValidationSession {...x} />} />)} />
                <Route exact path='/docs/test-builder' render={x => (<LayoutDocs children={<Pages.TestBuilder {...x} />} />)} />

                <Route exact path='/home' render={x => (<LayoutFull children={<Pages.Home {...x} />} />)} />
                <Route exact path='/transactions' render={x => (<LayoutFull children={<Pages.Transactions {...x} />} />)} />
                <Route exact path='/transaction-detail' render={x => (<LayoutFull children={<Pages.TransactionDetail {...x} />} />)} />
                <Route exact path='/preauthorizations' render={x => (<LayoutFull children={<Pages.PreAuthorizations {...x} />} />)} />
                <Route exact path='/subscriptions' render={x => (<LayoutFull children={<Pages.Subscriptions {...x} />} />)} />
                <Route exact path='/subscriptions-details/:active/:id/' render={x => (<LayoutFull children={<Pages.SubscriptionsDetails {...x} />} />)} />
                <Route exact path='/paid-leagues' render={x => (<LayoutFull children={<Pages.PaidLeagues {...x} />} />)} />
                <Route exact path='/leagues-single' render={x => (<LayoutFull children={<Pages.LeaguesSingle {...x} />} />)} />
                <Route exact path='/leagues-bulk' render={x => (<LayoutFull children={<Pages.LeaguesBulk {...x} />} />)} />
                <Route exact path='/help-center' render={x => (<LayoutFull children={<Pages.HelpCenter {...x} />} />)} />
                <Route exact path='/integration' render={x => (<LayoutFull children={<Pages.Integration {...x} />} />)} />
                <Route exact path='/my-account' render={x => (<LayoutFull children={<Pages.MyAccount {...x} />} />)} />
                <Route exact path='/fraud-management' render={x => (<LayoutFull children={<Pages.FraudManagement {...x} />} />)} />
                <Route exact path='/chargebacks' render={x => (<LayoutFull children={<Pages.Chargebacks {...x} />} />)} />
                <Route exact path='/chargebacks-detail' render={x => (<LayoutFull children={<Pages.ChargebacksDetails {...x} />} />)} />

                <Redirect from='/' to='/login' />

            </Switch>
        </Router>
    );
}
