import React, {useEffect, useRef, useState} from "react";
import {
    Alert,
    Button,
    Col,
    ControlLabel, FlexboxGrid,
    Form, FormControl,
    FormGroup,
    Icon, InputGroup, Schema
} from "rsuite";
import {endpoints} from "./endpoints";

/*Importing styles*/
import "./docs.scss";
import {FaExclamation} from "react-icons/fa";

export const FormRequest = (
    {
        itemTab,
        currentRequest,
        bodyRequest,
        setResponse ,
        setCredentials,
        credentials,
        formValue,
        setFormValue,
        messageError,
        setMessageError,
        execHandleSubmit,
        setItemTab,
        editorChanged
}) => {

    const [endpointsLink, setEndpointsLink] = useState(endpoints);

    const [showPassword, setShowPassword] = useState(true);
    const changePassword = () => setShowPassword(!showPassword);

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        userID: StringType().isRequired('* Campo requerido'),
        password: StringType().isRequired('* Campo requerido')
    });

    const idUser = useRef();
    const passUser = useRef();

    const handleChange = (data) => setFormValue(data);

    const handleInputName = (value) => setFormValue({...formValue, UserId: value});

    const handleInputPass = (value) => setFormValue({...formValue, Password: value});

    const handleSubmit = () => {

        if(formValue.UserId === '' || formValue.Password === '') {
            setMessageError('Credenciales erróneas')
            setCredentials(true)
            return;
        }

        setCredentials(false)
        let url = '';

        for (const [key, value] of Object.entries(endpointsLink)) {
            if(key === currentRequest) url = value;
        }

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };

        fetch(endpointsLink.Authenticate, {
            method: 'POST',
            mode: 'cors',
            headers: headers,
            body: JSON.stringify(formValue),
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                headers.Authorization = `Bearer ${result.token}`;
                fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    headers: headers,
                    body: JSON.stringify(bodyRequest),
                    redirect: 'follow'
                })
                    .then(response => response.text())
                    .then(resultPay => {
                        Alert.success(`¡Solicitud exitosa!`)
                        setResponse(JSON.parse(resultPay));
                        setItemTab(true)
                    })
                    .catch(error => {
                        Alert.error(`Revisa los datos enviados en el JSON`)
                        setMessageError(`Revisa los datos enviados en el JSON`)
                        setCredentials(true)
                    });
            })
            .catch(error => {
                Alert.error(`${error}`)
                setMessageError(`${error}`)
                setCredentials(true)
            });
    }

    const saveCredentials = () => {
        if(formValue.UserId === '' || formValue.Password === '') {
            setMessageError('No hay datos para guardar')
            setCredentials(true)
            return;
        }

        setCredentials(false)

        const apiKeyUser = {
            UserId: formValue.UserId,
            Password: formValue.Password
        }

        localStorage.setItem("apiKeyUser", JSON.stringify(apiKeyUser));
        setButtonClear(true)
    }

    const clearCredentials = () => {
        localStorage.removeItem("apiKeyUser");
        setFormValue({UserId: '', Password: ''})
        setButtonClear(false)
    }

    const [buttonClear, setButtonClear] = useState(false);

    useEffect(() => {
        const apiKeyUserStorage = localStorage.getItem("apiKeyUser");
        if(JSON.parse(apiKeyUserStorage)) setButtonClear(true)
    }, [])

    useEffect(() => {
        if(execHandleSubmit) handleSubmit();
    }, [execHandleSubmit])

    return (
       <Col md={10} sm={24} xs={24}>
            <div className={`container-credentials ${(!itemTab) ? 'active' : ''}`}>
                <FlexboxGrid justify="start" align="middle" className="mt-3">
                    <FlexboxGrid.Item>
                        <small>Ingresa los datos de tus credenciales</small>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <Form
                    onChange={handleChange}
                    layout="vertical"
                    model={model}
                    formValue={formValue}
                >
                    <FormGroup>
                        <ControlLabel>API Key</ControlLabel>
                        <FormControl ref={idUser} name="UserId" onChange={handleInputName} type="text" size="sm" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Contraseña</ControlLabel>
                        <InputGroup>
                            <FormControl ref={passUser} onChange={handleInputPass} name="Password" size="sm" type={`${(showPassword) ? 'password' : 'text'}`} />
                            <InputGroup.Button onClick={changePassword}>
                                {(showPassword) ? <Icon icon="eye" /> : <Icon icon="eye-slash" />}
                            </InputGroup.Button>
                        </InputGroup>
                    </FormGroup>
                    {
                        (credentials && credentials) ?
                            <div className="alert-error">
                                <p>
                                    <span><FaExclamation /></span>
                                    {messageError}
                                </p>
                            </div> : null
                    }
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item style={{ marginLeft: '.5rem' }}>
                            {
                                (buttonClear) ?
                                    <Button type="button" onClick={clearCredentials}>
                                        Borrar <span style={{ paddingLeft: '.5rem' }}><Icon icon="trash" /></span>
                                    </Button>
                                :
                                    <Button type="button" onClick={saveCredentials}>
                                        Guardar <span style={{ paddingLeft: '.5rem' }}><Icon icon="save" /></span>
                                    </Button>
                            }

                        </FlexboxGrid.Item>
                        {
                            (!editorChanged) ?
                            <FlexboxGrid.Item style={{ marginLeft: '.5rem' }}>
                                <Button type="button" onClick={handleSubmit} color="red">Enviar <span style={{ paddingLeft: '.5rem' }}><Icon icon="arrow-circle-right" /></span></Button>
                            </FlexboxGrid.Item> : null
                        }
                    </FlexboxGrid>
                </Form>
            </div>
        </Col>
    );
}
