import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TitleSection } from "../../components/titleSection";
import {
    Row,
    Col,
    Button,
    Icon,
    Tag,
    Notification, ControlLabel, FormGroup, Form, ButtonToolbar, Divider,
} from "rsuite";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import statusChargeBack from "../../data/statusChargeBack.json";
import getSymbolFromCurrency from "currency-symbol-map";
import { chargebackService } from "../../services/chargeback.service";
import "./chargebacks.scss";
import { accountService } from "../../services/account.service";
import { StatusEnum } from "../../enum/statusEnum";
import { InputFile } from "../../components/inputFile/inputFile";

export const ChargebacksDetails = (props) => {
    const [detail, setDetail] = useState(props.location.state);
    const userSetting = useSelector((state) => state.userSetting);
    const [filesChargeback, setFilesChargeback] = useState([]);
    const [hasFiles, setHasFiles] = useState(false);

    const initialFilters = {
        idStatus: 0,
        initDate: "",
        endDate: "",
        referenceNumber: detail.referenceNumber,
        email: "",
        statusChargeBack: [],
        idTransaction: detail.idTransaction
    };
    const setInitialFilters = () => {
        let filtersTmp = initialFilters;
        if (sessionStorage["rp_filters"] !== undefined && props.location.toReturn) {
            filtersTmp = JSON.parse(sessionStorage["rp_filters"]);
            filtersTmp.initDate = new Date(filtersTmp.initDate);
            filtersTmp.endDate = new Date(filtersTmp.endDate);
            filtersTmp.statusChargeBack = [];
        }

        return filtersTmp;
    };

    const [filters, setFilters] = useState(setInitialFilters());

    const getSameTransaction = () => {
        chargebackService
            .getByTransaction(filters.idTransaction)
            .then((itemChargeBack) => {
                setDetail(itemChargeBack);
                setFilesChargeback(itemChargeBack.chargeBackFiles);
                setHasFiles(itemChargeBack.chargeBackFiles.length>0);
            });
    }

    useEffect(() => {
        getSameTransaction();
        sessionStorage.setItem("rp_filters_ChargeBack", props.location.state.rp_filters_ChargeBack);
        sessionStorage.setItem("rp_ActivePage_ChargeBack", props.location.state.rp_ActivePage_ChargeBack);
    }, [props]);

    const handleUploadFiles = (files) => {
        setFilesChargeback([...filesChargeback, files[0]])
    }

    const handleRemoveFiles = (files) => setFilesChargeback(files)

    const handleSave = () => {
        if (filesChargeback.length === 0) {
            return Notification.error({
                title: "Error",
                duration: 5000,
                description: (<p>Necesitas cargar mínimo 1 archivo</p>)
            });
        }
        var data = new FormData();

        filesChargeback.forEach(file => data.append('files', file, file.name));
        data.append("idTransaction", filters.idTransaction);

        chargebackService.saveProof(data)
            .then(result => {
                if (result.data) {
                    Notification.success({
                        title: "Exitoso",
                        duration: 5000,
                        description: (<p>Archivos guardados correctamente</p>)
                    });
                    getSameTransaction();
                }
                else {
                    Notification.error({
                        title: "Error",
                        duration: 5000,
                        description: (<p>Error al guardar los archivos</p>)
                    });
                }
            });
    }

    return (
        <section className="transactions_details">
            <div className="mb-4">
                <Link to={{ pathname: "chargebacks", toReturn: true }}>
                    <Button className="btn c_primary p0" appearance="link" type="button">
                        <Icon icon="chevron-left" />
                        <span className="fw-400"> Regresar</span>
                    </Button>
                </Link>
            </div>
            <TitleSection title="Detalle de contracargo" />

            <Row className="mt-3">
                <Col sm={24} md={8}>
                    <div className="cards firstdata">
                        <p className="paragraph mb-4">
                            {detail && (
                                <span className="fw-30d0">
                                    <Tag color={statusChargeBack[detail.statusId].color}>
                                        <div className="labels">Estatus</div>
                                        <div>{statusChargeBack[detail.statusId].text}</div>
                                    </Tag>
                                </span>
                            )}
                        </p>
                        <p className="paragraph mb-3">
                            <div className="fw-700">Referencia: </div>
                            <div className="fw-300">{detail && detail.referenceNumber}</div>
                        </p>
                        <p className="paragraph mb-3">
                            <div className="fw-700 mb-2">Monto de transacción: </div>
                            <h2 className="fw-300">
                                {detail &&
                                    getSymbolFromCurrency(detail.currency) +
                                    detail.amount.toLocaleString(userSetting.cultureCode, {
                                        minimumFractionDigits: 2,
                                    })}
                            </h2>
                        </p>
                        <Form>
                            <FormGroup>
                                <>
                                    <ControlLabel className="fw-700">Archivos:</ControlLabel>
                                    <InputFile
                                        onFilesSelected={handleUploadFiles}
                                        nameFile={'filesChargeback'}
                                        valueFile={filesChargeback}
                                        deleteFile={handleRemoveFiles}
                                        accept={['pdf']}
                                        oneFile={hasFiles}
                                        maxFiles={5}
                                        edit={true}
                                        showUrlPreview={hasFiles}
                                    />
                                </>
                            </FormGroup>
                            <FormGroup>
                                {!hasFiles &&
                                    <ButtonToolbar> 
                                        <Button onClick={handleSave} block={true} className="rs-btn btn primary c_white">Guardar {filesChargeback.length > 1 ? 'archivos' : 'archivo'}</Button>
                                    </ButtonToolbar>
                                }
                            </FormGroup>
                        </Form>
                    </div>
                </Col>
                <Col sm={24} md={16}>
                    <div className="cards ">
                        <Row>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Id: </span>
                                    <span className="fw-300">
                                        {detail && detail.idTransaction}
                                    </span>
                                </p>
                                {detail && detail.paymentMethod && (
                                    <p className="paragraph mb-3">
                                        <span className="fw-700">Método: </span>
                                        <span className="fw-300">{detail.paymentMethod}</span>
                                    </p>
                                )}

                                {detail && detail.account && (
                                    <p className="paragraph mb-3">
                                        <span className="fw-700">Cuenta: </span>
                                        <span className="fw-300">{detail.account}</span>
                                    </p>
                                )}

                                {detail && detail.authCode && (
                                    <p className="paragraph mb-3">
                                        <span className="fw-700">Código de autorización: </span>
                                        <span className="fw-300">{detail.authCode}</span>
                                    </p>
                                )}
                            </Col>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Fecha de creación:</span>
                                    <span className="fw-300">
                                        <Moment locale="es" format="DD/MM/YYYY">
                                            {detail && detail.date}
                                        </Moment>
                                    </span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Correo electrónico: </span>
                                    <span className="fw-300">
                                        {detail && detail.custormerEmail}
                                    </span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">{detail && detail.secure3D && `Transacción por ${detail.secure3D}`}</span>
                                </p>
                            </Col>
                            <Col sm={24}><Divider>Información de contracargos</Divider></Col>
                            <Col sm={24}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Fecha de pago:</span>
                                    <span className="fw-300">
                                        <Moment locale="es" format="DD/MM/YYYY">
                                            {detail && detail.date}
                                        </Moment>
                                    </span>
                                </p>
                            </Col>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Estatus de contracargo : </span>
                                    <span className="fw-300">
                                        <Tag color={statusChargeBack[detail.statusId].color}>
                                            {statusChargeBack[detail.statusId].text}
                                        </Tag>
                                    </span>
                                </p>
                            </Col>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Fecha de contracargo : </span>
                                    <span className="fw-300">
                                        <Moment locale="es" format="DD/MM/YYYY">
                                            {detail && detail.date}
                                        </Moment>
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </section>
    );
};
