import React, { useState, useEffect } from 'react'
import { Modal, Notification, Tooltip, Whisper } from 'rsuite'
import { TitleSection } from '../../components/titleSection'
import { SubscripCancel } from './subscripCancel'
import { SubscripActive } from './subscripActive'
import { SubscripPause } from './subscripPause'
import { subscriptionService } from '../../services/subscription.service'
import { ModalConfirm, ModalMessage } from '../../components/modals/modalConfirm'
import {
    Button,
    ButtonGroup
} from 'rsuite';

export const Subscriptions = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [subscriptionSettings, setSubscriptionSettings] = useState();
    const [showButton, setShowButton] = useState(false);


    useEffect(() => {
        getPropertyCompany();
    }, []);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const reprocess = () => {
        ModalConfirm.show({
            size: "xs",
            title: "Reprocesar suscripciones",
            text: "¿Estas seguro que deseas iniciar el proceso de cobro de suscripciones?",
            onOk: () => {
                subscriptionService.reporcessSubscriptions()
                    .then(result => {
                        if (result.data) {
                            ModalMessage.show({
                                size: "xs",
                                title: "Reprocesar suscripciones",
                                text: "Se inicio el reproceso de suscripciones, este proceso puede tardar varios minutos dependiendo del número de tus suscripciones activas por cobrar."
                            });

                            getPropertyCompany();
                        }
                        else
                            ModalMessage.show({
                                size: "xs",
                                title: "Reprocesar suscripciones",
                                text: result.message
                            });

                    });
            }
        });
    }

    const getPropertyCompany = () => {
        subscriptionService.getSettings()
            .then(result => {
                let data = JSON.parse(result);
                setSubscriptionSettings(data);
            })
    }
    const ButtonReprocess = () => {
        if (subscriptionSettings && subscriptionSettings.settings.IsReprocessSubscriptions && (!subscriptionSettings.hasAttempts || subscriptionSettings.pendingRecords > 0)) {
            return (
                <>
                    <br />
                    <ButtonGroup>
                        <Whisper
                            trigger="hover"
                            speaker={<Tooltip>
                                {!subscriptionSettings.hasAttempts ? "Acción no disponible. La función de reprocesar suscripciones está limitada a 1 vez al día." :
                                    "Acción no disponible. El proceso de cobro diario de suscripciones aun no termina."}
                            </Tooltip>}>
                            <Button
                                size="lg"
                                className={"rs-btn btn primary c_white"}
                                disabled
                            >
                                Reprocesar suscripciones
                            </Button>
                        </Whisper>
                    </ButtonGroup>
                </>
            );
        } else if (subscriptionSettings && subscriptionSettings.settings.IsReprocessSubscriptions && subscriptionSettings.hasAttempts && subscriptionSettings.pendingRecords == 0) {
            return (
                <>
                    <br />
                    <ButtonGroup>
                        <Button
                            size="lg"
                            onClick={reprocess}
                            className={"rs-btn btn primary c_white"}
                        >
                            Reprocesar suscripciones
                        </Button>
                    </ButtonGroup>
                </>
            );
        }

        // Devuelve null si ninguna de las condiciones anteriores se cumple
        return null;
    };


    return (
        <section className="wrapper_subscriptions">
            <TitleSection title="Suscripciones" />

            <ButtonGroup className="nav_tabs mt-4" justified>
                <Button
                    size="lg"
                    onClick={() => { toggle(1); }}
                    className={"btn tabs_item " + (activeTab === 1 ? "active" : "")}
                >
                    ACTIVAS
                </Button>
                <Button
                    size="lg"
                    onClick={() => { toggle(2); }}
                    className={"btn tabs_item " + (activeTab === 2 ? "active" : "")}
                >
                    PAUSADAS
                </Button>
                <Button
                    size="lg"
                    onClick={() => { toggle(3); }}
                    className={"btn tabs_item " + (activeTab === 3 ? "active" : "")}
                >
                    CANCELADAS
                </Button>
            </ButtonGroup>
            <ButtonReprocess />
            <div className="wrapper_tabs">
                <div
                    className={"content_tabs " + (activeTab === 1 ? "is-show" : "")}
                >
                    <SubscripActive active={activeTab === 1} />
                </div>
                <div
                    className={"content_tabs " + (activeTab === 2 ? "is-show" : "")}
                >
                    <SubscripPause active={activeTab === 2} />
                </div>
                <div
                    className={"content_tabs " + (activeTab === 3 ? "is-show" : "")}
                >
                    <SubscripCancel active={activeTab === 3} />
                </div>
            </div>
        </section>
    )
}