import { callApi } from './service'

export const fraudManagementService = {
    getTimeRangeCatalog,
    getByCompany,
    updateRules
};

async function getTimeRangeCatalog() {
    return callApi('/velocityrules/timerangecatalog', 'GET')
        .then((result) => {
            if (result.success)
                return result.data;
            else
                console.log(result.message);
            return [];
        });
}

async function getByCompany() {
    return callApi(`/velocityrules/bycompany`, 'GET')
        .then((result) => {
            if (result.success)
                return result.data;
            else
                console.log(result.message);
            return [];
        });
}


async function updateRules(rules) {
    return callApi(`/velocityrules/updateRules`, 'POST', rules)
        .then((result) => {
            if (result.success)
                return result.data;
            else
                console.log(result.message);
            return {};
        });
}