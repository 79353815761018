import React, { useState, useEffect } from "react";
import { SelectPicker, ControlLabel } from "rsuite";
import { Row, Col, FlexboxGrid, Progress } from "rsuite";
import { TitleSection } from "../../../components/titleSection";
import { transactionService } from "../../../services/transaction.service";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";

import "./statisticsHome.scss";

const { Circle } = Progress;

export const StatisticsHome = () => {
    const userSetting = useSelector((state) => state.userSetting);
    const [periodLabel, setPeriodLabel] = useState("Hoy");
    const period = [
        {
            label: "Hoy",
            value: "0-D",
        },
        {
            label: "Ayer",
            value: "1-D",
        },
        {
            label: "Ultima semana",
            value: "6-D",
        },
        {
            label: "Último mes",
            value: "1-M",
        },
        {
            label: "Últimos 3 meses",
            value: "3-M",
        },
    ];

    const [summary, setSummary] = useState({
        balanceProcess: 0,
        completedTransactions: 0,
        currentBalance: 0,
        percentageCancellation: 0,
        percentageProcess: 0,
        percentageSuccess: 0,
        transactionsProcess: 0,
        currentBalanceCommission: 0,
    });

    useEffect(() => {
        getSummary("0-D");
    }, []);

    const getSummary = (period) => {
        transactionService.summary(period).then((result) => setSummary(result));
    };

    const handlerPeriod = (value, event) => {
        setPeriodLabel(event.target.innerHTML);
        getSummary(value);
    };

    return (
        <div className="content_statistics_home">
            <div className="box_periodo">
                <div>
                    <TitleSection title="Resumen" />
                    <p className="paragraph">
                        <small>
                            Período seleccionado : <strong>{periodLabel}</strong>
                        </small>
                    </p>
                </div>
                <div>
                    {/* <ControlLabel>Período</ControlLabel> */}
                    <SelectPicker
                        size="sm"
                        placeholder="Selec. un período"
                        data={period}
                        defaultValue={"0-D"}
                        searchable={false}
                        style={{ width: "175px" }}
                        onChange={handlerPeriod}
                    />
                </div>
            </div>

            <Row className="mt-2">
                <Col md={13}>
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            sm={12}
                            md={24}
                            lg={12}
                        >
                            <div className="cards box_statistics icons">
                                <div>
                                    <p className="label_ttl">Saldo</p>
                                    <p className="label_number">
                                        {getSymbolFromCurrency(userSetting.isoCurrency)}
                                        {summary.currentBalance.toLocaleString(
                                            userSetting.cultureCode,
                                            { minimumFractionDigits: 2 }
                                        )}
                                        <span className="label_min">
                                            {userSetting.isoCurrency}
                                        </span>
                                    </p>
                                    <p className="label_ttl">Transacciones completas</p>
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            sm={12}
                            md={24}
                            lg={12}
                        >
                            <div className="cards box_statistics">
                                <div>
                                    <p className="label_ttl">Transacciones pendientes</p>
                                    <p className="label_number">
                                        {getSymbolFromCurrency(userSetting.isoCurrency)}
                                        {summary.balanceProcess.toLocaleString(
                                            userSetting.cultureCode,
                                            { minimumFractionDigits: 2 }
                                        )}{" "}
                                        <span className="label_min">{userSetting.isoCurrency}</span>{" "}
                                    </p>
                                    <p className="label_ttl">
                                        {summary.transactionsProcess} Trans. en proceso
                                    </p>
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            sm={12}
                            md={24}
                            lg={12}
                        >
                            <div className="cards box_statistics">
                                <div>
                                    <p className="label_ttl">Transacciones</p>
                                    <p className="label_number">
                                        {summary.completedTransactions}
                                    </p>
                                    <p className="label_ttl">Completas</p>
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            sm={12}
                            md={24}
                            lg={12}
                        >
                            <div className="cards box_statistics">
                                <div>
                                    <p className="label_ttl">Saldo Neto</p>
                                    <p className="label_number">
                                        {getSymbolFromCurrency(userSetting.isoCurrency)}
                                        {summary.currentBalanceCommission.toLocaleString(
                                            userSetting.cultureCode,
                                            { minimumFractionDigits: 2 }
                                        )}
                                        <span className="label_min">
                                            {" "}
                                            {userSetting.isoCurrency}
                                        </span>{" "}
                                    </p>
                                    <p className="label_ttl">Menos comisiones (IVA incluido)</p>
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Col>
                <Col md={11}>
                    <FlexboxGrid className="cards box_progress_circle">
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            sm={8}
                            md={24}
                            lg={12}
                        >
                            <div className="item_progress_circle">
                                <h5 className="text-center mb-2">Transacciones exitosas</h5>
                                <Circle
                                    percent={summary.percentageSuccess}
                                    strokeColor="#d41f37"
                                />
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            sm={8}
                            md={24}
                            lg={12}
                        >
                            <div className="item_progress_circle">
                                <h5 className="text-center mb-2">Tasa de cancelación</h5>
                                <Circle
                                    percent={summary.percentageCancellation}
                                    strokeColor="#d41f37"
                                />
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            sm={8}
                            md={24}
                            lg={12}
                        >
                            <div className="item_progress_circle">
                                <h5 className="text-center mb-2">En proceso de pago</h5>
                                <Circle
                                    percent={summary.percentageProcess}
                                    strokeColor="#d41f37"
                                />
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Col>
            </Row>
        </div>
    );
};
