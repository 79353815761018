import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/es";
import getSymbolFromCurrency from "currency-symbol-map";
import { Tag, IconButton, Icon, Whisper, Tooltip } from "rsuite";
import { handleSimulate } from "./transactionHook";
import status from "../data/status.json";
import statusChargeBack from "../data/statusChargeBack.json";

export const getColumns = (userSetting, reloadTable, origin) => {
    const CreateColorByStatus = (idStatus, idChargeBack, row) => {
        let statusSelected = localStorage.getItem("statusIdTransaction");
        if (statusSelected !== '17') {
            if ([2, 17, 18].includes(idStatus) && idChargeBack !== "")
                return ColorChargeBack(idStatus);
            else
                return (<Tag color={status[idStatus].color}>
                    {status[idStatus].text}
                </Tag>);
        }
        else {
            return (<Tag color={statusChargeBack[idStatus].color}>
                {statusChargeBack[idStatus].text}
            </Tag>);
        }
    }

    const ColorChargeBack = (idStatus) => {
        switch (idStatus) {
            case 2:
                return ((<Tag color={status[idStatus].color}>
                    {"Contracargo ganadado"}
                </Tag>));
            case 17:
                return ((<Tag color={"cyan"}>
                    {"Contracargo en proceso"}
                </Tag>));
            case 18:
                return ((<Tag color={status[idStatus].color}>
                    {"Contracargo perdido"}
                </Tag>));
            default:
                return "";
        }
    }

    return [
        {
            name: "Id",
            width: "120px",
            selector: "idTransaction",
            cell: (row) => (
                <Link
                    className="btn_link fw-700"
                    to={{
                        pathname: "transaction-detail",
                        state: {
                            ...row, origin,
                            filters: { transaction: sessionStorage.getItem('rp_filters'), preauth: sessionStorage.getItem('rp_filters_PreAuth') },
                            pages: { transaction: sessionStorage.getItem('rp_ActivePage'), preauth: sessionStorage.getItem('rp_ActivePage_PreAuth') }
                        }
                    }}
                >
                    {row.idTransaction}
                </Link>
            ),
        },
        {
            name: "Referencia",
            selector: "referenceNumber",
            wrap: true,
            minWidth: "225px",
            cell: (row) => (
                <Link
                    className="btn_link fw-700"
                    to={{
                        pathname: "transaction-detail",
                        state: {
                            ...row, origin,
                            filters: { transaction: sessionStorage.getItem('rp_filters'), preauth: sessionStorage.getItem('rp_filters_PreAuth') },
                            pages: { transaction: sessionStorage.getItem('rp_ActivePage'), preauth: sessionStorage.getItem('rp_ActivePage_PreAuth') }
                        }
                    }}
                >
                    {row.referenceNumber}
                </Link>
            ),
        },
        {
            name: "Fecha",
            selector: "date",
            format: (row) => (
                <Moment locale="es" format="DD/MM/YYYY hh:mm:ss A">
                    {row.date}
                </Moment>
            ),
            wrap: true,
            width: "115px",
        },
        {
            name: "Monto",
            width: "100px",
            selector: "amount",
            format: (row) =>
                getSymbolFromCurrency(row.currency) +
                row.amount.toLocaleString(userSetting.cultureCode, {
                    minimumFractionDigits: 2,
                }),
        },
        // {
        //     name: "Cuenta",
        //     selector: "account",
        //     wrap: true,
        //     width: "175px",
        // },
        // {
        //   name: "Método",
        //   selector: "paymentMethod",
        //   width: "100px",
        // },
        {
            name: "Correo electrónico",
            selector: "custormerEmail",
            wrap: true,
            width: "250px",
        },
        {
            name: "Cliente",
            selector: "custormerName",
            wrap: true,
            width: "210px",
        },
        { name: "Secure", selector: "secure3D", wrap: true, width: "100px" },
        {
            name: "Estatus",
            selector: "status",
            wrap: true,
            width: "125px",
            cell: (row) => (
                <>
                    <span>
                        {CreateColorByStatus(row.statusId, row.statusChargeBack, row)}
                    </span>
                    <>&nbsp;</>
                    {row.statusId === 5 && userSetting.environment === "MODO PRUEBAS" && (
                        <span
                            className="box_ico_table"
                            onClick={() => handleSimulate(row.idTransaction, reloadTable)}
                        >
                            <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={<Tooltip>Simular Pago</Tooltip>}
                            >
                                <IconButton
                                    className="mr-2"
                                    icon={<Icon icon="shopping-cart" />}
                                    circle
                                    size="md"
                                />
                            </Whisper>
                        </span>
                    )}
                </>
            ),
        },
    ];
};
