import React, { useState, useEffect } from "react";
import { TitleSection } from "../../components/titleSection";
import { Table } from "../../components/table";
import {
  FlexboxGrid,
  Col,
  FormGroup,
  Button,
  Toggle,
  SelectPicker,
  InputGroup,
  InputNumber,
} from "rsuite";
import { fraudManagementService } from "../../services/fraudManagement.service";

import "./fraudManagement.scss";

export const BoxVelocityRules = () => {
  const [timeRanges, setTimeRanges] = useState([]);
  const [rules, setRules] = useState([]);
  const [initialRules, setInitialRules] = useState([]);
  const [activeModification, setActiveModification] = useState(false);

  useEffect(() => {
    // to do async
    fraudManagementService.getTimeRangeCatalog().then((result) => {
      setTimeRanges(result);
    });
    getRules();
  }, []);

  const getRules = () => {
    fraudManagementService.getByCompany().then((result) => {
      setRules(result);
    });
  };

  const columns = [
    {
      name: "Estatus",
      selector: "active",
      sortable: false,
      width: "150px",
      cell: (row) => (
        <>
          {activeModification ? (
            <Toggle
              checked={row.active}
              onChange={(value) =>
                handleChange(value, "active", row.fieldRuleTypesId)
              }
            />
          ) : (
            <span>{row.active ? "Habilitada" : "Deshabilitada"}</span>
          )}
        </>
      ),
    },
    {
      name: "Campo",
      selector: "fieldRulesName",
      sortable: false,
      width: "150px",
    },
    {
      name: "Valor",
      selector: "value",
      sortable: false,
      cell: (row) => (
        <>
          {activeModification ? (
            <InputGroup>
              <InputGroup.Addon>supera</InputGroup.Addon>
              <InputNumber
                size="xs"
                value={row.value}
                onChange={(value) =>
                  handleChange(value, "value", row.fieldRuleTypesId)
                }
              />
              <InputGroup.Addon> transacciones por</InputGroup.Addon>
            </InputGroup>
          ) : (
            <span>supera {row.value} transacciones por</span>
          )}
        </>
      ),
    },
    {
      name: "Rango",
      selector: "timeRangeId",
      sortable: false,
      cell: (row) => (
        <>
          {activeModification ? (
            <SelectPicker
              placeholder="Selecciona un rango"
              data={timeRanges}
              size="xs"
              searchable={false}
              className="mt-2 mb-3"
              value={row.timeRangeId}
              onChange={(value) =>
                handleChange(value, "timeRangeId", row.fieldRuleTypesId)
              }
            />
          ) : (
            <span>{row.timeRangeName}</span>
          )}
        </>
      ),
    },
  ];

  const onSubmit = () => {
    fraudManagementService.updateRules(rules).then(() => getRules());
  };

  const handleButton = ({ target }) => {
    if (target.innerText === "Guardar") onSubmit();
    else setInitialRules(JSON.parse(JSON.stringify(rules)));

    setActiveModification((status) => !status);
  };

  const handleButtonCancel = () => {
    setRules(initialRules);
    setInitialRules([]);
    setActiveModification((status) => !status);
  };

  const handleChange = (value, property, fieldId) => {
    if (valueIsValid(property, value)) {
      let update = rules.map((a) => {
        if (a.fieldRuleTypesId === fieldId) a[property] = value;

        return a;
      });
      setRules(update);
    }
  };

  const valueIsValid = (property, value) => {
    let isValid = true;
    if (property === "timeRangeId") {
      if (value === null) {
        isValid = false;
      }
    }

    if (property === "value") {
      isValid = value > 0 && value < 30;
    }

    return isValid;
  };

  return (
    <section className="wrapper_box_velocity">
      <TitleSection title="Reglas de velocidad" />
      <FlexboxGrid align="bottom">
        <FlexboxGrid.Item
          className="mb-4"
          componentClass={Col}
          colspan={24}
          sm={24}
          md={24}
        >
          <FormGroup className="mb-0">
            <Table myData={rules} myColumns={columns}></Table>
          </FormGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          className="mb-4"
          componentClass={Col}
          colspan={24}
          sm={24}
          md={24}
        >
          <FormGroup className="text-right">
            {activeModification && (
              <Button
                appearance="default"
                type="button"
                onClick={handleButtonCancel}
              >
                Cancelar
              </Button>
            )}

            <Button
              className={`btn ${activeModification ? " primary" : "secundary"}`}
              appearance="primary"
              type="button"
              onClick={handleButton}
            >
              {activeModification ? "Guardar" : "Editar"}
            </Button>
          </FormGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </section>
  );
};
