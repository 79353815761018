import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { TitleSection } from '../../components/titleSection'
import { NewLeagues } from './newLeagues'
import {
    Row,
    Col,
    Form,
    Icon,
    Button,
    Uploader,
    Notification,
    Message
} from 'rsuite';
import './paidLeagues.scss'
import { transactionService } from '../../services/transaction.service'

export const LeaguesBulk = (props) => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState("");

    const handleSubmit = () => {
        if (files.length > 0) {
            transactionService.createCaptureLineBatch(files)
                .then((result) => {
                    if (result.success) {
                        Notification.success({ title: result.message, duration: 5000 });
                        Notification.success({ title: result.data, duration: 5000 });
                        setError("");
                        props.history.push("/paid-leagues");
                    } else {
                        console.log(result.data);
                        Notification.error({ title: result.message, duration: 5000 });
                        setError(result.data);
                    }
                });
        } else Notification.warning({ title: 'Debes agregar al menos un archivo', duration: 5000 });
    }

    return (
        <section className="wrapper_paid_leagues">
            <div className="mb-4">
                <Link to="/paid-leagues">
                    <Button className="btn c_primary p0" appearance="link" type="submit">
                        <Icon icon="chevron-left" />
                        <span className="fw-400"> Regresar</span>
                    </Button>
                </Link>
            </div>
            <Row>
                <Col sm={24} md={16}>
                    <TitleSection title="Ligas de pago" />
                    <h3 className="fw-300 mt-3">Generar liga de pago masivo</h3>
                </Col>
                <Col sm={24} md={8}>
                    <div className="text-right mt-3">
                        <NewLeagues />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm={24} md={16}>
                    <div className="cards_form cards">
                        <div className="text-right mb-2">
                            <a href="/Templates/CaptureLineLayout.csv" className="btn c_primary p0" appearance="link" >
                                <Icon icon="file-excel-o" />
                                <span className="fw-400"> Descargar template</span>
                            </a>
                        </div>
                        <Form fluid onSubmit={handleSubmit}>
                            <Uploader multiple draggable action="/api/transaction/upload" accept=".csv" onChange={value => setFiles(value)} onRemove={() => setError("")} >
                                <div >Haga clic o arrastre los archivos CSV a esta área para cargarlos</div>
                            </Uploader>
                            {error != "" ? <Message type="error" description={error} /> : null}
                            <div className="text-right mt-5">
                                <Button
                                    className="btn primary"
                                    appearance="primary"
                                    type="submit">
                                    Generar liga de pago
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>

        </section>
    )
}