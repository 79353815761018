import { callApi, callApiFile } from './service'

export const chargebackService = {
    getByTransaction,
    getDate,
    saveProof,
    get,
    generateReport
};

async function getDate() {
    return callApi('/chargeback/LastAndFirstDate', 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return {};
            }
        });
}

async function saveProof(data)
{
    return callApiFile('/chargeback/save', 'POST', data)
        .then((result) => result);
}

async function getByTransaction(idTransaction){
    return callApi(`/chargeback/Transaction/${idTransaction}`, 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return {};
            }
        });
}

async function get ({
    idStatus,
    initDate,
    endDate,
    referenceNumber,
    idTransaction,
    email,
    statusChargeBack,
    pageNumber,
    rowsOfPage }) {
    idStatus = idStatus ? idStatus : 0;
    initDate = initDate ? initDate.toLocaleDateString("ja-JP") : "";
    endDate = endDate ? endDate.toLocaleDateString("ja-JP") : "";
    email = email ? email : "";
    idTransaction = idTransaction ? idTransaction : 0;
    statusChargeBack = statusChargeBack ? statusChargeBack.map(String) : [""];
    return callApi(
        `/chargeback?idCompany=${sessionStorage["rp_company"]}&idStatus=${idStatus}&initDate=${initDate}&endDate=${endDate}&idTransaction=${idTransaction}&referenceNumber=${referenceNumber}&email=${email}&statusChargeBack=${statusChargeBack}&pageNumber=${pageNumber}&rowsOfPage=${rowsOfPage}`,
        "GET"
    ).then((result) => {
        if (result.success) {
            return result.data;
        } else {
            // console.log(result.message);
            return [];
        }
    });
}

// generate report
async function generateReport ({
    idStatus,
    initDate,
    endDate,
    referenceNumber,
    email,
    statusChargeBack,
    rows }
){
    idStatus = idStatus ? idStatus : 0;
    initDate = initDate && initDate != "Invalid Date" ? initDate.toLocaleDateString("ja-JP") : "";
    endDate = endDate && endDate != "Invalid Date" ? endDate.toLocaleDateString("ja-JP") : "";
    statusChargeBack = statusChargeBack.map(String);
    return callApi(
        `/chargeback/generatereport?idCompany=${sessionStorage["rp_company"]}&idStatus=${idStatus}&initDate=${initDate}&endDate=${endDate}&referenceNumber=${referenceNumber}&email=${email}&statusChargeBack=${statusChargeBack}&rows=${rows}`,
        "GET"
    )
        .then((blob) => {
            if (blob.size !== 0) {
                var FileSaver = require("file-saver");
                FileSaver.saveAs(
                    blob,
                    `Red_Pay_${sessionStorage["rp_company"]
                    }_${new Date().toLocaleDateString("ja-JP")}.csv`
                );
            }
        })
        .catch((error) => true);
}