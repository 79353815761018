import React from "react";
import {
    Button,
    Col,
    Panel,
    Row
} from "rsuite";

/*Importing styles*/
import "./docs.scss";
import {
    FaCreditCard,
    FaDownload,
    FaExchangeAlt, FaList,
    FaMoneyBill,
    FaSearch,
    FaUsers
} from "react-icons/fa";
import node from '../../assets/imgs/nodejs.png';
import woocommerce from '../../assets/imgs/woocommerce.png';

export const Homepage = () => {

    const CardGuide = props => (
        <Panel bordered className="wrapper-docs-card-guide">
            <div className="icon">{props.icon}</div>
            <div className="info">
                <h5>{props.title}</h5>
                <p>{props.text}</p>
            </div>
        </Panel>
    );

    const CardFeatured = props => (
        <Panel bordered className={`wrapper-docs-card-featured ${props.img}`}>
            <div className="info">
                <p>{props.text}</p>
                <h5>{props.title}</h5>
            </div>
            <div className="overlay">
                <a href={props.link}>Ir a {props.title} <span></span></a>
            </div>
        </Panel>
    );

    const goToDownload = () => window.open('https://wordpress.com/es/plugins/redpay-payment-gateway','_blank')

    const CardLibrary = props => (
        <Panel bordered className={`wrapper-docs-card-library ${props.nameImg}`}>
            <img src={props.img} alt="" />
            <div className="info">
                <p>{props.text}</p>
            </div>
            {
                (props.btn) ?
                    <div className="btns"><Button color="red" onClick={goToDownload}>Descargar <FaDownload /></Button></div>
                : ''
            }
        </Panel>
    );

    return (
        <div className="home">
            <Col xs={24}>
                <h4>Homepage</h4>
                <p>
                    Actividades y requerimientos técnicos que permiten realizar la integración del e-commerce del cliente a Red
                    Pay, en cualquiera de sus modalidades (Api y WebCheckout).
                </p>
            </Col>
            <Col xs={24}>
                <h4>Guías</h4>
                <Row gutter={8}>
                    <Col lg={12} sm={12} xs={24}>
                        <CardGuide
                            icon={<FaCreditCard />}
                            title="Pago con tarjeta"
                            text="Aprende cómo realizar cargos a tarjetas de débito y crédito de manera segura"
                        />
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                        <CardGuide
                            icon={<FaSearch />}
                            title="Búsquedas"
                            text="Haz búsquedas a través de nuestra API"
                        />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col lg={12} sm={12} xs={24}>
                        <CardGuide
                            icon={<FaUsers />}
                            title="Partners"
                            text="Usa Red Pay como partner y controla cuentas de comercio"
                        />
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                        <CardGuide
                            icon={<FaExchangeAlt />}
                            title="Transferencias bancarias"
                            text="Obtén una cuenta CLABE y una referencia para recibir transferencias electrónicas"
                        />
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                        <CardGuide
                            icon={<FaMoneyBill />}
                            title="Pago en efectivo"
                            text="Genera un recibo con código de barras y recibe pagos en efectivo"
                        />
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                        <CardGuide
                            icon={<FaList />}
                            title="Suscripciones"
                            text="Cobra servicios de manera periódica"
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <h4>Destacados</h4>
                <Row gutter={8}>
                    <Col md={8} sm={8} xs={24}>
                        <CardFeatured
                            img="img1"
                            title="Métodos de Integración"
                            text="Existen disponibles dos métodos, mediante los cuales, se puede integrar Red Pay"
                            link="/docs/integration-methods/integrationWeb"
                        />
                    </Col>
                    <Col md={8} sm={8} xs={24}>
                        <CardFeatured
                            img="img2"
                            title="Tipos de autenticación"
                            text="Red Pay cuenta con dos tipos de Autenticación: Basic HTTP Authentication y Json Web Token"
                            link="/docs/authentication-types/auth"
                        />
                    </Col>
                    <Col md={8} sm={8} xs={24}>
                        <CardFeatured
                            img="img3"
                            title="Tipos de pago"
                            text="Dependiendo de la lógica del negocio del cliente, puede considerarse diversos tipos de pago"
                            link="/docs/payment-types/capture1"
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <h4>Librerías</h4>
                <Row>
                    <Col md={12} sm={24} xs={24}>
                        <CardLibrary
                            btn={false}
                            img={node}
                            text="Parte de le tecnología de Red Pay es NodeJs para una mejor experiencia de usuario."
                        />
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <CardLibrary
                            img={woocommerce}
                            text="Red Pay cuenta con su plugin exclusivo para tiendas en línea con Wordpress a través de Woocommerce."
                            btn={true}
                        />
                    </Col>
                </Row>
            </Col>
        </div>
    );
}
