const initState = {
    userSetting: {
        cultureCode: "es-MX",
        isoCurrency: "MXN",
        companyName: "",
        environment: ""
    },
    blocking: false
}

export const reducers = (state = initState, action) => {
    switch (action.type) {
        case "Set_User_Setting":
            return {
                ...state,
                userSetting: action.userSetting
            };
        case "Toggle_Blocking":
            return {
                ...state,
                blocking: !state.blocking
            };
        default:
            return state;
    }
}