import React, {useState} from "react";
import {Alert, Button, FlexboxGrid, Form, FormControl, FormGroup} from "rsuite";
import FlexboxGridItem from "rsuite/es/FlexboxGrid/FlexboxGridItem";
import {BsFillClipboardCheckFill} from "react-icons/bs";
import {FaCopy} from "react-icons/fa";
import {endpoints} from "./endpoints";

export const TableEndpoint = ({urlProduction,urlSandbox}) => {

    const [formValue] = useState(endpoints);

    const [result, setResult] = useState('');
    async function copyNumberCard(text, name) {
        try {
            await navigator.clipboard.writeText(formValue[Object.keys(formValue).filter(item => item === name)]);
            Alert.success('Link Copiado')
            setResult(name);
        } catch (e) {
            Alert.warning('Error al copiar link')
            setResult('');
            throw e;
        } finally {
            setTimeout(() => {
                setResult('');
            }, 2000);
        }
    }

    return (
        <div className="table-docs">
            <div className="title">
                <p>Endpoint</p>
            </div>
            <div className="content">
                <div style={{padding: '0 1rem 0 1rem'}}>
                    <small className="pb-0">Producción:</small>
                    <FlexboxGrid justify="start">
                        <FlexboxGridItem>
                            <Form formValue={formValue} className="form-docs">
                                <FormGroup>
                                    <FormControl name={urlProduction} readOnly={true} />
                                    <Button color="red" onClick={() => copyNumberCard('',urlProduction)}>
                                        {
                                            (result === urlProduction) ?
                                                <>
                                                    <span><BsFillClipboardCheckFill /></span>
                                                </>
                                                :
                                                <span><FaCopy /></span>
                                        }

                                    </Button>
                                </FormGroup>
                            </Form>
                        </FlexboxGridItem>
                    </FlexboxGrid>
                </div>
                <div style={{padding: '0 1rem'}}>
                    <small>Sandbox:</small>
                    <FlexboxGrid justify="start" className="mb-1">
                        <FlexboxGridItem>
                            <Form formValue={formValue} className="form-docs">
                                <FormGroup>
                                    <FormControl name={urlSandbox} readOnly={true} />
                                    <Button color="red" onClick={() => copyNumberCard('',urlSandbox)}>
                                        {
                                            (result === urlSandbox) ?
                                                <>
                                                    <span><BsFillClipboardCheckFill /></span>
                                                </>
                                                :
                                                <span><FaCopy /></span>
                                        }

                                    </Button>
                                </FormGroup>
                            </Form>
                        </FlexboxGridItem>
                    </FlexboxGrid>
                </div>
            </div>
        </div>
    );
}
