import React, {useEffect, useState} from "react";
import {Button, Col, Modal} from "rsuite";

/*Importing styles*/
import "./docs.scss";
import diagram1 from  "../../assets/imgs/diagram_01.svg";
import diagram2 from  "../../assets/imgs/diagram_02.svg";
import diagram3 from  "../../assets/imgs/diagram_03.svg";
import diagram4 from  "../../assets/imgs/diagram_04.svg";
import diagram5 from  "../../assets/imgs/diagram_05.svg";
import {useParams} from "react-router-dom";
import {FaTimes} from "react-icons/fa";

export const PaymentTypes = () => {

    const [stateImg, setStateImg] = useState('');
    const [modal, setModal] = useState(false);
    const params = useParams();
    const [nameRoute,setNameRoute] = useState(`${params.name}`);

    const showModal = (img) => {
        setStateImg(img)
        setModal(true);
    }
    const hideModal = () => setModal(false);

    const [scrollTop,setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollTop(window.scrollY);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollTop]);

    return (
        <>
            {{
                'capture1':
                    <>
                        <Col xs={24}>
                            <h4>Tipos de pago</h4>
                            <p>Dependiendo de la lógica del negocio del cliente, puede considerarse los siguientes tipos
                                de pago:</p>
                        </Col>
                        <Col xs={24} id="capture1">
                            <h4>Captura Inmediata</h4>
                            <p>Se le conoce así a la petición que envía el e-commerce del cliente para solicitar la
                                autorización y realizar el cobro inmediato <br/>de la transacción, sin que el e-commerce
                                realice alguna acción extra.</p>
                            <div className="figure" onClick={() => showModal(diagram1)}>
                                <img src={diagram1} alt=""/>
                                <small>Diagrama de Captura Inmediata</small>
                            </div>
                        </Col>
                    </>,
                'delayedCapture':
                    <Col xs={24} id="capture2">
                            <h4>Captura Diferida (Autorización)</h4>
                            <p>Para utilizar este tipo de pago, debe solicitar autorización con su ejecutivo de cuenta.
                                Una petición de este tipo consta de los siguientes pasos generales, para un flujo
                                normal:
                            </p>
                            <ol className="list-docs">
                                <li>
                                    <p><strong>Solicitud de autorización:</strong> Petición que envía el e-commerce del
                                        cliente para solicitar la autorización y reservar el saldo.</p>
                                </li>
                                <li>
                                    <p><strong>Captura:</strong> Esta acción es realizada por el e-commerce y se produce
                                        después de la solicitud de autorización. Su función es confirmar, tanto el monto
                                        como la ejecución de la transacción.</p>
                                </li>
                            </ol>
                            <div className="figure">
                                <img src={diagram2} alt="" onClick={() => showModal(diagram2)}/>
                                <figcaption>Diagrama de Captura Diferida</figcaption>
                            </div>
                            <p>Si, dentro del proceso, se determina que no habrá Captura, es necesario cancelar la
                                Autorización.</p>
                        </Col>,
                'delayedCaptureCancellation':
                    <Col xs={24} id="capture2Cancel">
                        <h4>Cancelación</h4>
                        <p>
                            Esta acción es realizada por el e-commerce y se produce después de la solicitud de
                            autorización. Su función es cancelar el
                            cobro de la autorización. Por consiguiente, se libera el saldo
                        </p>
                        <div className="figure">
                            <img src={diagram3} alt="" onClick={() => showModal(diagram3)}/>
                            <figcaption>Diagrama de Captura Diferida: Cancelación</figcaption>
                        </div>
                        <p><strong>Notas:</strong></p>
                        <ul className="list-docs">
                            <li>El monto de la Captura puede ser menor o igual al monto de la Autorización.</li>
                            <li>El monto de la transacción no se ve reflejada en la cuenta del cliente hasta que se
                                realiza la captura.
                            </li>
                            <li>
                                La captura de la Autorización se realizará mientras dicha Autorización esté vigente, (7
                                días después de su generación
                                aproximadamente). Después de este tiempo, la Autorización se cancela automáticamente.
                            </li>
                        </ul>
                    </Col>,
                'subs':
                    <Col xs={24} id="subs">
                        <h4>Suscripciones</h4>
                        <p>
                            Una suscripción es una serie de pagos recurrentes, ejecutados periódicamente, de acuerdo con la frecuencia indicada por el
                            e-commerce. <br /> <br />
                            Para utilizar este tipo de pago, debe solicitar autorización con su ejecutivo de cuenta.
                        </p>
                        <div className="figure">
                            <img src={diagram4} alt="" onClick={() => showModal(diagram4)} />
                            <figcaption>Diagrama de Suscripción</figcaption>
                        </div>
                    </Col>,
                'subsCancellation':
                    <Col xs={24} id="subsCancel">
                        <h4>Cancelación de suscripciones</h4>
                        <p>
                            La finalización de una suscripción es manejada por el e-commerce, mediante la cancelación.
                        </p>
                        <div className="figure">
                            <img src={diagram5} alt="" onClick={() => showModal(diagram5)} />
                            <figcaption>Diagrama de Cancelación de Suscripción</figcaption>
                        </div>
                        <p><strong>Notas:</strong></p>
                        <ul className="list-docs">
                            <li>El monto no se puede modificar.</li>
                            <li>
                                El cobro de los pagos se ejecuta automáticamente por Red Pay, de acuerdo con la configuración de la suscripción, (monto y
                                frecuencia de cobro). Este continúa en ejecución hasta que el cliente envíe la petición de cancelación.
                            </li>
                        </ul>
                    </Col>
            }[nameRoute]}
            <Modal size="lg" show={modal} onHide={hideModal}>
                <Modal.Header style={{display:'flex', justifyContent: 'flex-end'}}>
                    <Button color={'red'} onClick={hideModal}>
                        <span style={{marginTop: '2px'}}><FaTimes /></span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <img src={stateImg} alt="" style={{width: 'auto', height: 'auto'}} />
                </Modal.Body>
            </Modal>
        </>
    );
}
