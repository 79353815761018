import { callApi, callApiFile } from './service'
import 'moment/locale/es';

const get = async ()=>{
    return callApiFile('/frequency', 'get')
        .then((result) => result);
}

export const frequencyService = {
    get
};
