import React from "react";
import {Col} from "rsuite";

/*Importing styles*/
import "./docs.scss";

export const ProductionValidationSession = () => {

    return (
        <>
            <Col xs={24}>
                <h4>Sesión de validación de producción</h4>
                <p>Una vez que el cliente concluya con la integración y con sus pruebas internas, dentro del "ambiente de integración", se agenda una sesión de validación con un asesor técnico de Red Pay, conforme a los siguientes pasos:</p>
                <ol className="list-docs">
                    <li>
                        <p>El cliente realizará dos compras guiadas en línea, en su e-commerce productivo: una con datos que permitan concluir con la compra de manera exitosa y otra que pueda ser rechazada.</p>
                    </li>
                    <li>
                        <p>Por cada transacción, el asesor de Red Pay monitorea lo siguiente:</p>
                        <ul className="list-docs">
                            <li>
                                <p>
                                    <strong>Para la transacción exitosa:</strong> Los montos por los cuales fueron autorizados, el registro de la transacción y
                                    el envío de la información correcta hacia el e-commerce del cliente, notificando que su transacción fue aprobada.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Para la transacción rechazada:</strong> El registro de la transacción, los motivos de rechazo de la misma, y el
                                    envío de la información correcta hacia el e-commerce del cliente, notificando que la transacción fue rechazada.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>El cliente valida que su flujo, posterior al resultado, se cumple.<br /></p>
                        <ul className="list-docs">
                            <li>
                                <p>
                                    Si, dentro de la sesión, se identifica algún error en el flujo, se reagendará la sesión de validación posterior a las correcciones que haga el cliente en su portal. <br/>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Si la validación es exitosa, el cliente podrá operar con Red Pay dentro de su e-commerce.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ol>
            </Col>
        </>
    );
}
