export const StatusEnum = {
	TransactionCreated : 1,
	Accepted : 2,
	Rejected : 3,
	CanceledByCcustomer : 4,
	PendingPayment : 5,
	Expired : 6,
	Review : 7,
	Authorized : 8,
	Reversed : 9,
	NonPayment : 10,
	Replaced : 11,
	Refund : 12,
	AuthorizationExpired : 13,
	ExpiredCashPayment : 14,
	PaymentCanceled : 15,
	PartialRefund : 16,
	ProcessOfAppeal : 17,
	Chargeback : 18
}