import { callApi } from './service'

export const accountService = {
    login,
    logout,
    role,
    companies,
    usersByCompany,
    usersToInvite,
    register,
    information
};

//login
async function login(credentials) {
    return callApi('/account/login', 'POST', credentials, undefined, credentials.Environment)
        .then((result) => {
            if (result.success) {
                sessionStorage.setItem('rp_token', result.data.token);
                return true;
            } else {
                console.log(result.message);
                return false;
            }
        });
}

//logout
async function logout() {
    sessionStorage.removeItem('rp_token');
    sessionStorage.removeItem('rp_company');
    window.location.href = "/";
}

//role
async function role(isByCompany) {
    return callApi(`/account/role/${isByCompany ? sessionStorage["rp_company"] : 0}`, 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return "";
            }
        });
}

//companies
async function companies(isUserModule) {
    return callApi('/account/companies/' + isUserModule, 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return [];
            }
        });
}

//users
async function usersByCompany(id) {
    return callApi(`/account/usersbycompany/${id}`, 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return [];
            }
        });
}

async function usersToInvite() {
    return callApi("/account/usertoinvite", 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return [];
            }
        });
}

//register
async function register(model) {
    return callApi('/account/register', 'POST', model)
        .then(result => result);
}

//information
async function information() {
    return callApi('/account/information/' + sessionStorage["rp_company"], 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return {};
            }
        });
}