import React, { useState, useEffect } from "react";
import { TitleSection } from "../../components/titleSection";
import { Table } from "../../components/table";
import { CalendarRange } from "../../components/myCalendar";
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    SelectPicker,
    InputGroup,
    Icon,
    Button,
    Pagination,
    CheckboxGroup,
    Checkbox,
} from "rsuite";
import { transactionService } from "../../services/transaction.service";
import { useSelector } from "react-redux";
import { getColumns } from "../../hooks/templateColumnsHook";
import statusJson from "../../data/status.json";
import { StatusEnum } from "../../enum/statusEnum";
import "./transactions.scss";

export const Transactions = (props) => {
    const userSetting = useSelector((state) => state.userSetting);
    const [status, setStatus] = useState([]);
    const [statusChargeBack, setStatusChargeBack] = useState([{ idStatusChargeBack: 0, name: '', description: '' }]);
    const [data, setData] = useState([]);
    const [isChargeBack, setIsChargeBack] = useState(false);
    const statusMoney = [0, StatusEnum.Accepted, StatusEnum.ExpiredCashPayment, StatusEnum.PendingPayment];
    const currentDate = new Date();
    const initialFilters = {
        idStatus: 0,
        initDate: currentDate,
        endDate: currentDate,
        referenceNumber: "",
        email: "",
        statusChargeBack: [],
        referenceCash: "",
        isMoney: false
    };
    const setInitialFilters = () => {
        let filtersTmp = initialFilters;
        if (sessionStorage["rp_filters"] !== undefined) {
            filtersTmp = JSON.parse(sessionStorage["rp_filters"]);
            filtersTmp.initDate = filtersTmp.initDate ? new Date(filtersTmp.initDate) : "";
            filtersTmp.endDate = filtersTmp.endDate ? new Date(filtersTmp.endDate) : "";
        }

        return filtersTmp;
    };
    const [filters, setFilters] = useState(setInitialFilters());

    const initialTableData = { rows: 0, pages: 0, activePage: 1 };
    const setInitialTableData = () => {
        let tableDataTmp = initialTableData;
        if (sessionStorage["rp_ActivePage"] !== undefined)
            tableDataTmp.activePage = parseInt(sessionStorage["rp_ActivePage"]);

        return tableDataTmp;
    };
    const [tableData, setTableData] = useState(setInitialTableData());

    useEffect(() => {
        transactionService.status().then((result) => {
            result = result.filter(x => x.value !== StatusEnum.Chargeback.toString());
            const statusTemp = result.map((item) => {
                item.label = statusJson[item.value].text;
                return item;
            });
            setStatus(statusTemp);
        });

        transactionService.statusChargeBack().then((result) => {
            if (result.length > 0) {
                let idStatusCB = result.map(x => x.idStatusChargeBack);
                setStatusChargeBack(result);
                setFilters({ ...filters, statusChargeBack: idStatusCB })
                getTransactions(tableData.activePage);
            }
        });
        return () => {
            sessionStorage.removeItem('rp_filters');
            sessionStorage.removeItem('rp_ActivePage');
        }
    }, []);

    const getTransactions = (pageNumber) => {
        if (filters.idStatus == StatusEnum.ProcessOfAppeal && filters.statusChargeBack.length == 0) {
            setTableData(initialTableData);
            setData([]);
        } else {
            filters.initDate =
                filters.initDate == "Invalid Date" ? "" : filters.initDate;
            filters.endDate = filters.endDate == "Invalid Date" ? "" : filters.endDate;

            transactionService
                .get({ ...filters, pageNumber, rowsOfPage: 10 })
                .then((result) => {
                    localStorage.setItem("statusIdTransaction", filters.idStatus);
                    setData(result);
                    if (result.length > 0) {
                        setTableData({
                            rows: result[0].maxRows,
                            pages: result[0].maxPages,
                            activePage: pageNumber,
                        });
                        setIsChargeBack(filters.idStatus == StatusEnum.ProcessOfAppeal);
                        sessionStorage.setItem("rp_filters", JSON.stringify(filters));
                        sessionStorage.setItem("rp_ActivePage", pageNumber);
                    }
                    else setTableData(initialTableData);
                });
        }
    };

    const handleFilters = () => {
        sessionStorage.setItem("rp_filters", JSON.stringify(filters));
        getTransactions(1);
    };

    const columns = getColumns(userSetting, handleFilters);

    const generateReport = () => {
        if (tableData.rows > 0) {
            transactionService.generateReport({ ...filters, rows: tableData.rows });
        }
    };

    const handleSelectPage = (page) => {
        sessionStorage.setItem("rp_ActivePage", page);
        setTableData({ ...tableData, activePage: page });
        getTransactions(page);
    };

    const initCalendar = (filtersx) => {
        let fechaFinal = undefined;
        let fechaInicial = undefined;
        if (filtersx.endDate != "" || filtersx.endDate != "Invalid Date") { fechaFinal = filtersx.endDate } else { fechaFinal = currentDate; }
        if (filtersx.initDate == "" || filtersx.initDate != "Invalid Date") { fechaInicial = filtersx.initDate } else { fechaInicial = currentDate; }
        return (<CalendarRange
            defaultValue={fechaInicial && fechaFinal ? [fechaInicial, fechaFinal] : []}
            onChange={(value) => {
                var filterSession;
                if (sessionStorage["rp_filters"]) {
                    filterSession = JSON.parse(sessionStorage["rp_filters"]);
                } else {
                    filterSession = filters;
                }

                filterSession.initDate = value[0];
                filterSession.endDate = value[1];
                sessionStorage.setItem("rp_filters", JSON.stringify(filterSession));
                return setFilters({
                    ...filters,
                    initDate: value[0],
                    endDate: value[1],
                })
            }
            }
        />);
    }

    const showFilterMoney = () => {
        return <FlexboxGrid>
            <FlexboxGrid.Item className="mb-6"
                              componentClass={Col}
                              md={6}
                              sm={12}
                              xs={24}
                              style={{ fontSize: 11 }}>
                <FormGroup>
                    <InputGroup inside>
                        <Checkbox key="chkMoney" checked={filters.isMoney}
                                  disabled={!statusMoney.includes(parseInt(filters.idStatus == null ? 0 : filters.idStatus))}
                                  onChange={(value) => {
                                      let newFilters = { ...filters };
                                      if (newFilters.isMoney) { newFilters.referenceCash = ""; }
                                      newFilters.isMoney = !newFilters.isMoney;
                                      setFilters(newFilters);
                                  }}>Buscar referencia en efectivo</Checkbox>
                    </InputGroup>
                </FormGroup>
            </FlexboxGrid.Item>
            {filters.isMoney && statusMoney.includes(parseInt(filters.idStatus == null ? 0 : filters.idStatus)) &&
                <FlexboxGrid.Item className="mb-6"
                                  componentClass={Col}
                                  md={6}
                                  sm={12}
                                  xs={24}
                                  style={{ fontSize: 11 }}>
                    <FormGroup>
                        <ControlLabel>Buscar referencia en efectivo</ControlLabel>
                        <InputGroup inside>
                            <FormControl
                                size="lg"
                                name="input-1"
                                placeholder="Referencia del ticket"
                                onChange={(value) =>
                                    setFilters({ ...filters, referenceCash: value })
                                }
                                value={filters.referenceCash}
                            />
                            <InputGroup.Addon>
                                <Icon icon="search" />
                            </InputGroup.Addon>
                        </InputGroup>
                    </FormGroup>
                </FlexboxGrid.Item>}
        </FlexboxGrid>;
    }

    return (
        <section className="wrapper_transactions">
            <TitleSection title="Movimientos" />
            <div className="text-right">
                <Button
                    className="btn c_primary"
                    appearance="subtle"
                    type="submit"
                    onClick={generateReport}
                >
                    <Icon icon="file" />
                    <span className="fw-400"> Generar reporte</span>
                </Button>
            </div>
            <div className="box_filter_options">
                <Form onSubmit={handleFilters}>
                    <FlexboxGrid align="bottom">
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={6}
                            sm={12}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel>Buscar por rango de fechas</ControlLabel>
                                {initCalendar(filters)}
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={5}
                            sm={12}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel>Buscar por estatus</ControlLabel>
                                <SelectPicker
                                    block
                                    size="lg"
                                    placeholder="Seleccione"
                                    data={status}
                                    onChange={(value) => {
                                        let newFilters = { ...filters };
                                        newFilters.idStatus = value;
                                        if (!statusMoney.includes(parseInt(value))) { newFilters.referenceCash = ""; newFilters.isMoney = false; }
                                        setFilters(newFilters);
                                        setIsChargeBack(value == StatusEnum.ProcessOfAppeal);
                                    }}
                                    value={filters.idStatus}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={5}
                            sm={12}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel>Buscar por referencia</ControlLabel>
                                <InputGroup inside>
                                    <FormControl
                                        size="lg"
                                        name="input-1"
                                        placeholder="Referencia"
                                        onChange={(value) =>
                                            setFilters({ ...filters, referenceNumber: value })
                                        }
                                        value={filters.referenceNumber}
                                    />
                                    <InputGroup.Addon>
                                        <Icon icon="search" />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={5}
                            sm={12}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel>Buscar por correo electronico</ControlLabel>
                                <InputGroup inside>
                                    <FormControl
                                        size="lg"
                                        name="input-1"
                                        placeholder="Correo electronico"
                                        onChange={(value) =>
                                            setFilters({ ...filters, email: value })
                                        }
                                        value={filters.email}
                                    />
                                    <InputGroup.Addon>
                                        <Icon icon="search" />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={3}
                            sm={12}
                            xs={24}
                        >
                            <Button
                                className="btn secundary"
                                size="lg"
                                block
                                appearance="primary"
                                type="submit"
                            >
                                Buscar
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {showFilterMoney()}
                    {isChargeBack ? (
                        <FlexboxGrid>
                            <FlexboxGrid.Item className="mb-5"
                                              componentClass={Col}
                                              md={5}
                                              sm={12}
                                              xs={24}
                                              style={{ fontSize: 11 }}>
                                <CheckboxGroup inline name="chkStatusChargeback" value={filters.statusChargeBack} onChange={(value) => { setFilters({ ...filters, statusChargeBack: value }); }}>
                                    {statusChargeBack ? (statusChargeBack.map(item => {
                                        return <Checkbox key={`chk${item.idStatusChargeBack}`} value={item.idStatusChargeBack}>{item.description}</Checkbox>
                                    })) : ''}
                                </CheckboxGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>) : ''}
                </Form>
            </div>

            <div className="mt-3 mb-5">
                <br />
                <Table myData={data} myColumns={columns}></Table>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    ellipsis
                    maxButtons={10}
                    boundaryLinks
                    pages={tableData.pages}
                    activePage={tableData.activePage}
                    onSelect={handleSelectPage}
                />
            </div>
        </section>
    );
};
