import React, { useState, useEffect } from 'react'
import { Table } from '../../components/table'
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Icon,
    Button,
    Pagination
} from 'rsuite';
import { subscriptionService } from '../../services/subscription.service'
import { useSelector } from 'react-redux'
import { getColumns } from "./templateColumns"
import { SubscriptionStatusEnum } from '../../enum/subscriptionStatusEnum';

export const SubscripCancel = (props) => {
    const userSetting = useSelector(state => state.userSetting);
    const [data, setData] = useState([]);
    const [reference, setReference] = useState("");
    const [tableData, setTableData] = useState({ rows: 0, pages: 0, activePage: 0 });

    const columns = getColumns(userSetting);

    useEffect(() => {
        if(props.active)
            getSubscriptions("", 1);
    }, [props.active]);

    const getSubscriptions = (referenceNumber, pageNumber) => {
        subscriptionService.getSubscription(SubscriptionStatusEnum.Cancel, referenceNumber, pageNumber, 10)
            .then((result) => {
                setData(result);
                if (result.length > 0) setTableData({ rows: result[0].maxRows, pages: result[0].maxPages, activePage: pageNumber });
            });
    }

    const handleSelectPage = (page) => {
        setTableData({ ...tableData, activePage: page });
        getSubscriptions(reference, page);
    }

    const generateReport = () => {
        if (tableData.rows > 0) {
            subscriptionService.generateReportSubscription(SubscriptionStatusEnum.Cancel, reference, tableData.rows);
        }
    }

    return (
        <div className="">
            <div className="text-right">
                <Button className="btn c_primary" appearance="subtle" type="submit" onClick={generateReport}>
                    <Icon icon="file" />
                    <span className="fw-400"> Generar reporte</span>
                </Button>
            </div>

            <FlexboxGrid align="middle">
                <FlexboxGrid.Item className="mb-3" componentClass={Col} lg={12} sm={10} xs={24}>
                    <h3 className="fw-300">Suscripciones canceladas</h3>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="mb-3" componentClass={Col} lg={12} sm={10} xs={24}>
                    <Form className="sec_box_filters" onSubmit={() => getSubscriptions(reference, 1)}>
                        <FlexboxGrid align="bottom">
                            <FlexboxGrid.Item className="mb-3" componentClass={Col} sm={16} xs={24}>
                                <FormGroup>
                                    <ControlLabel>Buscar por referencia</ControlLabel>
                                    <InputGroup inside>
                                        <FormControl
                                            size='lg'
                                            name="input-1"
                                            placeholder="Referencia"
                                            // errorMessage={errorMessage}
                                            // errorPlacement={errorPlacement}
                                            onChange={(value) => setReference(value)}
                                        />
                                        <InputGroup.Addon>
                                            <Icon icon="search" />
                                        </InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="mb-3" componentClass={Col} sm={8} xs={24}>
                                <Button className="btn secundary" block appearance="primary" size="lg" type="submit">Buscar</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <div className="mt-3  mb-5">
                <Table myData={data} myColumns={columns}></Table>
                <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={tableData.pages} activePage={tableData.activePage} onSelect={handleSelectPage} />
            </div>
        </div>
    )
}