import React, { useEffect, useState } from "react";
import { TitleSection } from "../../components/titleSection";
import {
    Form,
    Whisper,
    Tooltip,
    IconButton,
    Icon,
    FormGroup,
    FormControl,
    FlexboxGrid,
    Col,
    Button,
    Notification,
} from "rsuite";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {ModalConfirm} from '../../components/modals/modalConfirm'
import { settingService } from "../../services/setting.service";
import "./integration.scss";

export const BoxKeys = ({ apiKey, environment, publicKey }) => {
    const [key, setKey] = useState("");

    useEffect(() => {
        setKey(publicKey);
    }, [publicKey]);

    const createSecretKey = () => {
        settingService.createPublicKey().then((result) => {
            if (result.success) {
                setKey(result.data);
                Notification.success({
                    title: "La public key se genero correctamente.",
                    duration: 5000,
                });
            } else {
                console.log(result.message);
                Notification.error({
                    title: "Ocurrió un error al generar public key.",
                    duration: 5000,
                });
            }
        });
    }

    const handleKey = () => {
        ModalConfirm.show({ size: "xs", title: "Public Key", text: `¿Estas seguro que deseas ${publicKey ? "resetear" : "crear"} la public key?`, onOk: () => createSecretKey() });
    }

    return (
        <section className="wrapper_boxAPIKeys">
            <TitleSection title="Keys" />
            {environment === "MODO PRUEBAS" ? (
                <div className="box_list_key test">
                    <div className="mb-4">
                        <h6 className="mb-1">Api key pruebas</h6>
                        <p className="paragraph fw-300">{apiKey}</p>
                    </div>
                </div>
            ) : (
                <div className="box_list_key prod">
                    <div className="mb-4">
                        <h6 className="mb-1">Api key productiva</h6>
                        <p className="paragraph fw-300">{apiKey}</p>
                    </div>
                </div>
            )}
            <div className="box_list_key secret">
                <div className="mb-4">
                    <h6 className="mb-1">Public key</h6>
                </div>
                <Form fluid>
                    <FlexboxGrid align="top">
                        {key && (
                            <FlexboxGrid.Item
                                className="mb-3"
                                componentClass={Col}
                                colspan={24}
                                xs={24}
                                sm={17}
                            >
                                <FormGroup className="mb-0 myform">
                                    <FormControl size="lg" name="key" value={key} />
                                    <CopyToClipboard text={key}>
                                        <Whisper
                                            placement="bottom"
                                            trigger="hover"
                                            speaker={<Tooltip>Copiar al portapapeles</Tooltip>}
                                        >
                                            <IconButton
                                                className="ml-2"
                                                icon={<Icon icon="copy" />}
                                                circle
                                                size="md"
                                            />
                                        </Whisper>
                                    </CopyToClipboard>
                                </FormGroup>
                            </FlexboxGrid.Item>
                        )}
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            colspan={24}
                            xs={24}
                            sm={7}
                        >
                            <Button
                                className="btn secundary"
                                block
                                size="md"
                                appearance="primary"
                                type="button"
                                onClick={handleKey}
                            >
                                {publicKey ? "Resetear" : "Crear"}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </div>
        </section>
    );
};
