import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { Table } from '../../components/table'
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Icon,
    Button,
    IconButton,
    Whisper,
    Tooltip,
    Pagination,
    Notification
} from 'rsuite';
import { subscriptionService } from '../../services/subscription.service'
import { useSelector } from 'react-redux'
import { ModalConfirm, ModalMessage } from '../../components/modals/modalConfirm'
import { getColumns } from "./templateColumns";
import { SubscriptionStatusEnum } from '../../enum/subscriptionStatusEnum';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const tooltipCancelSubs = (
    <Tooltip>
        Cancelar suscripción
    </Tooltip>
);
const tooltipActiveSubs = (
    <Tooltip>
        Reactivar suscripción
    </Tooltip>
);
const tooltipReplaceCardToken = (
    <Tooltip>
        Remplazar tarjeta
    </Tooltip>
);

export const SubscripPause = (props) => {
    const userSetting = useSelector(state => state.userSetting);
    const [data, setData] = useState([]);
    const [reference, setReference] = useState("");
    const [tableData, setTableData] = useState({ rows: 0, pages: 0, activePage: 0 });
    const navigate = useHistory();

    let columns = [{
        name: 'Acciones',
        center: true,
        width: "100px",
        cell: (row) => <> <span className="box_ico_table" onClick={() => cancelSuscription(row.idOriginTransaction)}>
            <Whisper placement="bottom" trigger="hover" speaker={tooltipCancelSubs}>
                <IconButton
                    className="mr-2 "
                    icon={<Icon icon="ban" />}
                    circle
                    size="md"
                />
            </Whisper>
        </span>
            {/* <span className="box_ico_table" onClick={() => reActiveSuscription(row.idOriginTransaction)}>
            <Whisper placement="bottom" trigger="hover" speaker={tooltipActiveSubs}>
                <IconButton
                    className="mr-2 "
                    icon={<Icon icon="reload" />}
                    circle
                    size="md"
                />
            </Whisper>
        </span> */}
            {row.linkToReplaceCard ? <span className="box_ico_table"> <CopyToClipboard text={row.linkToReplaceCard}>
                <Whisper
                    placement="bottom"
                    trigger="hover"
                    speaker={<Tooltip>Copiar al portapapeles</Tooltip>}
                >
                    <IconButton
                        className="mr-2"
                        icon={<Icon icon="copy" />}
                        circle
                        size="md"
                    />
                </Whisper>
            </CopyToClipboard> </span> : <span className="box_ico_table" onClick={() => replaceCardToken(row.idSubscription, row.referenceNumber)}>
                <Whisper placement="bottom" trigger="hover" speaker={tooltipReplaceCardToken}>
                    <IconButton
                        className="mr-2 "
                        icon={<Icon icon="credit-card" />}
                        circle
                        size="md"
                    />
                </Whisper>
            </span>
            }

        </>,
    }];
    columns = [...columns, ...getColumns(userSetting, true)];

    useEffect(() => {
        if (props.active)
            getSubscriptions("", 1);
    }, [props.active]);

    const getSubscriptions = (referenceNumber, pageNumber) => {
        subscriptionService.getSubscription(SubscriptionStatusEnum.Pause, referenceNumber, pageNumber, 10)
            .then((result) => {
                setData(result);
                if (result.length > 0) setTableData({ rows: result[0].maxRows, pages: result[0].maxPages, activePage: pageNumber });
            });
    }

    const handleSelectPage = (page) => {
        setTableData({ ...tableData, activePage: page });
        getSubscriptions(reference, page);
    }

    const cancel = (id, justification) => {
        subscriptionService.cancelSubscription({ TransactionNumber: id, reason: justification })
            .then((result) => {
                if (result.success) {
                    Notification.success({ title: 'Suscripción cancelada exitosamente', duration: 5000 });
                    getSubscriptions(reference, 1);
                } else Notification.error({ title: result.message, duration: 5000 });
            });
    }

    const reactive = (id) => {
        subscriptionService.reactiveSubscription({ TransactionNumber: id })
            .then((result) => {
                if (result.success) {
                    Notification.success({ title: 'Suscripción reactivada exitosamente', duration: 5000 });
                    getSubscriptions(reference, 1);
                } else Notification.error({ title: result.message, duration: 5000 });
            });
    }

    const cancelSuscription = (id) => {
        ModalConfirm.show({
            size: "xs",
            title: "Cancelar suscripción",
            text: "¿Estás seguro que deseas cancelar esta suscripción?",
            onOk: () => {
                ModalConfirm.show({
                    size: "xs",
                    title: "Cancelar suscripción",
                    text: "",
                    requireJustification: true,
                    onOk: (justification) => {
                        cancel(id, justification)
                    }
                });
            }
        });
    }

    const reActiveSuscription = (id) => {
        ModalConfirm.show({
            size: "xs",
            title: "Reactivar suscripción",
            text: "¿Estas seguro que deseas reactivar esta suscripción?",
            onOk: () => {
                reactive(id);
            }
        });
    }



    const replaceCardToken = (idSubscription, referenceNumber) => {
        ModalConfirm.show({
            size: "xs",
            title: "Remplazar tarjeta",
            text: `¿Estás seguro que deseas remplazar la tarjeta de la suscripción: ${referenceNumber}?`,
            onOk: () => {
                subscriptionService.replaceCardToken(idSubscription)
                    .then((result) => {
                        if (result.success) {
                            Notification.success({ title: 'Se creo la liga para remplazar la tarjeta exitosamente', duration: 5000 });
                            getSubscriptions(reference, tableData.activePage);
                        }
                        else Notification.error({ title: result.message, duration: 5000 });
                    });
            }
        });
    }

    const generateReport = () => {
        if (tableData.rows > 0) {
            subscriptionService.generateReportSubscription(SubscriptionStatusEnum.Pause, reference, tableData.rows);
        }
    }

    return (
        <div className="">
            <div className="text-right">
                <Button className="btn c_primary" appearance="subtle" type="submit" onClick={generateReport}>
                    <Icon icon="file" />
                    <span className="fw-400"> Generar reporte</span>
                </Button>
            </div>

            <FlexboxGrid align="middle">
                <FlexboxGrid.Item className="mb-3" componentClass={Col} lg={12} sm={10} xs={24}>
                    <h3 className="fw-300">Suscripciones pausadas</h3>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="mb-3" componentClass={Col} lg={12} sm={14} xs={24}>
                    <Form className="sec_box_filters" onSubmit={() => getSubscriptions(reference, 1)}>
                        <FlexboxGrid align="bottom">
                            <FlexboxGrid.Item className="mb-3" componentClass={Col} sm={16} xs={24}>
                                <FormGroup>
                                    <ControlLabel>Buscar por referencia</ControlLabel>
                                    <InputGroup inside>
                                        <FormControl
                                            size='lg'
                                            name="input-1"
                                            placeholder="Referencia"
                                            // errorMessage={errorMessage}
                                            // errorPlacement={errorPlacement}
                                            onChange={(value) => setReference(value)}
                                        />
                                        <InputGroup.Addon>
                                            <Icon icon="search" />
                                        </InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="mb-3" componentClass={Col} sm={8} xs={24}>
                                <Button className="btn secundary" block appearance="primary" size="lg" type="submit">Buscar</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <div className="mt-3 mb-5">
                <Table myData={data} myColumns={columns}></Table>
                <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={tableData.pages} activePage={tableData.activePage} onSelect={handleSelectPage} />
            </div>
        </div>
    )
}