import React, {useEffect, useState} from "react";
import { Dropdown, Nav, Sidenav} from "rsuite";
import {useLocation, useParams} from "react-router-dom";

export const AsideDocs = ({isMobile, expanded, showSidenav}) => {
    const location = useLocation();
    const params = useParams();

    const [openKeys, setOpenKeys] = useState([]);
    const [activeKey, setActiveKey] = useState([]);

    const routeNames = [
        {
            "key":   "3-1",
            "label": "capture1",
            "routeParent": "3"
        },
        {
            "key":   "3-2",
            "label": "delayedCapture",
            "routeParent": "3"
        },
        {
            "key":   "3-3",
            "label": "delayedCaptureCancellation",
            "routeParent": "3"
        },
        {
            "key":   "3-4",
            "label": "subs",
            "routeParent": "3"
        },
        {
            "key":   "3-5",
            "label": "subsCancellation",
            "routeParent": "3"
        },
        {
            "key":   "5-1",
            "label": "auth",
            "routeParent": "5"
        },
        {
            "key":   "5-2",
            "label": "json",
            "routeParent": "5"
        },
        {
            "key":   "6-1",
            "label": "response",
            "routeParent": "6"
        },
        {
            "key":   "6-2",
            "label": "frecuency",
            "routeParent": "6"
        },
        {
            "key":   "7-1",
            "label": "create",
            "routeParent": "7"
        },
        {
            "key":   "7-2",
            "label": "delete",
            "routeParent": "7"
        },
        {
            "key":   "7-3",
            "label": "list",
            "routeParent": "7"
        },
        {
            "key":   "8-1",
            "label": "web",
            "routeParent": "8"
        },
        {
            "key":   "8-1-1",
            "label": "integrationWeb",
            "routeParent": "8-1",
            "routeBigParent": "8"
        },
        {
            "key":   "8-1-2",
            "label": "wci",
            "routeParent": "8-1",
            "routeBigParent": "8"
        },
        {
            "key":   "8-1-3",
            "label": "wcda",
            "routeParent": "8-1",
            "routeBigParent": "8"
        },
        {
            "key":   "8-1-4",
            "label": "wcdc",
            "routeParent": "8-1",
            "routeBigParent": "8"
        },
        {
            "key":   "8-1-5",
            "label": "wcdx",
            "routeParent": "8-1",
            "routeBigParent": "8"
        },
        {
            "key":   "8-1-6",
            "label": "wsc",
            "routeParent": "8-1",
            "routeBigParent": "8"
        },
        {
            "key":   "8-1-7",
            "label": "wsx",
            "routeParent": "8-1",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2",
            "label": "API",
            "routeParent": "8-2"
        },
        {
            "key":   "8-2-2",
            "label": "deferment",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-3",
            "label": "cash-payments",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-4",
            "label": "inmediate-capture-card",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-5",
            "label": "inmediate-capture-session",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-6",
            "label": "inmediate-capture-token-creation",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-7",
            "label": "inmediate-capture-token",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-8",
            "label": "delayed-capture-auth",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-9",
            "label": "delayed-capture-token",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-10",
            "label": "delayed-capture-auth-token",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-25",
            "label": "delayed-capture-msi",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-11",
            "label": "capture",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-12",
            "label": "delayed-capture-cancel",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-14",
            "label": "suscription-token",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-15",
            "label": "suscription-token-initial-date",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-16",
            "label": "suscription-card",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-17",
            "label": "suscription-card-initial-date",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-18",
            "label": "suscription-cancelation",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },

        {
            "key":   "8-2-19",
            "label": "refund",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-20",
            "label": "transaction-status",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-21",
            "label": "by-reference",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-22",
            "label": "report",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-23",
            "label": "device-fingerprint",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "8-2-1",
            "label": "api",
            "routeParent": "8-2",
            "routeBigParent": "8"
        },
        {
            "key":   "9-1",
            "label": "simulation",
            "routeParent": "9"
        },
        {
            "key":   "9-2",
            "label": "visa",
            "routeParent": "9"
        },
        {
            "key":   "9-3",
            "label": "americanExpress",
            "routeParent": "9"
        }
    ]

    const getNameRoute = () => {
        routeNames.map(route => {
            if(route.label === params.name) {
                openKeys.push(route.routeBigParent)
                openKeys.push(route.routeParent)
                setActiveKey([route.key]);
            }
            return [openKeys, activeKey]
        });
    }

    useEffect(() => {
        getNameRoute();
    },[location.pathname])

    return (
        <>
            <Sidenav defaultOpenKeys={openKeys} activeKey={activeKey[0]}
                     className={`wrapper-docs-sidenav ${expanded ? 'visible' : ''} ${isMobile ? 'mobile' : ''}`}
                     expanded={true}
                     onSelect={showSidenav}
            >
                <Sidenav.Body>
                    <Nav>
                        {
                            (isMobile) ?
                                <>
                                    <Nav.Item href="/">Home</Nav.Item>
                                    <Nav.Item href="/" className="rs-btn-red">Iniciar Sesión</Nav.Item>
                                </>
                                : ''
                        }
                        <Nav.Item eventKey="1" href="/docs">Homepage</Nav.Item>
                        <Nav.Item eventKey="2" href="/docs/integration-credentials">Credenciales de Integración</Nav.Item>
                        <Dropdown eventKey="3" title="Tipos de Pago">
                            <Dropdown.Item eventKey="3-1" href="/docs/payment-types/capture1">Captura inmediata</Dropdown.Item>
                            <Dropdown.Item eventKey="3-2" href="/docs/payment-types/delayedCapture">Captura diferida</Dropdown.Item>
                            <Dropdown.Item eventKey="3-3" href="/docs/payment-types/delayedCaptureCancellation">Captura diferida cancelación</Dropdown.Item>
                            <Dropdown.Item eventKey="3-4" href="/docs/payment-types/subs">Suscripciones</Dropdown.Item>
                            <Dropdown.Item eventKey="3-5" href="/docs/payment-types/subsCancellation">Cancelación de suscripciones</Dropdown.Item>
                        </Dropdown>
                        <Nav.Item eventKey="4" href="/docs/antifraud-validations">Validaciones antifraude</Nav.Item>
                        <Dropdown eventKey="5" title="Tipos de Autenticación">
                            <Dropdown.Item eventKey="5-1" href="/docs/authentication-types/auth">Autenticación HTTP</Dropdown.Item>
                            <Dropdown.Item eventKey="5-2" href="/docs/authentication-types/json">Json Web Token (JWT)</Dropdown.Item>
                        </Dropdown>
                        <Dropdown eventKey="6" title="Catálogos">
                            <Dropdown.Item eventKey="6-1" href="/docs/catalogs/response">Códigos de respuesta</Dropdown.Item>
                            <Dropdown.Item eventKey="6-2" href="/docs/catalogs/frecuency">Códigos de frecuencia</Dropdown.Item>
                        </Dropdown>
                        <Dropdown eventKey="7" title="Tokenización">
                            <Dropdown.Item eventKey="7-1" href="/docs/tokenization/create">Creación de token (CT)</Dropdown.Item>
                            <Dropdown.Item eventKey="7-2" href="/docs/tokenization/delete">Eliminación de token</Dropdown.Item>
                            <Dropdown.Item eventKey="7-3" href="/docs/tokenization/list">Listado de tokens creados</Dropdown.Item>
                        </Dropdown>
                        <Dropdown eventKey="8" title="Métodos de integración">
                            <Dropdown.Menu eventKey="8-1" title="Web Checkout">
                                <Dropdown.Item eventKey="8-1-1" href="/docs/integration-methods/integrationWeb">Instrucciones de integración</Dropdown.Item>
                                <Dropdown.Item eventKey="8-1-2" href="/docs/integration-methods/wci">Captura Inmediata</Dropdown.Item>
                                <Dropdown.Item eventKey="8-1-3" href="/docs/integration-methods/wcda">Captura Diferida</Dropdown.Item>
                                <Dropdown.Item eventKey="8-1-6" href="/docs/integration-methods/wsc">Creación de suscripción</Dropdown.Item>
                            </Dropdown.Menu>
                            <Dropdown.Menu eventKey="8-2" title="API">
                                    <Dropdown.Item eventKey="8-2-1" href="/docs/integration-methods/api">API</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-2" href="/docs/integration-methods/deferment">Aplazo</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-3" href="/docs/integration-methods/cash-payments">Generación de referencia para pagos en efectivo</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-4" href="/docs/integration-methods/inmediate-capture-card">Captura Inmediata con tarjeta</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-5" href="/docs/integration-methods/inmediate-capture-session">Captura inmediata con sesión</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-6" href="/docs/integration-methods/inmediate-capture-token-creation">Captura Inmediata con creación de token</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-7" href="/docs/integration-methods/inmediate-capture-token">Captura Inmediata con token</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-8" href="/docs/integration-methods/delayed-capture-auth">Captura diferida (Autorización)</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-9" href="/docs/integration-methods/delayed-capture-token">Captura diferida (Autorización con creación de token)</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-10" href="/docs/integration-methods/delayed-capture-auth-token">Captura diferida (Autorización con token)</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-25" href="/docs/integration-methods/delayed-capture-msi">Captura diferida a MSI</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-12" href="/docs/integration-methods/delayed-capture-cancel">Cancelación de captura diferida</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-11" href="/docs/integration-methods/capture">Captura</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-14" href="/docs/integration-methods/suscription-token">Suscripción con token</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-15" href="/docs/integration-methods/suscription-token-initial-date">Suscripción con token y fecha inicial</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-16" href="/docs/integration-methods/suscription-card">Suscripción con tarjeta</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-17" href="/docs/integration-methods/suscription-card-initial-date">Suscripción con tarjeta y fecha inicial</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-18" href="/docs/integration-methods/suscription-cancelation">Cancelación de suscripción</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-19" href="/docs/integration-methods/refund">Reembolso</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-20" href="/docs/integration-methods/transaction-status">Obtención de Estatus de la Transacción por número</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-21" href="/docs/integration-methods/by-reference">Obtención de Estatus de la Transacción por referencia</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-22" href="/docs/integration-methods/report">Reporte de transacciones</Dropdown.Item>
                                    <Dropdown.Item eventKey="8-2-23" href="/docs/integration-methods/device-fingerprint">Device fingerPrint</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown eventKey="9" title="Pruebas de integración">
                            <Dropdown.Item eventKey="9-1" href="/docs/integration-tests/simulation">Simulación de resultados</Dropdown.Item>
                            <Dropdown.Item eventKey="9-2" href="/docs/integration-tests/visa">VISA / Mastercard</Dropdown.Item>
                            <Dropdown.Item eventKey="9-3" href="/docs/integration-tests/americanExpress">American Express</Dropdown.Item>
                        </Dropdown>
                        <Nav.Item eventKey="10" href="/docs/production-validation-session">Sesión de validación de producción</Nav.Item>
                    </Nav>
                </Sidenav.Body>
            </Sidenav>
        </>
    );
}
