import React from 'react';
import {
    DateRangePicker,
    DatePicker,
} from 'rsuite';

const Locale = {
    sunday: 'Do',
    monday: 'Lu',
    tuesday: 'Ma',
    wednesday: 'Mi',
    thursday: 'Ju',
    friday: 'Vi',
    saturday: 'Sa',
    ok: 'OK',
    today: 'Hoy',
    yesterday: 'Ayer',
    last7Days: 'Última semana',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos'
};

export const CalendarRange = ({ onChange, defaultValue }) => {
    return (
        <DateRangePicker
            placeholder="Seleccione un rango"
            locale={Locale}
            block
            size="lg"
            onChange={onChange}
            defaultValue={defaultValue ? defaultValue : [new Date(), new Date()]}
        />
    );
};

export const CalendarSingle = () => {
    return (
        <DatePicker
            locale={Locale}
            block
            size="lg"
        />
    );
};

export const CalendarDisabledDate = ({ initDate, endDate, onChange }) => {
    return (
        <DatePicker
            placement="bottomEnd"
            disabledDate={date => date < initDate || date > endDate}
            locale={Locale}
            block
            size="lg"
            onChange={onChange}
        />
    );
};

export const CalendarWithEnabledRange = ({ startDate, endDate, defaultValue, onChange }) => {
    return (
        <DateRangePicker
            placeholder="Seleccione un rango"
            disabledDate={date => date < startDate || date > endDate}
            locale={Locale}
            block
            size="lg"
            defaultValue={defaultValue ? defaultValue : [new Date(), new Date()]}
            onChange={onChange}
        />
    );
};
