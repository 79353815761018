import React from 'react'

export const TitleSection = (props) => {
 
  return (
    <h2
      className="ttl_section"
      style={{ color: props.color}}
    >
      {props.title}
    </h2>
  )
}
