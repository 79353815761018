import React from 'react';
import DataTable from 'react-data-table-component';

export const Table = ({ 
  myData,
  myColumns,
  conditionalRowStyles,
 }) => {
  return (
    <DataTable
      className="my_table"
      noHeader={true}
      responsive={true}
      columns={myColumns}
      data={myData}
      striped={true}
      highlightOnHover={true}
      conditionalRowStyles={conditionalRowStyles}
      noDataComponent="No hay registros"
    />
  );
};