import React, {useEffect, useState} from "react";
import {Button, Col} from "rsuite";
/*Importing styles*/
import "./docs.scss";
import {TablePost} from "./tablePost";
import {TableEndpoint} from "./tableEndpoint";
import {useParams} from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {BsFillClipboardCheckFill} from "react-icons/bs";
import {FaCopy} from "react-icons/fa";
import {ThemeEditor} from "./themeEditor";

export const AuthenticationTypes = () => {
    const params = useParams();
    const [scrollTop,setScrollTop] = useState(0);
    const [nameRoute] = useState(`${params.name}`);
    const [dataUser] = useState({
        "UserId": "00000000-0000-0000-0000-000000000000",
        "Password": "Password"
    })

    const [dataToken] = useState({
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Imxnb256YWxleiIsImVtYWlsIjoibGdvbnphbGV6QHJlZGNvbXBhbmllcy5jb20ubXgiLCJuYmYiOjE1OTMwMzEzMjAsImV4cCI6MTU5MzAzNDkyMCwiaWF0IjoxNTkzMDMxMzIwLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDMyMi8iLCJhdWQiOiJyZXNvdXJjZV9zZXJ2ZXIifQ.BSZ6h5apXInbudPFmXqlyCB0dxDC5lGKmwLKdhBsKxQ",
        "message": "Success"
    })

    useEffect(() => {
        const handleScroll = () => setScrollTop(window.scrollY);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollTop]);

    const [result, setResult] = useState(false);
    const [resultResponse, setResultResponse] = useState(false);

    async function copyCode(value, typeCopy = true) {
        if(typeCopy) {
            try {
                await navigator.clipboard.writeText(value);
                setResult(true);
            } catch (e) {
                setResult(false);
                throw e;
            } finally {
                setTimeout(() => setResult(null), 2000);
            }
        } else {
            try {
                await navigator.clipboard.writeText(value);
                setResultResponse(true);
            } catch (e) {
                setResultResponse(false);
                throw e;
            } finally {
                setTimeout(() => setResultResponse(null), 2000);
            }
        }
    }

    return (
        <>
            {{
                'auth':
                    <>
                        <Col xs={24}>
                            <h4>Tipos de autenticación</h4>
                            <p>Red Pay cuenta con dos tipos de Autenticación:</p>
                            <ul className="list-docs">
                                <li>Basic HTTP Authentication</li>
                                <li>Json Web Token</li>
                            </ul>
                        </Col>
                        <Col xs={24} id="auth">
                            <h4>Basic HTTP Authentication</h4>
                            <p>
                                En la autenticación HTTP básica, una solicitud contiene un campo de encabezado en forma de Authorization: Basic {`<credentials>`},
                                donde “credenciales” es la codificación Base64 de la API Key y password unidos por dos puntos (:).
                            </p>
                            <div className="table-docs">
                                <div className="title">
                                    <th>Headers</th>
                                </div>
                                <div className="content">
                                    <p>Authorization: Basic [Base64{`<API Key:Password>`}]</p>
                                    <small>
                                        <strong>Nota: {' '}
                                            Este header de Autorización se debe colocar en cada una de las peticiones a la API de
                                            Red Pay</strong>
                                    </small>
                                </div>
                            </div>
                        </Col>,
                    </>,
                'json':
                    <Col xs={24} id="json">
                        <h4>Json Web Token (JWT)</h4>
                        <p>Permite la propagación de identidad y privilegios. Es necesario hacer una petición con las siguientes características para obtener el JWT</p>
                        <TableEndpoint urlProduction="productionAuthenticate" urlSandbox="Authenticate" />
                        <TablePost />
                        <div className="table-docs">
                            <div className="title">
                                <p>Headers</p>
                            </div>
                            <div className="content">
                                <p>Content-Type, application/json</p>
                            </div>
                        </div>
                        <div className="table-docs code">
                            <div className="title">
                                <p>Request Body</p>
                            </div>
                            <div className="code">
                                <Button color="red" onClick={() => copyCode(JSON.stringify(dataUser,null,2))}>
                                    {
                                        (result) ?
                                            <>
                                                <span><BsFillClipboardCheckFill /></span>
                                            </>
                                            :
                                            <span><FaCopy /></span>
                                    }
                                </Button>
                                <CodeMirror
                                    value={JSON.stringify(dataUser,null,2)}
                                    height="auto"
                                    readOnly={true}
                                    extensions={javascript({jsx: true})}
                                    theme={ThemeEditor}
                                />
                            </div>
                        </div>
                        <div className="table-docs code">
                            <div className="title">
                                <p>Response</p>
                            </div>
                            <div className="code">
                                <Button color="red" onClick={() => copyCode(JSON.stringify(dataToken,null,2), false)}>
                                    {
                                        (resultResponse) ?
                                            <>
                                                <span><BsFillClipboardCheckFill /></span>
                                            </>
                                            :
                                            <span><FaCopy /></span>
                                    }
                                </Button>
                                <CodeMirror
                                    value={JSON.stringify(dataToken,null,2)}
                                    height="auto"
                                    readOnly={true}
                                    extensions={javascript({jsx: true})}
                                    theme={ThemeEditor}
                                />
                            </div>
                        </div>
                    </Col>,
            }[nameRoute]}
        </>
    );
}
