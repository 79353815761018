import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "rsuite";
// import { PlecaInfo } from "../plecaInfo/plecaInfo";
import Logo from "../../assets/imgs/RedPay.png";
import { settingService } from "../../services/setting.service";
import { accountService } from "../../services/account.service";
import { useDispatch } from "react-redux";
import { setUserSetting } from "../../store/actions";
import menu from "../../data/menu.json";

import "./header.scss";

export const Header = (props) => {
  const dispatch = useDispatch();
  const [userSettings, setUserSettings] = useState({});

  useEffect(() => {
    settingService.getSettingSite().then((result) => {
      setUserSettings(result);
      dispatch(setUserSetting(result));
    });
  }, [sessionStorage["rp_company"]]);

  const MyLink = React.forwardRef((props, ref) => {
    const { href, as, ...rest } = props;
    return <Link to={href} as={as} ref={ref} {...rest}></Link>;
  });

  const NavLink = (props) => (
    <Dropdown.Item componentClass={MyLink} {...props} />
  );

  const Integration = () => {
    const data = menu.find(
      (a) => a.section == "Dropdown" && a.roles.includes(props.role)
    );
    return data != undefined ? (
      <div>
        <Dropdown.Item divider />
        <NavLink href={data.url}>{data.name}</NavLink>
      </div>
    ) : null;
  };

  return (
    <>
      <header className="wrapper_header">
        <div className=" content_header">
          <div className="box_logo">
            <span
              className={`btn_menu ${props.activeBtn}`}
              onClick={props.MyFunMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
            <Link to="/home">
              <img
                src={Logo}
                alt="Red Pay MX, Pagos online, visa, mastercard, oxxo pay"
              />
            </Link>
          </div>
          <div className="text-center box_actions">
            <span className="tag_mode">
              <span className="drop"></span>
              <span>{userSettings.environment}</span>
            </span>

            <Dropdown
              className="box_drop_user"
              title={
                <span className="name_business_drop">
                  {userSettings.companyName}{" "}
                </span>
              }
              trigger={"click"}
              icon={<Icon icon="building-o" />}
              placement="bottomEnd"
            >
              <Dropdown.Item>
                <Icon icon="user-o" /> Hola,{" "}
                <span className="fw-900">{userSettings.companyName}</span>
              </Dropdown.Item>

              <NavLink href="/business">Mis negocios</NavLink>
              <Integration />
              <Dropdown.Item divider />
              <NavLink href="/my-account">Mi cuenta</NavLink>
              <Dropdown.Item onClick={() => accountService.logout()}>
                Cerrar sesión
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </header>
      {/* <PlecaInfo /> */}
    </>
  );
};
