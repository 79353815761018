import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "rsuite";

import "./itemBusiness.scss";

export const ItemBusiness = (props) => {
  const setCompany = () => {
    sessionStorage.setItem("rp_company", props.id);
  };

  return (
    <div className="item_business cards">
      <span className="item_ico_ttl">
        <Icon icon="building-o"></Icon>
      </span>
      <div className="box_ttls_business">
        <h4 className="ttls__business">{props.name}</h4>
        <p className="fw-300 businessActivity">{props.businessActivity}</p>
      </div>
      <div className="box_actions_go">
        <span>
          <Icon icon="user" /> {props.usersCount}
        </span>
        <Link to="/home" className="btn_link" onClick={setCompany}>
          Entrar <Icon icon="angle-right"></Icon>
        </Link>
      </div>
    </div>
  );
};
