export const PaymentMethodEnum = {
    Banorte : 1,
    Banorte3D : 2,
    Amex : 3,
    OxxoPay : 4,
    OxxoCode : 5,
    FacVMC : 6,
    FacAmex : 7,
    CardGeneric : 8,
    OpenPay : 9,
    CyberSource : 10,
    Paynet : 11,
    CompassMerchantSolution : 12,
    Aplazo : 13
}