import React from "react";
import {Col} from "rsuite";

/*Importing styles*/
import "./docs.scss";

export const IntegrationCredentials = () => {

    return (
        <>
            <Col xs={24}>
                <h4>Credenciales de integración</h4>
                <p>
                    Se le proporcionarán dos datos al cliente, los cuales deberá configurar en su e-commerce, pues serán esenciales en cada
                    transacción que se realice con Red Pay:
                </p>
            </Col>
            <Col xs={24}>
                <ul className="list-docs">
                    <li><strong>APIKey:</strong> Identificador global único con el cual se ubicará al comercio electrónico del cliente en cada transacción.</li>
                    <li><strong>Password:</strong> Clave secreta única que será necesaria para autenticar las transacciones con Red Pay</li>
                </ul>
            </Col>
        </>
    );
}
