export const endpoints = {
    productionCreateTransaction: "https://api.redpayonline.com/api/pay/createtransaction",
    createTransaction: "https://appredpayapiclientmxdev.azurewebsites.net/api/pay/createtransaction",
    productionCapture: "https://api.redpayonline.com/api/pay/capture",
    capture: "https://appredpayapiclientmxdev.azurewebsites.net/api/pay/capture",
    productionCancel: "https://api.redpayonline.com/api/pay/cancel",
    cancel: "https://appredpayapiclientmxdev.azurewebsites.net/api/pay/cancel",
    productionCancelSubscription: "https://api.redpayonline.com/api/pay/CancelSubscription",
    CancelSubscription: "https://appredpayapiclientmxdev.azurewebsites.net/api/pay/CancelSubscription",
    productionCreate: "https://api.redpayonline.com/api/pay/create",
    create: "https://appredpayapiclientmxdev.azurewebsites.net/api/pay/create",
    productionRefund: "https://api.redpayonline.com/api/pay/refund",
    refund: "https://appredpayapiclientmxdev.azurewebsites.net/api/pay/refund",
    productionTransaction: "https://api.redpayonline.com/api/transaction/response/{transactionNumber}",
    transaction: "https://appredpayapiclientmxdev.azurewebsites.net/api/transaction/response/{transactionNumber}",
    productionReference: "https://api.redpayonline.com/api/transaction/responsebyreference/{referenceNumber}",
    reference: "https://appredpayapiclientmxdev.azurewebsites.net/api/transaction/responsebyreference/{referenceNumber}",
    productionTransactionDate: "https://api.redpayonline.com/api/transaction/report/{transactionDate}",
    transactionDate: "https://appredpayapiclientmxdev.azurewebsites.net/api/transaction/report/{transactionDate}",
    productionCustomercardidentifiers: "https://api.redpayonline.com/api/customercardidentifiers",
    customercardidentifiers: "https://appredpayapiclientmxdev.azurewebsites.net/api/customercardidentifiers",
    productionCustomercardidentifiersDelete: "https://api.redpayonline.com/api/customercardidentifiers/{id}",
    customercardidentifiersDelete: "https://appredpayapiclientmxdev.azurewebsites.net/api/customercardidentifiers/{id}",
    productionCustomercardidentifiersCreate: "https://api.redpayonline.com/api/customercardidentifiers/{emailCustomer}/list",
    customercardidentifiersCreate: "https://appredpayapiclientmxdev.azurewebsites.net/api/customercardidentifiers/{emailCustomer}/list",
    productionAuthenticate: "https://api.redpayonline.com/api/auth/Authenticate",
    Authenticate: "https://appredpayapiclientmxdev.azurewebsites.net/api/auth/Authenticate"
}
