import React, { useState, useEffect } from "react";
import { TitleSection } from "../../components/titleSection";
import { BoxVelocityRules } from "./boxVelocityRules";
import { BoxBlackList } from "./boxBlackList";
import { FlexboxGrid, Col, Icon } from "rsuite";
import { useSelector } from "react-redux";

import "./fraudManagement.scss";

export const FraudManagement = () => {
    const userSetting = useSelector((state) => state.userSetting);
    const [activeTab, setActiveTab] = useState(1);
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <section className="wrapper_fraud">
            <TitleSection title="Gestión de fraude" />
            <FlexboxGrid className="conten_fraud">
                <FlexboxGrid.Item componentClass={Col} xs={24} sm={7} md={6}>
                    <div className="cards box_controls_config">
                        <p className="fw-700">
                            <small>CONFIGURACIÓN</small>
                        </p>
                        <h4 className="name_business">
                            {" "}
                            <Icon icon="building-o"></Icon> {userSetting.companyName}
                        </h4>
                        <hr />
                        <nav className="nav_list_fraud">
                            <ul>
                                <li>
                                    <span
                                        onClick={() => {
                                            toggle(1);
                                        }}
                                        className={"span_link " + (activeTab === 1 ? "active" : "")}
                                    >
                                        Reglas de velocidad
                                    </span>
                                </li>
                                <li>
                                    <span
                                        onClick={() => {
                                            toggle(2);
                                        }}
                                        className={"span_link " + (activeTab === 2 ? "active" : "")}
                                    >
                                        Lista negra
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={24} sm={17} md={18}>
                    <div className="section_box_config">
                        {{
                            1: <BoxVelocityRules />,
                            2: <BoxBlackList />
                        }[activeTab]}
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </section>
    );
};
