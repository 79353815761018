import { callApi } from './service'

export const settingService = {
    get,
    update,
    getSettingSite,
    createPublicKey
};

// setting site
async function getSettingSite() {
    return callApi('/setting/settingssite', 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return {};
            }
        });
}


// get
async function get() {
    return callApi('/setting', 'GET')
        .then((result) => {
            if (result.success) {
                return result.data;
            } else {
                console.log(result.message);
                return {};
            }
        });
}

//update
async function update(model) {
    return callApi('/setting/update', 'PUT', model)
        .then(result => result);
}

//create secret key
async function createPublicKey() {
    return callApi('/setting/publicKey', 'POST', null)
        .then(result => result);
}
