import React, { useState, useEffect, useRef } from 'react'
import { Table } from '../../components/table'
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
    Icon,
    Button,
    IconButton,
    Whisper,
    Tooltip,
    Pagination,
    Notification
} from 'rsuite';
import { subscriptionService } from '../../services/subscription.service'
import { useSelector } from 'react-redux'
import {ModalConfirm} from '../../components/modals/modalConfirm'
import { getColumns } from "./templateColumns";
import { SubscriptionStatusEnum } from '../../enum/subscriptionStatusEnum';

const tooltipCancelSubs = (
    <Tooltip>
        Cancelar suscripción
    </Tooltip>
);

export const SubscripActive = (props) => {
    const userSetting = useSelector(state => state.userSetting);
    const [data, setData] = useState([]);
    const [reference, setReference] = useState("");
    const [tableData, setTableData] = useState({ rows: 0, pages: 0, activePage: 0 });    

    let columns = [{
        name: 'Acciones',
        center: true,
        width: "75px",
        cell: (row) => <span className="box_ico_table" onClick={() => cancelSuscription(row.idOriginTransaction)}>

            <Whisper placement="bottom" trigger="hover" speaker={tooltipCancelSubs}>
                <IconButton
                    className="mr-2 "
                    icon={<Icon icon="ban" />}
                    circle
                    size="md"
                />
            </Whisper>
        </span>,
    }];
    columns = [...columns, ...getColumns(userSetting, true)];

    useEffect(() => {
        if(props.active)
            getSubscriptions("", 1);
    }, [props.active]);

    const getSubscriptions = (referenceNumber, pageNumber) => {
        subscriptionService.getSubscription(SubscriptionStatusEnum.Active, referenceNumber, pageNumber, 10)
            .then((result) => {
                setData(result);
                if (result.length > 0) setTableData({ rows: result[0].maxRows, pages: result[0].maxPages, activePage: pageNumber });
            });
    }

    const handleSelectPage = (page) => {
        setTableData({ ...tableData, activePage: page });
        getSubscriptions(reference, page);
    }

    const cancel = (id, justification) => {
        subscriptionService.cancelSubscription({ TransactionNumber: id, reason: justification })
            .then((result) => {
                if (result.success) {
                    Notification.success({ title: 'Suscripción cancelada exitosamente', duration: 5000 });
                    getSubscriptions(reference, 1);
                } else Notification.error({ title: result.message, duration: 5000 });
            });
    }

    const cancelSuscription = (id) => {
        ModalConfirm.show({
            size: "xs",
            title: "Cancelar suscripción",
            text: "¿Estas seguro que deseas cancelar esta suscripción?",
            onOk: () => {
                ModalConfirm.show({
                    size: "xs",
                    title: "Cancelar suscripción",
                    text: "",
                    requireJustification: true,
                    onOk: (justification) => {
                        cancel(id, justification)
                    }
                });
            }
        });
    }

    const generateReport = () => {
        if (tableData.rows > 0) {
            subscriptionService.generateReportSubscription(SubscriptionStatusEnum.Active, reference, tableData.rows);
        }
    }

    return (
        <div className="">
            <div className="text-right">
                <Button className="btn c_primary" appearance="subtle" type="submit" onClick={generateReport}>
                    <Icon icon="file" />
                    <span className="fw-400"> Generar reporte</span>
                </Button>
            </div>

            <FlexboxGrid align="middle">
                <FlexboxGrid.Item className="mb-3" componentClass={Col} lg={12} sm={10} xs={24}>
                    <h3 className="fw-300">Suscripciones activas</h3>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="mb-3" componentClass={Col} lg={12} sm={14} xs={24}>
                    <Form className="sec_box_filters" onSubmit={() => getSubscriptions(reference, 1)}>
                        <FlexboxGrid align="bottom">
                            <FlexboxGrid.Item className="mb-3" componentClass={Col} sm={16} xs={24}>
                                <FormGroup>
                                    <ControlLabel>Buscar por referencia</ControlLabel>
                                    <InputGroup inside>
                                        <FormControl
                                            size='lg'
                                            name="input-1"
                                            placeholder="Referencia"
                                            // errorMessage={errorMessage}
                                            // errorPlacement={errorPlacement}
                                            onChange={(value) => setReference(value)}
                                        />
                                        <InputGroup.Addon>
                                            <Icon icon="search" />
                                        </InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="mb-3" componentClass={Col} sm={8} xs={24}>
                                <Button className="btn secundary" block appearance="primary" size="lg" type="submit">Buscar</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <div className="mt-3 mb-5">
                <Table myData={data} myColumns={columns}></Table>
                <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={tableData.pages} activePage={tableData.activePage} onSelect={handleSelectPage} />
            </div>
        </div>
    )
}