import React from "react"
import { Link } from 'react-router-dom'
import {
  Icon,
  Dropdown,
} from 'rsuite';

export const NewLeagues = () => {
  return(
    <Dropdown
      className="drop_leagues"
      title="Generar liga de pago"
      trigger={['click']}
      icon={<Icon icon="plus-square-o" />}
      placement="bottomEnd"
      size="md"
      appearance="primary"
    >
      <Link className="rs-dropdown-item-content" to="/leagues-single">Liga pago único</Link>
      <Dropdown.Item divider />
      <Link className="rs-dropdown-item-content" to="/leagues-bulk">Liga pago masivo</Link>
    </Dropdown>
  )
}
