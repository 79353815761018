import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    ControlLabel, Drawer, FlexboxGrid, Icon, Nav, Row, SelectPicker, Tag,
} from "rsuite";
import {FormRequest} from "./formRequest";
/*Importing styles*/
import "./docs.scss";
import 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import sampleRequests from '../../assets/jsons/sampleRequests.json';
import sampleTypes from '../../assets/jsons/sampleTypes.json';
import dataCreate from '../../assets/jsons/dataCreate.json';
import { FaCopy, FaTimes} from "react-icons/fa";
import {GeneratorJSON} from "./generatorJSON";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import {BsFillClipboardCheckFill} from "react-icons/bs";
import FlexboxGridItem from "rsuite/es/FlexboxGrid/FlexboxGridItem";
import {ThemeEditor} from "./themeEditor";

export const TestBuilder = ({ loadSampleType, loadSampleRequest }) => {

    const [itemTab, setItemTab] = useState(true);
    const selectTab = (activeKey) => setItemTab(activeKey);

    const [checkDataJSON, setCheckDataJSON] = useState([]);
    const [idSampleType, setIdSampleType] = useState(0);
    const [sampleTypeObject, setSampleTypeObject] = useState([]);
    const [idJsonSelected, setIdJsonSelected] = useState(0);
    const [objectJsonSelected, setObjectJsonSelected] = useState([]);
    const [objectJsonResponse, setObjectJsonResponse] = useState([]);
    const [stateJsonSelected, setStateJsonSelected] = useState([]);
    const [currentRequest, setCurrentRequest] = useState([]);
    const [credentials, setCredentials] = useState(false);
    const [formValue, setFormValue] = useState({
        UserId: '',
        Password: ''
    });
    const [messageError, setMessageError] = useState('');

    //DRAWER CODE -------STARTS
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [titleDrawer, setTitleDrawer] = useState('');
    const [descriptionDrawer, setDescriptionDrawer] = useState('');

    const showDrawerBuilder = (nodeData) => {
        setOpenWithHeader(true)
        setTitleDrawer(nodeData.label)
        setDescriptionDrawer(nodeData.description.map(item => item))
    }

    const dataSampleRequests = sampleRequests.map(item => ({ label: item.title, value: item.id }));

    const onChangeSelectSampleRequest = (selected) => {
        setIdSampleType(selected)
        const newData = sampleTypes.filter(data => data.sampleRequest === selected);
        setSampleTypeObject(newData.map(item => {
            return {
                "label": item.title,
                "value": item.id,
                "endpoint": item.endpoint,
            }
        }))
    }

    const [bodyRequest, setBodyRequest] = useState({});

    const onChangeSelectJson = (selected) => {
        setIdJsonSelected(selected)
        const sampleTypeSelected = sampleTypeObject.filter(data => data.value === selected)
        const data = jsonBuilder(dataCreate, selected);

        setObjectJsonSelected(data)
        setStateJsonSelected(data)
        setCurrentRequest(sampleTypeSelected[0].endpoint);
    }

    const makeRequestJSON = () => {
        let aKeys = [];
        stateJsonSelected.map(data => {
            if(data.hasChildren) {
                const child = data.children.map(child => {

                    let grandChildArray, grandChildArrayConverted;
                    let isLineItems = false;

                    if(child?.children && child.hasChildren) {
                        grandChildArray = child.children.map(item => {
                            return {
                                "label": item.label,
                                "labelValue": item.labelValue
                            }
                        })
                        grandChildArrayConverted = grandChildArray.reduce((o, key) => ({ ...o, [key.label]: key.labelValue}), {})
                    }

                    if(grandChildArrayConverted) {
                        if(child.label === 'LineItems') isLineItems = true
                    }

                    return {
                        "label": child.label,
                        "labelValue": (child.hasChildren && isLineItems) ? [grandChildArrayConverted] : (child.hasChildren) ? grandChildArrayConverted : child.labelValue
                    };
                });

                const childConverted = child.reduce((o, key) => ({ ...o, [key.label]: key.labelValue}), {})
                aKeys.push({
                    "label": data.label,
                    "labelValue": childConverted
                });
            } else {
                aKeys.push({
                    "label": data.label,
                    "labelValue": data.labelValue
                });
            }
            return aKeys;
        });
        setBodyRequest(aKeys.reduce((o, key) => ({ ...o, [key.label]: key.labelValue}), {}));
    }

    useEffect(() => {
        makeRequestJSON();
    },[stateJsonSelected]);

    const jsonBuilder = (data, selected) => {
        const dataFiltered = data.filter(item => item.sampleType.includes(selected));

        return dataFiltered.map(item => {
            const children = item.children?.filter(item => item.sampleType.includes(selected)) ?? [];

            const newChildren = children.map(child => {
                const parent = {
                    "label": child.label,
                    "labelValue": child.labelValue,
                    "value": child.value,
                    "check": child.check,
                    "checkAll": child.checkAll,
                    "description": child.description,
                    "sampleType": child.sampleType,
                    "hasChildren": child.hasChildren,
                    "parent": child.parent
                };

                if(child.hasChildren) {
                    const grandChild = child.children?.filter(item => item.sampleType.includes(selected)) ?? [];

                    parent.children = grandChild.map(grandChild => {
                        return {
                            "label": grandChild.label,
                            "labelValue": grandChild.labelValue,
                            "value": grandChild.value,
                            "check": grandChild.check,
                            "checked": grandChild.check,
                            "description": grandChild.description,
                            "sampleType": grandChild.sampleType,
                            "hasChildren": grandChild.hasChildren
                        }
                    })
                }

                return parent;
            });

            const grandParent = {
                "label": item.label,
                "labelValue": item.labelValue,
                "value": item.value,
                "check": item.check,
                "checkAll": item.checkAll,
                "sampleType": item.sampleType,
                "description": item.description,
                "hasChildren": item.hasChildren
            }

            if(newChildren.length > 0) grandParent.children = newChildren;

            return grandParent;
        });

    }

    const onLoadSampleRequest = () => onChangeSelectSampleRequest(loadSampleRequest);
    const onLoadSampleType = () => onChangeSelectJson(loadSampleType);

    useEffect(() => {
        onLoadSampleRequest();
        onLoadSampleType();
        const apiKeyUserStorage = localStorage.getItem("apiKeyUser");
        if(JSON.parse(apiKeyUserStorage)) {
            const data = JSON.parse(apiKeyUserStorage);
            setFormValue({
                UserId: data.UserId,
                Password: data.Password
            })
        }
    }, []);

    const [editorChanged, setEditorChanged] = useState(false);
    const [newBodyRequest, setNewBodyRequest] = useState('');


    const handleChangeEditorRequest = (data) => {
        setEditorChanged(true)
        setNewBodyRequest(data)
    }

    const updateBodyRequest = () => {
        setBodyRequest(JSON.parse(newBodyRequest));
        setEditorChanged(false);
    }

    const [result, setResult] = useState(false);
    const [resultResponse, setResultResponse] = useState(false);

    const [execHandleSubmit, setExecHandleSubmit] = useState(false);
    const toggleExecSubmit = () => {
        setItemTab(false)
        setExecHandleSubmit(!execHandleSubmit);
    }

    async function copyCode(value,typeResult) {
        if(typeResult !== null) {
            try {
                await navigator.clipboard.writeText(value);
                setResult(true);
            } catch (e) {
                setResult(false);
                throw e;
            } finally {
                setTimeout(() => setResult(null), 2000);
            }
        } else {
            try {
                await navigator.clipboard.writeText(value);
                setResultResponse(true);
            } catch (e) {
                setResultResponse(false);
                throw e;
            } finally {
                setTimeout(() => setResultResponse(null), 2000);
            }
        }
    }

    return (
        <>
            <Row className="row-builder">
                <Col xs={24}>
                    <Nav appearance="subtle">
                        <Nav.Item active={(itemTab)} eventKey="requestBuilder" onSelect={() => selectTab(true)}>Request Builder</Nav.Item>
                        <Nav.Item active={(itemTab === false)} eventKey="settings" onSelect={() => selectTab(false)}>Credenciales</Nav.Item>
                    </Nav>
                </Col>
                {{
                    true:
                            <Col md={10} sm={24} xs={24}>
                                <div className={`container-credentials ${(!itemTab) ? 'active' : ''}`}>
                                    <FlexboxGrid justify="start" align="middle" className="mt-3">
                                        <FlexboxGrid.Item>
                                            <small>Consulta</small>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <div className="link">
                                        <div className="link-label">
                                            <ControlLabel className="fw-700">
                                                URL:
                                                <Tag color="red">Sandbox</Tag>
                                            </ControlLabel>
                                        </div>
                                    </div>
                                    <div className="link">
                                        <div className="link-label">
                                            <ControlLabel className="fw-700">Tipo de petición</ControlLabel>
                                        </div>
                                        <div className="link-value">
                                            <SelectPicker
                                                size="lg"
                                                value={(idSampleType) ? idSampleType : onLoadSampleRequest()}
                                                searchable={false}
                                                cleanable={false}
                                                data={dataSampleRequests}
                                                placeholder="Selecciona una petición de muestra"
                                                onSelect={onChangeSelectSampleRequest}
                                            />
                                        </div>
                                    </div>
                                    {
                                        (idSampleType !== 0) ?
                                            <div className="link">
                                                <div className="link-label">
                                                    <ControlLabel className="fw-700">Petición de muestra</ControlLabel>
                                                </div>
                                                <div className="link-value">
                                                    <SelectPicker
                                                        searchable={false}
                                                        size="lg"
                                                        cleanable={false}
                                                        placeholder="Selecciona un tipo de petición"
                                                        data={sampleTypeObject}
                                                        value={(idJsonSelected) ? idJsonSelected : onLoadSampleType()}
                                                        onChange={onChangeSelectJson}
                                                    />
                                                </div>
                                            </div> : ''
                                    }
                                </div>
                                {
                                    ((formValue.UserId !== '' && formValue.Password !== '') && !editorChanged) ?
                                        <FlexboxGrid justify="end">
                                            <FlexboxGrid.Item style={{ marginLeft: '.5rem' }}>
                                                <Button type="button" onClick={toggleExecSubmit} color="red">Enviar <span style={{ paddingLeft: '.5rem' }}><Icon icon="arrow-circle-right" /></span></Button>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid> : null
                                }
                            </Col>,
                    false:
                            <FormRequest
                                setResponse={setObjectJsonResponse}
                                itemTab={itemTab}
                                currentRequest={currentRequest}
                                bodyRequest={bodyRequest}
                                setCredentials={setCredentials}
                                credentials={credentials}
                                setFormValue={setFormValue}
                                formValue={formValue}
                                setMessageError={setMessageError}
                                messageError={messageError}
                                execHandleSubmit={execHandleSubmit}
                                setItemTab={setItemTab}
                                editorChanged={editorChanged}
                    />
                }[itemTab]}
                <Col md={14} sm={24} xs={24}>
                    <div className={`container-json active`}>
                        <FlexboxGrid justify="start" align="middle" className="mt-3">
                            <FlexboxGrid.Item><small>Muestra de JSON</small></FlexboxGrid.Item>
                        </FlexboxGrid>
                        <GeneratorJSON
                            checkDataJSON={checkDataJSON}
                            setCheckDataJSON={setCheckDataJSON}
                            objectJsonSelected={objectJsonSelected}
                            setStateJsonSelected={setStateJsonSelected}
                            stateJsonSelected={stateJsonSelected}
                            showDrawerBuilder={showDrawerBuilder}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={24} xs={24} className="mt-3">
                    {
                        (editorChanged) ?
                            <>
                                <FlexboxGrid justify="end" className="mb-3">
                                    <FlexboxGridItem>
                                        <Button color="red" size="sm" onClick={updateBodyRequest}>Actualizar JSON</Button>
                                    </FlexboxGridItem>
                                </FlexboxGrid>
                                <FlexboxGrid justify="end" className="mb-3">
                                    <FlexboxGridItem>
                                        <div className="alert-error">
                                            <p><strong style={{marginRight: '.5rem'}}>Nota:</strong>Mantén la estructura de un JSON al actualizar</p>
                                        </div>
                                    </FlexboxGridItem>
                                </FlexboxGrid>
                            </>
                            : null
                    }
                    <div className="table-docs code">
                        <div className="title">
                            <p>Request Body</p>
                        </div>
                        <div className="code">
                            <Button color="red" onClick={() => copyCode(JSON.stringify(bodyRequest,null,2))}>
                                {
                                    (result) ?
                                        <>
                                            <span><BsFillClipboardCheckFill /></span>
                                        </>
                                        :
                                        <span><FaCopy /></span>
                                }
                            </Button>
                            <CodeMirror
                                value={JSON.stringify(bodyRequest,null, 2)}
                                height="auto"
                                editable={true}
                                extensions={javascript({jsx: true})}
                                onChange={handleChangeEditorRequest}
                                theme={ThemeEditor}
                            />
                        </div>
                    </div>
                </Col>
                <Col sm={24} xs={24} className="mt-3">
                    <div className="table-docs code">
                        <div className="title">
                            <p>Response</p>
                        </div>
                        <div className="code">
                            {
                                (objectJsonResponse.length !== 0) ?
                                    <Button color="red" onClick={() => copyCode(JSON.stringify(objectJsonResponse,null,2), null)}>
                                        {
                                            (resultResponse) ?
                                                <span><BsFillClipboardCheckFill /></span>
                                                :
                                                <span><FaCopy /></span>
                                        }

                                    </Button> : null
                            }
                            <CodeMirror
                                value={JSON.stringify(objectJsonResponse,null, 2)}
                                height="auto"
                                readOnly={true}
                                extensions={javascript({jsx: true})}
                                onChange={(data) => console.log(data)}
                                theme={ThemeEditor}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Drawer show={openWithHeader} onHide={() => setOpenWithHeader(false)} className="docs-drawer">
                <Drawer.Header>
                    <Button className="btn-bars" onClick={() => setOpenWithHeader(false)}><FaTimes /></Button>
                    <Drawer.Title>Propiedad: <span>{titleDrawer}</span></Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <h4>
                        {
                            descriptionDrawer && descriptionDrawer.map((item,i) => {
                                return (
                                    <>
                                        <ul className="row-builder-table-header">
                                            <li key={3895247+i}>
                                                <span>Descripción:</span>
                                                <p>{item.content}</p>
                                            </li>
                                            <li key={87465239785+i}>
                                                <span>Tipo de dato:</span>
                                                <p>{item.type}</p>
                                            </li>
                                        </ul>
                                    </>
                                )
                            })
                        }
                    </h4>
                </Drawer.Body>
            </Drawer>
        </>
    );
}
