import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { StatisticsHome } from './statisticsHome/statisticsHome';
import { Row, Col, FlexboxGrid, Icon, Button } from 'rsuite';
//import { Table } from '../../components/table'
//import { transactionService } from '../../services/transaction.service'
//import { useSelector } from 'react-redux'

export const Home = () => {
  //const userSetting = useSelector(state => state.userSetting);
  //const [data, setData] = useState([]);
  //const columns = transactionService.columns(userSetting);

  //useEffect(() => {
  //  transactionService.get(0, "", "", "", 1, 10)
  //    .then((result) => {
  //      setData(result)
  //    });
  //}, []);

  return (
    <section className="wrapper_home">
      <StatisticsHome />
      <Row className="last_movim">
        <Col>
          <FlexboxGrid justify="space-between" align="middle">
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={16}>
              <div className="">
                <h4 className="mb-1">Movimientos recientes</h4>
                <p className="paragraph">Consulta todos los movimientos recientes de su cuenta.</p>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item className="text-right" componentClass={Col} colspan={24} md={8}>
              <Link to="/transactions" appearance="link">
                <Button appearance="link" className="text-right p0" ><Icon icon='list' /> Ver todos los movimientos</Button>
              </Link>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Col>
      </Row>
      {/*<Table myData={data} myColumns={columns}></Table>*/}
    </section>
  )
}
