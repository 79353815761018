import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TitleSection } from "../../components/titleSection";
import {
    ButtonToolbar,
    Toggle,
    FlexboxGrid,
    Col,
    Icon,
    Drawer,
    Button,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    SelectPicker,
    Schema,
    Notification,
    Message,
    InputGroup,
    Input,
    Dropdown,
} from "rsuite";
import { accountService } from "../../services/account.service";
import roles from "../../data/roles.json";

import "./users.scss";

export const Users = () => {
    const formDefault = {
        id: "",
        userName: "‎",
        email: "",
        password: "",
        roleName: "",
        edit: true,
        editPermissions: true,
        companies: [],
        error: "",
    };
    //==== FUNC TO ASIDE-DRAWER ===
    const [activeAside, setActiveAside] = useState(false);
    const toggleAside = () => {
        setActiveAside((prevState) => !prevState);
    };
    //=======

    const [business, setBusiness] = useState([]);
    const [businessSearch, setBusinessSearch] = useState([]);
    const [businessSearch2, setBusinessSearch2] = useState([]);
    const [activeBusiness, setActiveBusiness] = useState(0);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [formValue, setFormValue] = useState(formDefault);
    const [model, setModel] = useState(0);

    const { StringType } = Schema.Types;

    const model1 = Schema.Model({
        userName: StringType().isRequired("Este campo es requerido."),
        email: StringType()
            .isEmail(
                "Por favor, introduce una dirección de correo electrónico válida."
            )
            .isRequired("Este campo es requerido."),
        password: StringType().isRequired("Este campo es requerido."),
    });

    const model2 = Schema.Model({
        id: StringType().isRequired("Este campo es requerido."),
    });

    const model3 = Schema.Model({
        email: StringType()
            .isEmail(
                "Por favor, introduce una dirección de correo electrónico válida."
            )
            .isRequired("Este campo es requerido."),
    });

    useEffect(() => {
        accountService.companies(true).then((result) => {
            setBusinessSearch(result);
            setBusinessSearch2(result);
            setBusiness(result);
            handleBusiness(result[0].id);
        });
    }, []);

    const handelFilter = (searchInput, filterName) => {
        filterName = filterName.toUpperCase();
        const search = business.filter((b) =>
            b.name.toUpperCase().includes(filterName)
        );
        if (searchInput === 1) setBusinessSearch(search);
        else setBusinessSearch2(search);
    };

    const handleBusiness = (id) => {
        sessionStorage.setItem("rp_company", id);
        setActiveBusiness(id);
        getUsersByCompany(id);
    };

    const getUsersByCompany = (id) => {
        accountService.usersByCompany(id).then((result) => setCompanyUsers(result));
    };

    const getUsersToInvite = () => {
        accountService.usersToInvite().then((result) => setUsers(result));
    };

    const handleToggleBusiness = (value, id) => {
        if (value) {
            formValue.companies.push({ idCompany: id, roleId: "" });
            setFormValue({ ...formValue });
        } else {
            formValue.companies = formValue.companies.filter(
                (item) => item.idCompany !== id
            );
            setFormValue({ ...formValue });
        }
    };

    const handleToggleRole = (value, id) => {
        let company = formValue.companies.filter((item) => item.idCompany == id)[0];
        company.roleId = value;
        setFormValue({ ...formValue });
    };

    const handleAside = (model, item) => {
        if (item === undefined) {
            setFormValue(formDefault);
        } else {
            const user = JSON.parse(JSON.stringify(item));
            setFormValue({ ...user, password: "", error: "" });
        }

        if (model === 2) getUsersToInvite();

        setModel(model);
        toggleAside();
    };

    const handleUser = (value) => {
        if (value === null) {
            setFormValue(formDefault);
        } else {
            const user = JSON.parse(
                JSON.stringify(users.find((a) => a.id === value))
            );
            setFormValue({ ...user, password: "", error: "" });
        }
    };

    const handleSubmit = (formStatus) => {
        if (formStatus) {
            if (formValue.companies.length > 0) {
                formValue.userName = formValue.userName.replace("‎", "");
                accountService.register(formValue).then((result) => {
                    if (result.success) {
                        Notification.success({
                            title: "El usuario se guardó correctamente.",
                            duration: 5000,
                        });
                        toggleAside();
                        getUsersByCompany(activeBusiness);
                    } else {
                        setFormValue({ ...formValue, error: result.message });
                    }
                });
            } else {
                setFormValue({
                    ...formValue,
                    error: "El usuario debe de tener al menos un negocio asignado.",
                });
            }
        }
    };

    const [showPass, setShowPass] = useState("password");
    const [activePass, setActivePass] = useState("eye-slash");

    const iconPass = () => {
        setShowPass(showPass === "password" ? "text" : "password");
        setActivePass(activePass === "eye-slash" ? "eye" : "eye-slash");
    };

    return (
        <section className="wrapper_users">
            <div className="am_container ">
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item componentClass={Col} xs={24} md={20}>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24} sm={16}>
                                <TitleSection title="Usuarios" />
                                <h4 className="fw-300 mt-2">
                                    Invita a nuevas personas a tu negocio
                                </h4>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xs={24}
                                sm={8}
                                className="text-right mt-3"
                            >
                                <Dropdown
                                    title="Agregar usuario"
                                    placement="bottomEnd"
                                    renderTitle={(children) => {
                                        return (
                                            <Button appearance="primary" className="btn primary">
                                                <Icon icon="plus" /> {children}
                                            </Button>
                                        );
                                    }}
                                >
                                    <Dropdown.Item onClick={() => handleAside(1)}>
                                        <Icon size="lg" icon="user" /> Usuario nuevo
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleAside(2)}>
                                        <Icon size="lg" icon="user-o" /> Invitar a mi negocio
                                    </Dropdown.Item>
                                </Dropdown>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <FlexboxGrid
                            justify="space-between"
                            className="mt-5 cards content_users"
                        >
                            <FlexboxGrid.Item componentClass={Col} xs={24} sm={10} md={7}>
                                <aside className="aside_users ">
                                    <div className="mb-5">
                                        <TitleSection title="Negocios" />
                                        <InputGroup className="mt-2">
                                            <Input
                                                placeholder="Buscar negocio"
                                                onChange={(value) => handelFilter(1, value)}
                                            />
                                            <InputGroup.Addon>
                                                <Icon icon="search" />
                                            </InputGroup.Addon>
                                        </InputGroup>
                                    </div>

                                    <div className="content_list_infouser">
                                        {businessSearch.map((item) => (
                                            <div
                                                className={`box_info_user ${item.id == activeBusiness ? "active" : ""
                                                    }`}
                                                key={item.id}
                                                onClick={() => handleBusiness(item.id)}
                                            >
                                                <div className="ico_user_info ">
                                                    <Icon icon="building-o"></Icon>
                                                </div>
                                                <div>
                                                    <h5 className="fw-700 mb-1">{item.name}</h5>
                                                    <div>
                                                        <Icon icon="user-o" /> {item.usersCount} usuario(s)
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div>
                                        <hr />
                                        <p className="text-center fw-300 mt-3">
                                            Total de negocios <b>{business.length}</b>
                                        </p>
                                    </div>
                                </aside>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={24} sm={13} md={16}>
                                <div className="infos">
                                    <div className="drawer_ttl">
                                        <TitleSection title="Lista de usuarios " />
                                        <p className="fw-300 text-right">
                                            <b>{companyUsers.length} </b> invitado(s)
                                        </p>
                                    </div>
                                    <div className=" mt-5 content_list_users">
                                        {companyUsers.map((item) => (
                                            <div className="cards mb-4 item_card_user" key={item.id}>
                                                <FlexboxGrid>
                                                    {item.edit ? (
                                                        <span
                                                            className="ico_options"
                                                            onClick={() => handleAside(3, item)}
                                                        >
                                                            <Icon icon="edit" />
                                                        </span>
                                                    ) : null}
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        xs={24}
                                                        md={12}
                                                    >
                                                        <h5 className="fw-700 mb-2">{item.userName}</h5>
                                                        <p>{item.email}</p>
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        xs={24}
                                                        md={12}
                                                    >
                                                        <p>
                                                            Rol: <span>{item.roleName}</span>
                                                        </p>
                                                    </FlexboxGrid.Item>
                                                </FlexboxGrid>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>

            <Drawer
                size="md"
                show={activeAside}
                placement="right"
                className="is_drawer"
            >
                <Drawer.Header>
                    <Drawer.Title>
                        {model == 2
                            ? "Invitar usuario"
                            : formValue.id == ""
                                ? "Agregar usuario"
                                : "Editar usuario"}
                    </Drawer.Title>
                    <Icon className="close_ico" onClick={toggleAside} icon="close" />
                </Drawer.Header>
                <Drawer.Body>
                    <Form
                        fluid
                        model={model == 1 ? model1 : model == 2 ? model2 : model3}
                        formValue={formValue}
                        onChange={(formValue) => setFormValue(formValue)}
                        onSubmit={handleSubmit}
                    >
                        <div className=" mb-4">
                            <FlexboxGrid justify="space-between">
                                {model == 1 || model == 3 ? (
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        xs={24}
                                        sm={12}
                                        className="mt-5"
                                    >
                                        <TitleSection title="Datos de la cuenta" />
                                        <hr />
                                        <FormGroup>
                                            <ControlLabel>Usuario</ControlLabel>
                                            <FormControl
                                                size="lg"
                                                name="userName"
                                                disabled={formValue.id != "" && "disabled"}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>Correo electrónico</ControlLabel>
                                            <FormControl size="lg" name="email" type="email" />
                                        </FormGroup>
                                        <FormGroup className="myboxform">
                                            <ControlLabel>Contraseña</ControlLabel>
                                            <span className="inputIcon">
                                                <FormControl
                                                    size="lg"
                                                    name="password"
                                                    type={showPass}
                                                    placeholder={
                                                        formValue.id == ""
                                                            ? "Ingresa una contraseña"
                                                            : "Cambiar contraseña"
                                                    }
                                                />
                                                <span className="icoPass" onClick={iconPass}>
                                                    <Icon icon={activePass} size="lg" />
                                                </span>
                                            </span>
                                        </FormGroup>
                                    </FlexboxGrid.Item>
                                ) : (
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        xs={24}
                                        sm={12}
                                        className="mt-5"
                                    >
                                        <TitleSection title="Datos de la cuenta" />
                                        <hr />
                                        <FormGroup controlId="radioList">
                                            <ControlLabel>Usuario</ControlLabel>
                                            <FormControl
                                                accepter={SelectPicker}
                                                placeholder="Seleccione"
                                                name="id"
                                                data={users}
                                                block
                                                size="lg"
                                                onChange={handleUser}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>
                                )}
                                {formValue.editPermissions ? (
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        xs={24}
                                        sm={10}
                                        className="mt-5"
                                    >
                                        <TitleSection title="Acceso a negocios" />
                                        <hr />
                                        <div className="content_access_business">
                                            <div className="mb-5">
                                                <TitleSection title="Negocios" />
                                                <InputGroup className="mt-2">
                                                    <Input
                                                        placeholder="Buscar negocio"
                                                        onChange={(value) => handelFilter(2, value)}
                                                    />
                                                    <InputGroup.Addon>
                                                        <Icon icon="search" />
                                                    </InputGroup.Addon>
                                                </InputGroup>
                                            </div>
                                            {businessSearch2.map((item) =>
                                                model == 1 ||
                                                    (model == 2 &&
                                                        formValue.companies.find(
                                                            (e) => e.idCompany == item.id && e.edit == false
                                                        ) == undefined) ||
                                                    (model == 3 && item.id == activeBusiness) ? (
                                                    <div className="mb-3" key={item.id}>
                                                        <Toggle
                                                            checked={
                                                                formValue.companies.find(
                                                                    (e) => e.idCompany == item.id
                                                                ) != undefined
                                                            }
                                                            onChange={(value) =>
                                                                handleToggleBusiness(value, item.id)
                                                            }
                                                        />
                                                        <span className=" fw-500"> {item.name} </span>
                                                        {formValue.companies.find(
                                                            (e) => e.idCompany == item.id
                                                        ) != undefined ? (
                                                            <FormGroup controlId="radioList">
                                                                <FormControl
                                                                    accepter={SelectPicker}
                                                                    placeholder="Selecciona un rol"
                                                                    data={roles}
                                                                    block
                                                                    size="sm"
                                                                    searchable={false}
                                                                    className="mt-2 mb-3"
                                                                    onChange={(value) =>
                                                                        handleToggleRole(value, item.id)
                                                                    }
                                                                    value={
                                                                        formValue.companies.find(
                                                                            (e) => e.idCompany == item.id
                                                                        ).roleId
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        ) : null}
                                                    </div>
                                                ) : null
                                            )}
                                        </div>
                                    </FlexboxGrid.Item>
                                ) : null}
                            </FlexboxGrid>
                            <br />
                            {formValue.error != "" ? (
                                <Message
                                    type="error"
                                    description={
                                        <div
                                            dangerouslySetInnerHTML={{ __html: formValue.error }}
                                        />
                                    }
                                />
                            ) : null}
                            <hr />
                            <FormGroup>
                                <ButtonToolbar className="text-right">
                                    <Link to="/companyUsers">
                                        <Button
                                            appearance="primary"
                                            size="lg"
                                            className="btn secundary"
                                            onClick={toggleAside}
                                        >
                                            Cancelar
                                        </Button>
                                    </Link>
                                    <Button
                                        appearance="primary"
                                        size="lg"
                                        className="btn primary"
                                        type="submit"
                                    >
                                        Guardar
                                    </Button>
                                </ButtonToolbar>
                            </FormGroup>
                        </div>
                    </Form>
                </Drawer.Body>
            </Drawer>
        </section>
    );
};
