import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TitleSection } from '../../components/titleSection'
import { Table } from '../../components/table'
import {
    Row,
    Col,
    Button,
    Icon,
    Tag,
    Pagination,
    Notification
} from 'rsuite';
import Moment from 'react-moment'
import 'moment/locale/es';
import { subscriptionService } from '../../services/subscription.service'
import { useSelector } from 'react-redux'
import getSymbolFromCurrency from "currency-symbol-map"
import {ModalConfirm} from '../../components/modals/modalConfirm'
import { getColumns } from "./templateColumnsSubscription"
import { SubscriptionStatusEnum } from '../../enum/subscriptionStatusEnum';

import './subscriptions.scss'

export const SubscriptionsDetails = (props) => {
    const userSetting = useSelector((state) => state.userSetting);
    const [columns, setColumns] = useState(getColumns(userSetting));
    const [status] = useState(props.match.params.active == "Activa" ? SubscriptionStatusEnum.Active : props.match.params.active == "Cancelada" ? SubscriptionStatusEnum.Cancel : SubscriptionStatusEnum.Pause);

    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState({ rows: 0, pages: 0, activePage: 0 }); 
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        
        subscriptionService.getSubscriptionDetail(status, props.match.params.id)
            .then((result) => {
                if (result && result.numberOfPayments === 0) { }
                setColumns(state => state.filter((item) => item.selector !== "numberOfPayment"));

                setSubscription(result)
                getTransactions(result.idSubscription, 1)

                if(result.status != "Activa"){
                    let div = document.querySelector('#intentos');
                    div.style.visibility = 'hidden';
                }
                
            })
    }, []);

    const getTransactions = (idSubscription, pageNumber) => {
        localStorage.setItem("statusIdTransaction", 1);
        subscriptionService.getSubscriptionTransaction({ idSubscription, pageNumber, rowsOfPage: 10 })
            .then((result) => {
                setData(result);
                if (result.length > 0) setTableData({ rows: result[0].maxRows, pages: result[0].maxPages, activePage: pageNumber });
            });
    }

    const handleSelectPage = (page) => {
        setTableData({ ...tableData, activePage: page });
        getTransactions(subscription.idSubscription, page);
    }

    const cancel = (id, justification) => {
        subscriptionService.cancelSubscription({ subscriptionId: id, reason: justification })
            .then((result) => {
                if (result.success) {
                    Notification.success({ title: 'Suscripción cancelada exitosamente', duration: 5000 });
                    props.history.push("/subscriptions")
                } else Notification.error({ title: result.message, duration: 5000 });
            });
    }

    const cancelSuscription = () => {
        debugger
        ModalConfirm.show({
            size: "xs",
            title: "Cancelar suscripción",
            text: "¿Estas seguro que deseas cancelar esta suscripción?",
            onOk: () => {
                ModalConfirm.show({
                    size: "xs",
                    title: "Cancelar suscripción",
                    text: "",
                    requireJustification: true,
                    onOk: (justification) => {
                        cancel(subscription.idSubscription, justification)
                    }
                });
            }
        });
    }

    return (
        <section className="subscriptions_details">
            <div className="mb-4">
                <Link to="/subscriptions">
                    <Button className="btn c_primary p0" appearance="link" type="submit">
                        <Icon icon="chevron-left" />
                        <span className="fw-400"> Regresar</span>
                    </Button>
                </Link>
            </div>
            <TitleSection title="Detalles de la Suscripción" />

            <Row className="mt-3">
                <Col sm={24} md={16}>
                    <div className="cards ">
                        <Row>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Id: </span>
                                    <span className="fw-300">{subscription && subscription.idOriginTransaction}</span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Referencia: </span>
                                    <span className="fw-300">{subscription && subscription.referenceNumber}</span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Cliente: </span>
                                    <span className="fw-300">{subscription && subscription.customerName}</span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Email de cliente: </span>
                                    <span className="fw-300">{subscription && subscription.email}</span>
                                </p>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Monto: </span>
                                    <span className="fw-300">{subscription && subscription.amount && getSymbolFromCurrency(userSetting.isoCurrency) +
                                        subscription.amount.toLocaleString(userSetting.cultureCode, {
                                            minimumFractionDigits: 2,
                                        })}</span>
                                </p>
                            </Col>
                            <Col sm={24} md={12}>
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Fecha de creación: </span>
                                    <span className="fw-300"> <Moment locale="es" format="DD/MM/YYYY hh:mm:ss A">{subscription && subscription.date}</Moment></span>
                                </p>
                                {subscription && subscription.status == "Activa"
                                    ? <p className="paragraph mb-3">
                                        <span className="fw-700">Fecha de siguiente cobro: </span>
                                        <span className="fw-300"> <Moment locale="es" format="DD/MM/YYYY">{subscription && subscription.nextPaymentDate}</Moment></span>
                                    </p>
                                    : <p className="paragraph mb-3">
                                        <span className="fw-700">Fecha de {subscription.status == "Cancelada" ? "cancelación" : "pausa"} </span>
                                        <span className="fw-300"> <Moment locale="es" format="DD/MM/YYYY hh:mm:ss A">{subscription && subscription.modificationDate}</Moment></span>
                                    </p>
                                }
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Frecuencia de cobro: </span>
                                    <span className="fw-300">{subscription && {
                                        "Daily": "Diario",
                                        "Biweekly": "Quincenal",
                                        "Monthly": "Mensual",
                                        "Halfyearly": "Semestral",
                                        "Annual": "Anual",
                                        "Scheduled": "Calendarizado"
                                    }[subscription.frequency]}
                                    </span>
                                </p>
                                {subscription && subscription.numberOfPayments > 0 &&
                                    (<p className="paragraph mb-3">
                                        <span className="fw-700">Número de pagos: </span>
                                        <span className="fw-300"> {subscription.numberOfPayments}</span>
                                    </p>)}
                                <p className="paragraph mb-3">
                                    <span className="fw-700">Estatus: </span>
                                    <span className="fw-300">
                                        <Tag color={subscription.status == "Activa" ? "green" : subscription.status == "Cancelada" ? "red" : "orange"}><p className="paragraph min fw-700 c_white">{subscription.status}</p></Tag>
                                    </span>
                                </p>
                                <p className="paragraph mb-3" id="intentos">
                                    <span className="fw-700">Intentos de cobro restantes: </span>
                                    <span className="fw-300">{subscription && subscription.nowAttemptNumber}</span>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm={24} md={8}>
                    <div className="box_result">
                        <div className="cards text-center result">
                            <div className="text">
                                <span>Monto total</span> <span>cobrado a la fecha</span>
                            </div>
                            <h3>{getSymbolFromCurrency(userSetting.isoCurrency)}{data.length > 0 && data[0].totalAmount.toLocaleString(userSetting.cultureCode, { minimumFractionDigits: 2 })} <span className="label_min">{userSetting.isoCurrency}</span></h3>
                        </div>
                        {status != SubscriptionStatusEnum.Cancel ? <Button className="btn primary" block appearance="primary" type="submit" size="lg" onClick={cancelSuscription}>Cancelar suscripción</Button> : null}
                    </div>
                </Col>
            </Row>

            <Row className="content_table_historial">
                <Col xs={24}>
                    <TitleSection title="Historial de suscripción " />
                    <Table myData={data} myColumns={columns}></Table>
                    <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={tableData.pages} activePage={tableData.activePage} onSelect={handleSelectPage} />
                </Col>
            </Row>
        </section>
    )
}