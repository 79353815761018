import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import {
    Button,
    Modal,
    Input,
    Notification
} from 'rsuite';

// Componente para el modal de confirmación con ambos botones
const ModalConfirmComponent = ({ size, title, text, onOk, requireJustification = false }) => {
    const [show, setShow] = useState(true);
    const [justification, setJustification] = useState("");
    const maxLength = 250;

    const hideConfirm = () => setShow(false);

    const handleOnOk = () => {
        if (!requireJustification || (requireJustification && justification.trim().length >= 5 && justification.trim().length <= 250)) {
            hideConfirm();
            onOk(justification);
        } else {
            Notification.warning({ title: "Error en justificación", description: 'La justificación debe tener entre 5 y 250 caracteres', duration: 5000 });
        }
    }

    return (
        <Modal size={size} show={show} onHide={hideConfirm}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{text}</p>
                {requireJustification && (
                    <div>
                        <p>Por favor, proporciona una justificación:</p>
                        <Input
                            componentClass="textarea"
                            value={justification}
                            onChange={value => setJustification(value)}
                            maxLength={maxLength}
                        />
                        <p>{maxLength - justification.length} caracteres restantes</p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleOnOk} appearance="primary">
                    Aceptar
                </Button>
                <Button onClick={hideConfirm} appearance="subtle">
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

// Componente para el modal con solo un botón
const ModalMessageComponent = ({ size, title, text }) => {
    const [show, setShow] = useState(true);

    const hideConfirm = () => setShow(false);

    return (
        <Modal size={size} show={show} onHide={hideConfirm}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{text}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={hideConfirm} appearance="primary">
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ModalConfirm = {
    show: (props) => ReactDOM.render(<ModalConfirmComponent {...props} />, document.createElement('div'))
};

const ModalMessage = {
    show: (props) => ReactDOM.render(<ModalMessageComponent {...props} />, document.createElement('div'))
};

export { ModalConfirm, ModalMessage };
